import React from 'react';
import exit from '../../images/exit.svg';
import next from '../../images/next.svg';
import prepare from '../../images/preparing.svg';
import delivering from '../../images/delivering.svg';
import completed from '../../images/completed.svg';
import cancel from '../../images/cancel.svg';
import lang from '../../images/lang.svg';
import tourguide from '../../images/tourguide.svg';
import company from '../../images/company.svg';
import logo_mono from '../../images/logo-mono.svg';
import myorder from '../../images/myorder.svg';
import info from '../../images/info.svg';
import faq from '../../images/faq.svg';
import down from '../../images/down.svg';

const icons = {
  exit,
  next,
  prepare,
  delivering,
  completed,
  cancel,
  lang,
  tourguide,
  company,
  logo_mono,
  myorder,
  info,
  faq,
  down
};

// 접수대기 => 접수완료 => 배달중 => 배달완료
export const changeImg = status => {
  if (status === 1 || status === 2) {
    return prepare;
  } else if (status === 3) {
    return delivering;
  } else if (status === -1) {
    return cancel;
  } else {
    return completed;
  }
};

const Icon = ({ type, style, onClick, ...rest }) => {
  return <img src={icons[type]} alt="" style={style} onClick={onClick} {...rest} />;
};

export default Icon;
