import React from "react";
import ani from "../../images/ani.png";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  z-index: 50;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .msg {
    color: #aaa;
    font-size: 12px;
    margin-top: 0.4em;
    margin-left: 0.4em;
    text-align: center;
  }
`;

const Loader = ({ children }) => {
  return (
    <Container>
      <img src={ani} alt="" style={{ width: "28%", maxWidth: "190px" }} />
      {children && <div className="msg">{children}</div>}
    </Container>
  );
};

export default Loader;
