import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { loadInfo, SAMPLE_HOTEL_CODE } from '../../redux/info';
import { stringReturn } from '../../common/Translator/Translator';
import { logger } from '../../common/utils';
import { time, location } from '../../images';
import CategoryIcon from '../../components/Icons/CategoryIcon';
import Translator from '../../common/Translator/Translator';
import Footer from '../../Layouts/Footer';
import Loader from '../../components/Icons/Loader';
import Banner from './Banner';
import Slider from '../../components/SlideBanner/Slider';
import { useWindowResize } from '../../common/hooks';
import ManualSlider from '../../components/SlideBanner/ManualSlider';
import { orderBy } from 'lodash';
import in_room_dining from '../../images/in_room_dining.svg';

const GridContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  .grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    color: black;
    border-bottom: solid 0.5px #e6e6e6;
    border-right: solid 0.5px #e6e6e6;
    height: ${({ gridHeight }) => gridHeight + 'px'};
    max-height: 191px;
  }
  .in_room_dining {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
  }
`;

const Home = (props) => {
  const { info, contents } = useSelector((state) => state.info, []);
  const { lang } = useSelector((state) => state.lang, []);

  const [gridHeight, setGridHeight] = useState(() => window.innerWidth * 0.333);
  useWindowResize(() => setGridHeight(window.innerWidth * 0.333));

  const dispatch = useDispatch();

  // 카테고리 빈 공간 채워주는 더미 아이템 카운트
  const noneCategorryArr = useMemo(() => {
    let arr = [];
    if (!info || !info.category) return arr;
    const categoryLength = info.category.includes(0) ? info.category.length + 1 : info.category.length;
    const count = Math.ceil(categoryLength / 3) * 3 - categoryLength;
    //arr.push('asian');
    //info.category.push('asian')
    //info.category.push('korean')
    //const count = 0; // none 추가 개수

    for (let i = 0; i < count; i++) {
      arr.push('none');
    }
    return arr;
  }, [info]);

  /* 한번접속하면 그 url로 호텔정보 입력하고 새로고침등으로 사라질시 세션에서 가져옴 */
  useEffect(() => {
    logger('initial code:', info && info.code);
    dispatch(loadInfo());
  }, [dispatch]); // eslint-disable-line

  return (
    <>
      {info && info.code === SAMPLE_HOTEL_CODE && (
        <div style={{ width: '100%', textAlign: 'center', background: '#f6f6f6', fontSize: '12px' }}>
          샘플 페이지입니다. Sample page.
        </div>
      )}
      {info && info.qrData && (
        <div
          style={{
            width: '100%',
            textAlign: 'center',
            background: '#fff',
            fontSize: '14px',
            color: '#373751',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0.25rem 0',
          }}
        >
          <img src={location} alt='' style={{ width: '16px' }} />
          {info.name_en}
          {info.qrData.roomNo && `, `}
          {info.qrData.roomNo}
        </div>
      )}
      {contents ? (
        <>
          <ManualSlider contents={contents} />
          {info && !info.active && (
            <div
              style={{
                width: '100%',
                background: `linear-gradient(to bottom, rgba(0,0,0,0.4), rgba(0,0,0,0.1))`,
                padding: '1rem 0',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '14px',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>
                <Translator id='hoteltime' type='local' />
                <span style={{ fontSize: '22px', marginLeft: '0.5rem' }} role='img' aria-label='sad'>
                  😭
                </span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={time} alt='' style={{ width: '18px' }} />
                {info.time}
              </div>
            </div>
          )}
          <GridContainer gridHeight={gridHeight}>
            {orderBy(
              categoryList.filter((item) => info.category.includes(item.adminIndex)),
              'orderIndex',
              'asc'
            ).map((item, index) => (
              <Category
                itemNo={item}
                index={item.adminIndex}
                key={index}
                itemKey={item.key}
                history={props.history}
                active={info.active}
                lang={lang}
              />
            ))}
            {noneCategorryArr.map((item, index) => (
              <div className='grid-item' key={`none-${index}`}>
                <CategoryIcon type={item} />
              </div>
            ))}
          </GridContainer>

          <Slider speed={6000} auto>
            <a
              style={{ width: '100%' }}
              target='_blank'
              href={`https://www.seoultravelpass.com/${convertLang(lang)}?affiliate=deliquick`}
              rel='noopener noreferrer'
            >
              <img
                src={`https://firebasestorage.googleapis.com/v0/b/deliquick-93b6b.appspot.com/o/banners%2Fseoulpass_r6.svg?alt=media&token=b557dd2e-48f2-4b15-872d-4816ae779786`}
                alt=''
                style={{ width: '100%' }}
              />
            </a>
            <a
              style={{ width: '100%' }}
              target='_blank'
              href={`https://www.shillaipark.com/estore/kr/zh/event/eventView?eventId=E54307`}
              rel='noopener noreferrer'
            >
              <img
                src={`https://firebasestorage.googleapis.com/v0/b/deliquick-93b6b.appspot.com/o/banners%2Fshilla_banner_r2.svg?alt=media&token=92654db5-f636-4d31-a037-3fd1659ba134`}
                alt=''
                style={{ width: '100%' }}
              />
            </a>
            <div>
              <Banner />
            </div>
          </Slider>
          <Footer />
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default withRouter(Home);

const convertLang = (lang) => {
  switch (lang) {
    case 'jp':
      return 'ja';
    case 'cn':
      return 'zh-CN';
    default:
      return 'en';
  }
};

// adminIndex: 어드민에서의 순서
// orderIndex: 정렬할 순서
// key: translator key
/*
export const categoryList = [
  {key: 'all', name: 'All', adminIndex: 0, orderIndex: 0},
  {key: 'korean', name: 'Korean', adminIndex: 1, orderIndex: 1, id: ['6kMrkmZarK5TFTGY65qF']},
  {key: 'chicken', name: 'Chicken', adminIndex: 2, orderIndex: 4, id: ['8CW013YW1LNHTxO1sb6J']}, 
  {key: 'chinese', name: 'Chinese', adminIndex: 3, orderIndex: 2, id: ['cOi7Krdq2Gju5zoayLyL']},
  {key: 'japanese', name: 'Japanese', adminIndex: 4, orderIndex: 3, id: ['5794zbsABH9roo3mtxZ5']},
  {key: 'pizza', name: 'Italian/Pizza/Burger', adminIndex: 5, orderIndex: 5, id: ['pInMwb3XIa1wBiZSoqts', 'OAE9uKqrKagNUkmEMYj5']},
  {key: 'asian', name: 'Asian',adminIndex: 6, orderIndex: 6, id: ['opGv38CWW50jB83PUtIK']},
  {key: 'salads', name: 'Salads', adminIndex: 7, orderIndex: 13, id: ['pZGFaAQrYu06QMSySWIc']},
  {key: 'dessert', name: 'Dessert',adminIndex: 8, orderIndex: 14, id: ['vf6RrvlClS2beDWtdadf']},
  {key: 'america', name: 'American/Grill', adminIndex: 9, orderIndex: 7, id: ['9krC3lmteDxKWhaJ9dqI']},
  {key: 'african', name: 'African', adminIndex: 10, orderIndex: 10, id: ['b0RqdIDU0Iuh7zlxDHvT']},
  {key: 'mexican', name: 'Mexican', adminIndex: 11, orderIndex: 9,id: ['hH6f2kSIz8xnZlLjW0bj']},
  {key: 'arabic', name: 'Arabic/Turkish', adminIndex: 12, orderIndex: 11,id: ['stBSajpBek2fYrZWZK8y']},
  {key: 'indian', name: 'Indian', adminIndex: 13, orderIndex: 12,id: ['wv8538609ueVvet37MTQ']},
  {key: 'european', name: 'European',adminIndex: 14, orderIndex: 8,id: ['Yasgr3NnpYXwqxIwbY2o']},
  {key: 'meal_box', name: 'Meal Box',adminIndex: 15, orderIndex: 15,id: ['P9fMoDFg06XwNLQ8UGs6']},
  {key: 'grocery',name: 'Grocery', adminIndex: 16, orderIndex: 16,id: ['O618DGa9w478fIJpw9Ww']},
];*/
export const categoryList = [
  { key: 'in_room_dining', name: 'In Room Dining', adminIndex: 0, orderIndex: 0, id: ['4wCiR7tgDZ54vixEF5Gr'] },
  { key: 'all', name: 'All', adminIndex: 1, orderIndex: 1 },
  { key: 'korean', name: 'Korean', adminIndex: 2, orderIndex: 2, id: ['6kMrkmZarK5TFTGY65qF'] },
  { key: 'chicken', name: 'Chicken', adminIndex: 3, orderIndex: 3, id: ['8CW013YW1LNHTxO1sb6J'] },
  { key: 'chinese', name: 'Chinese', adminIndex: 4, orderIndex: 4, id: ['cOi7Krdq2Gju5zoayLyL'] },
  { key: 'japanese', name: 'Japanese', adminIndex: 5, orderIndex: 5, id: ['5794zbsABH9roo3mtxZ5'] },
  {
    key: 'pizza',
    name: 'Italian/Pizza/Burger',
    adminIndex: 6,
    orderIndex: 6,
    id: ['pInMwb3XIa1wBiZSoqts', 'OAE9uKqrKagNUkmEMYj5'],
  },
  { key: 'asian', name: 'Asian', adminIndex: 7, orderIndex: 7, id: ['opGv38CWW50jB83PUtIK'] },
  { key: 'salads', name: 'Salads', adminIndex: 8, orderIndex: 8, id: ['pZGFaAQrYu06QMSySWIc'] },
  { key: 'dessert', name: 'Dessert', adminIndex: 9, orderIndex: 9, id: ['vf6RrvlClS2beDWtdadf'] },
  { key: 'america', name: 'American/Grill', adminIndex: 10, orderIndex: 10, id: ['9krC3lmteDxKWhaJ9dqI'] },
  { key: 'african', name: 'African', adminIndex: 11, orderIndex: 11, id: ['b0RqdIDU0Iuh7zlxDHvT'] },
  { key: 'mexican', name: 'Mexican', adminIndex: 12, orderIndex: 12, id: ['hH6f2kSIz8xnZlLjW0bj'] },
  { key: 'arabic', name: 'Arabic/Turkish', adminIndex: 13, orderIndex: 13, id: ['stBSajpBek2fYrZWZK8y'] },
  { key: 'indian', name: 'Indian', adminIndex: 14, orderIndex: 14, id: ['wv8538609ueVvet37MTQ'] },
  { key: 'european', name: 'European', adminIndex: 15, orderIndex: 15, id: ['Yasgr3NnpYXwqxIwbY2o'] },
  { key: 'meal_box', name: 'Meal Box', adminIndex: 16, orderIndex: 16, id: ['P9fMoDFg06XwNLQ8UGs6'] },
  { key: 'grocery', name: 'Grocery', adminIndex: 17, orderIndex: 17, id: ['O618DGa9w478fIJpw9Ww'] },
];

const Category = ({ index, active, history, lang, itemKey }) => {
  return (
    <div
      className={`grid-item ${itemKey}`}
      onClick={() => {
        if (!active) return alert(stringReturn(lang, 'hoteltime'));
        history.push(`category/${index}`);
      }}
    >
      {itemKey === 'in_room_dining' ? (
        <img src={in_room_dining} style={{ width: '100%' }} alt='' />
      ) : (
        <>
          <CategoryIcon type={itemKey} />
          <div style={{ textAlign: 'center' }}>
            <Translator id={itemKey} type='local' />
          </div>
        </>
      )}
    </div>
  );
};
