import { takeEvery, put, call, all, select } from 'redux-saga/effects';
import firebase from '../firebase';
import querystring from 'query-string';
import { logger } from '../common/utils';
import { differenceInMinutes } from 'date-fns';

export const SAMPLE_HOTEL_CODE = 'GDS001';

const { db } = firebase;

/* types */
const LOAD_INFO = 'info/LOAD_INFO';
const SET_INFO = 'info/SET_INFO';
const SET_CONTENTS = 'info/SET_CONTENTS';
const CLEAR_INFO = 'info/CLEAR_INFO';

/* actions */
export const setInfo = value => ({ type: SET_INFO, payload: value });
export const loadInfo = code => ({ type: LOAD_INFO, code });
export const clearInfo = () => ({ type: CLEAR_INFO });

/* sagas */
const loadInfoSaga = function*() {
  const { info } = yield select(state => state.info);
  const qrData = querystring.parse(window.location.search);
  const code = (qrData && qrData.code) || (info && info.code) || SAMPLE_HOTEL_CODE;
  const doc = db.collection('hotels').doc(code);
  const data = yield call([doc, doc.get]);

  const transform = doc => {
    if (!doc.exists || !doc.data().status || doc.data().status === '미계약') {
      alert('Invalid path, Move to sample page.');
      logger('샘플페이지로 이동');
      return window.location.replace(`/?code=${SAMPLE_HOTEL_CODE}`);
    } else {
      return {
        ...doc.data(),
        qrData
      };
    }
  };
  yield put(setInfo({ ...transform(data), code }));
};

const loadContents = function*() {
  const { contentsLoadedAt } = yield select(state => state.info);
  if (differenceInMinutes(contentsLoadedAt, new Date()) < 10)
    return logger('info:', 'contents not loaded');
  logger('contents load');
  const doc = db
    .collection('contents')
    .where('category.main', '==', true)
    .where('active', '==', true);

  const transform = snapshots => {
    let result = [];
    snapshots.forEach(doc => {
      result.push({ ...doc.data(), id: doc.id });
    });
    return result;
  };
  const data = yield call([doc, doc.get]);
  yield put({ type: SET_CONTENTS, payload: transform(data) });
};

export const infoSaga = function*() {
  yield all([takeEvery(LOAD_INFO, loadInfoSaga), takeEvery(LOAD_INFO, loadContents)]);
};

/* redux */
const initialState = {
  info: null
};

const info = (state = initialState, action) => {
  switch (action.type) {
    case SET_INFO:
      let newInfo = { ...action.payload };
      delete newInfo.logs;
      return {
        ...state,
        info: newInfo
      };
    case SET_CONTENTS:
      return {
        ...state,
        contents: action.payload,
        contentsLoadedAt: new Date()
      };
    case CLEAR_INFO:
      return initialState;
    default:
      return state;
  }
};

export default info;
