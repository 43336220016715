import firebase from '../../firebase';
import { getUniqueOrderNo, formatDeleteDash } from '../format';

const { db } = firebase;

const OrderData = class {
  constructor(infoData, cartData, storeData, inputData, paymentData, language, coupon) {
    this.data = {
      orderNumber: getUniqueOrderNo(infoData.code),
      date: new Date(),
      cart: cartData.map(
        ({ addedAt, language, store_name_id, store_id, store_delivery_fee, store_min_price, index, ...rest }) => rest
      ),
      total: parseInt(paymentData.total),
      hotel_id: infoData.code,
      hotelName: infoData.name, // 숙박업체 이름
      hotel: `${infoData.name_en} `,
      address: `${infoData.address.jibun} ${infoData.address.detail || ''} ${inputData.roomNo || ''}`,
      address_en: `${infoData.address.en}`,
      deliveryFee: storeData.deliveryFee || 0,
      store_id: storeData.id,
      storeStatus: storeData.status,
      storeName: storeData.realName,
      storePhone: formatDeleteDash(storeData.phone),
      pay: paymentData.pay,
      status: 1, // 주문상태 1: 주문대기
      serviceFee: parseInt(infoData.serviceFee),
      hotelPhone: infoData.phone || '',
      defaultMessage: infoData.defaultMessage || '',
      language: language,
      coupon,
    };
    if (inputData) {
      this.data.email = {
        email: inputData.email || '',
        agreement: inputData.email ? true : false,
      };
      this.data.roomNo = inputData.roomNo || '';
      this.data.phoneNo = inputData.phoneNo || '';
      this.data.countryCode = inputData.countryCode || '';
      this.data.reservationTime = inputData.reservationTime || '';
      this.data.reservationDate = inputData.reservationDate || '';
    }
  }
  checkOrderNumberValid = async () => {
    return db
      .collection('orders')
      .doc(this.data.orderNumber)
      .get()
      .then((doc) => !doc.exists);
  };
};

export default OrderData;
