// Chinese
export const cn = {
  total: '总价',
  order: '订单',
  nomenu: '没有选择菜单',
  deliveryfee: '配送费',
  chicken: '炸鸡',
  korean: '韩国',
  japanese: '日本',
  dessert: '甜点',
  pizza: '比萨饼/汉堡',
  burger: '汉堡',
  chinese: '中国',
  street: '街头食品',
  salads: '沙拉',
  asian: '亚洲',
  midnight: '晚餐',
  deliverytime: '预计送达时间',
  minute: '分钟',
  address: '地址',
  businesshour: '营业时间',
  telephone: '电话号码',
  main: '主菜',
  side: '副菜',
  info: '详情信息',
  payment: '付款',
  quantity: '数量',
  addtocart: '添加到购物车',
  unavailable: '已打烊',
  alipay: '支付宝',
  unionpay: 'UnionPay (银联)',
  makepayment: '进行付款',
  size: '尺寸',
  option: '选项',
  credit: '信用卡',
  myorder: '我的订单',
  mylocation: '我的位置',
  orderplaced: '订单已下',
  preparing: '准备',
  delivery: '配送',
  paymentsuccess: '已付款',
  cancel: '取消付款',
  cancel2: '付款已取消',
  cart1: '已经在您的购物车中添加了相同的项目! 继续订单吗?',
  cart2: '购物车中只可添加同一家店订单，要清空购物车然后添加吗',
  method: '请选择付款方式',
  invalid: '路径错误',
  room1: '请输入房间号',
  room2: '请输入正确房间号',
  nearby: '附近信息',
  home: '主页',
  pay1: '接单后则不得取消。请确认您点的餐以及付款信息后点击同意(必点)',
  minprice: '最低订单金额',
  price: '价钱',
  exim: `1) 此付款方式由eximbay提供，卡账单上提供名称是 “www.eximbay.com”。`,
  exim2: `2) 通知: 请注意，账单名称将视写为EXIMBAY.COM。`,
  original: '原型',
  subtotal: '小计',
  empty: '购物车是空的',
  undermin: `您的购物车低于所需的最低订购量`,
  countlimit: '你购物车里的物品已经超过了最大限额 允许数量为50',
  nohistory: '没有订单历史',
  status1: '等待您的订单',
  status2: '您的订单已经确认',
  status3: '交付',
  status4: '交付完成',
  deleteinfo: `您的订单记录将在付款后72小时内自动删除。更多信息请查看您的电子邮件`,
  emailagreement: `同意向填写的邮箱进行营销活动(选择)`,
  emailinput: `填写邮箱的话会向您的邮箱发送收据`,
  popup: `结算窗口无法打开时,请在浏览器上解除弹出式拦截`,
  browser: `付款后务必返回该页面才可完成结算`,
  hoteltime: `现在是外卖准备时间`,
  openingsoon: `即将开始营业`,
  nowclosed: `对不起！现在不是营业时间`,
  tourguide: `旅游信息`,
  faq: `常见问询`,
  aboutus: `公司资料`,
  african: '非洲',
  all: '所有餐厅'
};
