import React from 'react';
import img from '../../images/tourguide_banner.svg';
import { useSelector } from 'react-redux';

const Banner = () => {
  const language = useSelector(state => state.lang.lang, []);
  const convertLang = lang => {
    switch (lang) {
      case 'jp':
        return 'ja';
      case 'cn':
        return 'zh-CN';
      default:
        return 'en';
    }
  };
  return (
    <a style={{ width: '100%' }} href={`https://tour.deliquick.co.kr/${convertLang(language)}`}>
      <img src={img} style={{ width: '100%' }} alt="" />
    </a>
  );
};

export default Banner;
