import React, { useState } from "react";

export const PlusIcon = ({ onClick }) => {
  const [active, setHoverEffect] = useState(false);

  return (
    <div
      onMouseOver={() => {
        setHoverEffect(true);
      }}
      onMouseOut={() => {
        setHoverEffect(false);
      }}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <g fill="none" fillRule="evenodd">
          <rect
            width="15"
            height="15"
            x="4.5"
            y="4.5"
            fill="#FFF"
            stroke={active ? "#FF3250" : "#DDD"}
            rx="2"
          />
          <rect
            width="1"
            height="10"
            x="11.5"
            y="7"
            fill={active ? "#FF3250" : "#CCC"}
            rx=".5"
          />
          <rect
            width="1"
            height="10"
            x="11.5"
            y="7"
            fill={active ? "#FF3250" : "#CCC"}
            rx=".5"
            transform="rotate(90 12 12)"
          />
        </g>
      </svg>
    </div>
  );
};

export const MinusIcon = ({ onClick }) => {
  const [active, setHoverEffect] = useState(false);
  return (
    <div
      onMouseOver={() => {
        setHoverEffect(true);
      }}
      onMouseOut={() => {
        setHoverEffect(false);
      }}
      onClick={onClick}
    >
      <svg
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="icon/quantity/m/d"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <rect
            id="Rectangle"
            stroke={active ? "#FF3250" : "#DDD"}
            fill="#FFFFFF"
            x="4.5"
            y="4.5"
            width="15"
            height="15"
            rx="2"
          />
          <rect
            id="Rectangle"
            fill={active ? "#FF3250" : "#CCC"}
            transform="translate(12.000000, 12.000000) rotate(90.000000) translate(-12.000000, -12.000000) "
            x="11.5"
            y="7"
            width="1"
            height="10"
            rx="0.5"
          />
        </g>
      </svg>
    </div>
  );
};
