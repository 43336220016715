import React, { useState, useEffect, useMemo } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setCart, clearCart } from '../../redux/cart';
import styled from 'styled-components';
import deleteIcon from '../../images/delete.svg';
import exitIcon from '../../images/exit.svg';
import trashActiveIcon from '../../images/trashcan.svg';
import trashNormalIcon from '../../images/trash.svg';
import editIcon from '../../images/edit.svg';
import activeEditIcon from '../../images/edit_active.svg';
import { MinusIcon, PlusIcon } from '../../components/Icons/Counter';
import { formatPrice, formatTenPercentCommission } from '../../common/format';
import Translator, { stringReturn } from '../../common/Translator/Translator';

const Container = styled.div`
  color: #aaaaaa;
  font-size: 14px;
  min-height: 100vh;
  .header {
    width: 100%;
    max-width: 580px;
    display: flex;
    padding: 12px 0;
    border-bottom: solid 1px #e6e6e6;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    position: fixed;
    top: 0;
  }
  .head-box {
    display: flex;
    width: 90%;
    margin: 48px 5% 0 5%;
    border-bottom: solid 1px #e6e6e6;
    padding: 12px 0;
    color: #aaaaaa;
    .title {
      font-size: 16px;
      line-height: 1.09;
      color: #000000;
      font-weight: 600;
    }
  }
  .min {
    width: 90%;
    margin: 0 5%;
    text-align: center;
    border-top: solid 1px #e6e6e6;
    padding-top: 8px;
  }
`;

const ItemContainer = styled.div`
  width: 90%;
  margin: 0 5%;
  border-bottom: solid 1px #e6e6e6;
  padding: 12px 0;
`;

const OrderButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  background-color: #ff3250;
  color: white;
  cursor: pointer;
  padding: 12px 0;
  font-size: 16px;
  line-height: 1;
  transition: 0.2s all ease-out;
  white-space: nowrap;
  &:hover,
  :active {
    background-color: #c3223a;
  }
  .total {
    position: fixed;
    bottom: 1.75rem;
    right: 10%;
    font-size: 14px;
    line-height: 1.14;
  }
`;
const ButtonBackground = styled.div`
  z-index: 100;
  position: fixed;
  bottom: 0;
  width: 90%;
  max-width: 522px;
  margin: 0 5%;
  padding: 0.5rem 0 1rem 0;
  @media screen and (min-width: 580px) {
    margin: 0 29px;
  }
`;

const Cart = props => {
  const { cart } = useSelector(state => state.cart, []);
  const { lang } = useSelector(state => state.lang, []);
  const { info } = useSelector(state => state.info, []);
  const [viewCart, setViewCart] = useState(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const DELIVERY_FEE = useMemo(() => {
    const storeFee = (cart && cart.length > 0 && cart[0].store_delivery_fee) || 0;
    return parseInt(info.serviceFee || 0) + parseInt(storeFee);
  }, [cart, info]);

  // 마운트시에만 실행되야함 cart dep에 넣으면 에러
  useEffect(() => {
    window.scrollTo(0, 0);
    setViewCart(cart);
    if (cart && cart.length === 0) {
      props.history.goBack();
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    dispatch(setCart(viewCart));
    if (viewCart && viewCart.length === 0) props.history.goBack();
  }, [viewCart, dispatch]); // eslint-disable-line

  const handleSetCart = (name, value, index) => {
    const newCart = viewCart.slice();
    newCart.splice(index, 1, { ...newCart[index], [name]: value });
    // 변경 사항 반영하여 새배열 생성하고 그 새 배열의 총합 계산 하여 추가
    let optionPrice = 0;
    newCart[index].options.map(
      option => (optionPrice += formatTenPercentCommission(parseInt(option.price || 0)))
    );
    let total;
    if (name === 'counter') {
      total = (formatTenPercentCommission(parseInt(newCart[index].price)) + optionPrice) * value;
    } else {
      total = formatTenPercentCommission(parseInt(newCart[index].price)) + optionPrice;
    }

    newCart.splice(index, 1, { ...newCart[index], total });
    setViewCart(newCart);
  };

  // todo : cart 삭제 index 불일치
  const handleDeleteCart = index => {
    setLoading(true);
    setTimeout(() => {
      setViewCart(viewCart.filter((_, findIndex) => index !== findIndex));
      setLoading(false);
    }, 100);
  };

  const total = useMemo(() => {
    let totalPrice = 0;
    viewCart && viewCart.map(item => (totalPrice += parseInt(item.total)));
    return totalPrice + DELIVERY_FEE;
  }, [viewCart, DELIVERY_FEE]);

  // total 옵션 삭제시 같이 삭제 되게
  return (
    <Container>
      <div className="header">
        <img
          src={exitIcon}
          alt=""
          style={{ marginLeft: '5%' }}
          onClick={() => {
            dispatch(setCart(viewCart));
            const prevUrl = sessionStorage.getItem('prevUrl');
            return prevUrl && prevUrl.split('/')[1]
              ? props.history.push(prevUrl)
              : props.history.push('/');
          }}
        />

        <div style={{ fontSize: '16px', lineHeight: 1.5, color: 'black' }}>Cart</div>
        <img
          src={trashActiveIcon}
          alt=""
          style={{ marginRight: '5%' }}
          onClick={() => {
            setViewCart([]);
            dispatch(clearCart());
            props.history.goBack();
          }}
        />
      </div>
      <div className="head-box">
        <div className="title">
          {viewCart && viewCart.length > 0 && (
            <Translator id={viewCart[0].store_name_id} collection="words" />
          )}
        </div>
      </div>
      <div>
        {!loading && viewCart && viewCart.length > 0
          ? viewCart.map((item, index) => (
              <CartItem
                item={item}
                key={index}
                handleSetCart={handleSetCart}
                cartIndex={index}
                handleDeleteCart={handleDeleteCart}
              />
            ))
          : 'Empty Cart'}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '90%',
          margin: '12px 5%',
          color: 'black'
        }}
      >
        <div style={{ fontSize: '16px' }}>
          <Translator id="deliveryfee" type="local" />
        </div>
        <div>₩{formatPrice(DELIVERY_FEE)}</div>
      </div>
      <div className="min">
        <Translator id="minprice" type="local" /> ₩
        {(cart && cart.length > 0 && formatPrice(cart[0].store_min_price)) || 0}
      </div>
      <div style={{ width: '100%', height: '72px' }} />
      <ButtonBackground>
        <OrderButton
          onClick={() => {
            if (process.env.NODE_ENV === 'development')
              return props.history.push(`/order/checkout`);
            // todo : 최종주문에서도 체크필요
            if (parseInt(cart[0].store_min_price) > total - DELIVERY_FEE) {
              alert(stringReturn(lang, 'undermin'));
              return null;
            }
            props.history.push(`/order/checkout`);
          }}
        >
          <div className="name">
            <Translator id="makepayment" type="local" />
          </div>
          <div className="total"> ₩{formatPrice(total)}</div>
        </OrderButton>
      </ButtonBackground>
    </Container>
  );
};

export default withRouter(Cart);

const CartItem = ({ item, cartIndex, handleDeleteCart, handleSetCart }) => {
  const [openEdit, setOpenEdit] = useState(false);
  const [options, setOptions] = useState([...item.options]);
  const [count, setCount] = useState(item.counter);

  const editAvailable = useMemo(() => {
    if (options.filter(option => option.must).length === options.length) return false;
    if (options.length === 0) return false;
    return true;
  }, [options]);

  const total = useMemo(() => {
    let totalPrice = parseInt(formatTenPercentCommission(item.price));
    options &&
      options.forEach(option => (totalPrice += parseInt(formatTenPercentCommission(option.price))));
    return totalPrice * count;
  }, [item, options, count]);

  return (
    <ItemContainer>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Link to={`/store/menu/${item.id}`}>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              alignItems: 'center',
              marginRight: '0.25rem'
            }}
          >
            <Translator id={item.name_id} collection="words" />
          </div>
          <span style={{ fontSize: '12px' }}>{item.realName}</span>
        </Link>
        <div style={{ color: 'black' }}>₩{formatPrice(total)}</div>
      </div>
      <div style={{ width: '75%' }}>
        {options.length > 0 &&
          options.map((option, index) => (
            <div
              key={index}
              style={{
                lineHeight: 1.8,
                display: 'flex',
                alignItems: 'center',
                whiteSpace: 'normal'
              }}
            >
              <span>
                <Translator id={option.name_id} collection="words" name={option.realName} />
              </span>
              <span style={{ marginLeft: '0.3rem' }}>
                {option.price !== 0 &&
                  `(₩${formatPrice(formatTenPercentCommission(option.price))})`}
              </span>
              {openEdit && !option.must && (
                <img
                  src={deleteIcon}
                  alt=""
                  onClick={() => {
                    setOptions(options.filter((item, findIndex) => index !== findIndex));
                    handleSetCart(
                      'options',
                      options.filter((item, findIndex) => index !== findIndex),
                      cartIndex
                    );
                  }}
                />
              )}
            </div>
          ))}
      </div>
      <div
        style={{
          display: 'flex',
          marginTop: '24px',
          fontSize: '16px',
          color: 'black',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <div style={{ display: 'flex' }}>
          <img
            src={trashNormalIcon}
            alt=""
            onClick={() => {
              handleDeleteCart(cartIndex);
            }}
          />
          {options && editAvailable && (
            <>
              <img
                src={openEdit ? activeEditIcon : editIcon}
                alt=""
                style={{ marginLeft: '1rem' }}
                onClick={() => {
                  setOpenEdit(!openEdit);
                }}
              />
            </>
          )}
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            height: '24px'
          }}
        >
          <MinusIcon
            active={true}
            onClick={() => {
              let updateCount = count === 1 ? 1 : count - 1;
              setCount(updateCount);
              handleSetCart('counter', updateCount, cartIndex);
            }}
          />
          <span
            style={{
              width: '32px',
              textAlign: 'center',
              lineHeight: 1.14
            }}
          >
            {count}
          </span>
          <PlusIcon
            active={true}
            onClick={() => {
              let updateCount = count >= 50 ? count : count + 1;
              setCount(updateCount);
              handleSetCart('counter', updateCount, cartIndex);
            }}
          />
        </div>
      </div>
    </ItemContainer>
  );
};
