import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import Scene from 'scenejs';
import { Link, withRouter } from 'react-router-dom';
import { setLanguage } from '../redux/lang';
import { stringReturn } from '../common/Translator/Translator';
import { logo, cart as cartIcon, sidemenu } from '../images';
import Sidebar from './Sidebar';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: solid 1px #e6e6e6;
  height: 47px;
  position: sticky;
  top: 0;
  background: white;
  z-index: 300;
  .logo {
    width: 96px;
    height: 24px;
    transition: opacity 0.5s ease-out;
    -webkit-transition: opacity 0.5s ease-out;
  }
  .store {
    margin-left: 5%;
    font-size: 16px;
    line-height: 1.5;
    color: #000000;
    transition: opacity 0.5s ease-out;
    -webkit-transition: opacity 0.5s ease-out;
  }
`;

const Badge = styled.span`
  z-index: 301;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #ff3250;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -2px;
  right: -31px;
  font-size: 11px;
  font-weight: 600;
`;

const Black = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 150;
  background: rgba(0, 0, 0, 0.5);
`;

const Header = props => {
  const dispatch = useDispatch();
  const { lang } = useSelector(state => state.lang, []);
  const { cart } = useSelector(state => state.cart, []);
  const { info } = useSelector(state => state.info, []);

  const [openSide, setOpenSide] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);

  const onChangeLang = e => {
    dispatch(setLanguage(e.target.value));
    setOpenSide(false);
  };

  useEffect(() => {
    if (!isFirstRender && cart.length > 0) {
      new Scene(
        {
          '#badge': {
            0: {
              opacity: 0.5,
              transform: 'scale(1)'
            },
            0.15: {
              opacity: 0.75,
              transform: 'scale(1.6)'
            },
            0.35: {
              opacity: 1,
              transform: 'scale(1)'
            }
          }
        },

        {
          selector: true,
          easing: 'ease-in-out'
        }
      ).play();
    }
    setIsFirstRender(false);
  }, [cart]); // eslint-disable-line

  return (
    <>
      <Container>
        {props.store ? (
          <div
            style={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              maxWidth: '200px'
            }}
            className="store"
            id="header-store"
            onClick={() => {
              props.history.push(`/store/${props.id}`);
            }}
          >
            {props.store}
          </div>
        ) : (
          <Link to={`/`} style={{ marginLeft: '5%', marginTop: '4px' }}>
            <img src={logo} className="logo" alt="" id="header-logo" />
          </Link>
        )}
        <div style={{ display: 'flex', marginRight: '5%' }}>
          <div style={{ display: 'flex' }}>
            {cart && cart.length > 0 ? (
              <Badge id="badge">
                <span>{cart.length}</span>
              </Badge>
            ) : (
              <span style={{ width: '16px', height: '1px' }} />
            )}
            <img
              src={cartIcon}
              alt=""
              id="cart"
              style={{ marginRight: '16px' }}
              onClick={() => {
                sessionStorage.setItem('prevUrl', props.match.url);
                cart.length > 0
                  ? props.history.push(`/order/cart`)
                  : alert(stringReturn(lang, 'empty'));
              }}
            />
          </div>
          <img
            src={sidemenu}
            alt=""
            onClick={() => {
              setOpenSide(!openSide);
            }}
          />
        </div>
      </Container>
      <Sidebar open={openSide} onChangeLang={onChangeLang} info={info} />
      {openSide && (
        <Black
          onClick={() => {
            setOpenSide(false);
          }}
        />
      )}
    </>
  );
};

export default withRouter(Header);
