import axios from 'axios';
import { logger } from '../utils';

const authKey = process.env.REACT_APP_FUNCTIONS_AUTH_KEY;

const BASE_URL =
  process.env.NODE_ENV === 'production'
    ? `https://us-central1-deliquick-93b6b.cloudfunctions.net/api`
    : `http://localhost:5000/deliquick-93b6b/us-central1/api`;

const Payment = class {
  static doPayment = ({ ...orderData }, exchangeRatio) => {
    const isProduction = process.env.NODE_ENV === 'production';

    // 달러 환율 계산 : Math.round((total * 0.00085).toFixed(3) * 100) / 100,
    const getAmount = (pg, total) => {
      switch (pg) {
        case 'paypal':
          return Math.round((total * exchangeRatio).toFixed(3) * 100) / 100;
        // case 'eximbay':
        //   return isProduction ? total : Math.round((total * exchangeRatio).toFixed(3) * 100) / 100;
        // case 'molpay':
        //   return isProduction ? total : Math.round((total * exchangeRatio).toFixed(3) * 100) / 100;
        // case 'paysbuy':
        //   return isProduction ? total : Math.round((total * exchangeRatio).toFixed(3) * 100) / 100;
        // case 'tenpay':
        //   return isProduction ? total : Math.round((total * exchangeRatio).toFixed(3) * 100) / 100;
        default:
          return isProduction ? total : 1000;
      }
    };

    const getPg = (select) => {
      switch (select) {
        case 'paypal':
          return 'paypal';
        case 'inicis':
          return 'html5_inicis';
        default:
          return 'eximbay';
      }
    };

    const getLanguage = (lang, pg) => {
      switch (pg) {
        case 'html5_inicis':
          return 'kr';
        default:
          return lang;
      }
    };

    const getCurrency = (pg) => {
      switch (pg) {
        case 'paypal':
          return 'USD';
        case 'html5_inicis':
          return 'KRW';
        default:
          // return isProduction ? 'KRW' : 'USD';
          return 'KRW';
      }
    };

    const pg = getPg(orderData.pay);
    const language = getLanguage(orderData.language, pg);
    const currency = getCurrency(pg);
    const amount = getAmount(pg, orderData.total);

    const pgRequestParam = {
      pg,
      pay_method: orderData.pay, // unionpay, alipay, card
      merchant_uid: orderData.orderNumber,
      name: `DeliQuick (${orderData.cart.length} ${
        orderData.cart.length === 1 ? 'item' : 'items'
      })`,
      amount,
      buyer_email: orderData.email.email || '',
      buyer_name: orderData.hotel + ' Guest',
      language,
      company: 'DeliQuick',
      currency,
      m_redirect_url: `${window.location.origin}/order/complete`,
    };

    logger(pgRequestParam);
    return new Promise((resolve) => {
      window.IMP.request_pay(
        pgRequestParam,
        // callback
        (response) => {
          resolve(response);
        }
      );
    });
  };

  static sendPushMsg = ({
    store_id,
    storeName,
    orderNumber,
    hotelName,
    cart,
    address,
    storeStatus,
  }) => {
    return axios
      .post(`${BASE_URL}/order/sendkakao`, {
        id: store_id,
        storeName,
        orderNumber,
        hotelName,
        cart,
        address,
        storeStatus,
        dateString: new Date().toLocaleString('ko-KR'),
      })
      .then(() => {
        logger('send msg success');
        return true;
      })
      .catch((err) => console.log(err));
  };

  static checkPayStatus = (orderNumber) => {
    return axios
      .post(
        `${BASE_URL}/order/get`,
        {
          orderNumber,
        },
        {
          headers: {
            Authorization: authKey,
          },
        }
      )
      .then((result) => {
        if (result.data.status === 'FAILED') return { status: 'not exist' };
        return result.data.result.response;
      });
  };
};

export default Payment;
