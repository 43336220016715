const SET_LANGUAGE = "lang/SET_LANGUAGE";

export const setLanguage = value => ({ type: SET_LANGUAGE, payload: value });

const initialState = {
  lang: null
};

const lang = (state = initialState, action) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return {
        ...state,
        lang: action.payload
      };
    default:
      return state;
  }
};

export default lang;
