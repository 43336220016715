import { all, fork, cancelled, put, take } from 'redux-saga/effects';
import { orderSaga } from './order';
import { infoSaga } from './info';
import { storeSaga } from './store';

export default function* rootSaga() {
  yield all([fork(orderSaga), fork(infoSaga), fork(storeSaga)]);
}

// utils
export function* syncChannel(channel, successAction, failAction) {
  const transform = (snapshots) => {
    let result = [];
    snapshots.forEach((doc) => {
      result.push({ ...doc.data(), id: doc.id });
    });
    return result;
  };
  try {
    while (true) {
      const data = yield take(channel);
      const transformedData = transform ? transform(data) : data;
      yield put(successAction(transformedData));
    }
  } catch (err) {
    /* eslint-disable no-console */
    if (failAction) yield put(failAction(err));
    else console.error(err);
  } finally {
    if (yield cancelled()) channel.close();
  }
}
