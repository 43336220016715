import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { down_f as downIcon } from '../images';
import animateScrollTo from 'animated-scroll-to';
import { Link } from 'react-router-dom';

const Container = styled.div`
  background-color: #f6f6f6;
  width: 100%;
  color: #aaaaaa;
  line-height: 1;
  font-size: 12px;
  text-align: center;
  .footer-top {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.33;
    padding: 1rem 0;
  }
  .footer-bottom {
    display: flex;
    margin-top: 1rem;
    color: #aaaaaa;
    .part {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      line-height: 1.33;
      padding: 1rem 0;
      font-weight: 400;
      border-top: solid 1px #e6e6e6;
      border-left: solid 1px #e6e6e6;
    }
  }
`;

const Footer = () => {
  const [open, setOpen] = useState(true);
  const imgRef = useRef();
  const openInfo = () => {
    setOpen(!open);
    imgRef.current.style.marginTop = open ? '1px' : '-5px';
    imgRef.current.style.transform = open ? 'rotate(180deg)' : 'rotate(0deg)';
    setTimeout(() => {
      animateScrollTo(document.body.clientHeight, { speed: 1500 });
    }, 100);
  };
  return (
    <Container>
      <div
        className="footer-top"
        onClick={() => {
          openInfo();
        }}
      >
        <div>DeliQuick</div>
        <img
          src={downIcon}
          alt=""
          style={{ marginTop: '-5px', transition: 'transform 0.15s linear' }}
          ref={imgRef}
        />
      </div>
      {!open && (
        <>
          <div style={{ lineHeight: 1.35, padding: '0 1rem' }}>
            <b>E-Mail help@publics.co.kr | Tel 02-577-0420</b>
            <br />
            <br />
            <b>(주)선진나노테크</b>
            <br />
            사업자등록번호 163-88-00051 <br /> 통신판매업신고번호 제 2019-서울강남-00508호
            <br />
            서울특별시 중구 청계천로 40, 한국관광공사 707호{' '}
            <br />
            All Contents Copyright © {new Date().getFullYear()}{' '}
            <span
              onClick={() => {
                localStorage.clear();
                sessionStorage.clear();
              }}
            >
              Publics Inc.
            </span>
          </div>
          <div className="footer-bottom" style={{ lineHeight: 1.33, fontWeight: 'bold' }}>
            <div className="part">
              <Link to="/contract/use">
                이용약관 | 환불규정
                <br />
                (Terms of Use)
              </Link>
            </div>

            <div className="part">
              <Link to="/contract/privacy">
                개인정보보호정책
                <br />
                (Privacy Policy)
              </Link>
            </div>
          </div>
        </>
      )}
    </Container>
  );
};

export default Footer;
