import React from 'react';
import Swiper from 'react-id-swiper';

const Slider = ({ children, auto, speed }) => {
  const SPEED = speed || 5000;

  return (
    <Swiper
      autoplay={
        auto
          ? {
              delay: SPEED
            }
          : false
      }
      loop
    >
      {children}
    </Swiper>
  );
};

export default Slider;

// const useInterval = (callback, delay) => {
//   const savedCallback = useRef();

//   useEffect(() => {
//     savedCallback.current = callback;
//   }, [callback]);

//   useEffect(() => {
//     const tick = _ => {
//       savedCallback.current();
//     };
//     if (delay !== null) {
//       let id = setInterval(tick, delay);
//       return () => clearInterval(id);
//     }
//   }, [delay]);
// };

// import React, { useRef, useState, useEffect } from 'react';
// import styled from 'styled-components';
// import { Swipeable } from 'react-swipeable';
// import arrow_r from '../../images/arrow-r.svg';
// import arrow_l from '../../images/arrow-l.svg';

// const Container = styled.div`
//   height: ${props => props.height};
//   width: 100%;
//   overflow: hidden;
//   .contents {
//     width: ${({ length }) => length * 100 + '%'};
//     font-size: 20px;
//     transition: transform 0.3s linear;
//     display: flex;
//     transform: ${({ percent }) => `translateX(-${percent}%)`};
//   }
//   .btn {
//     position: relative;
//     z-index: 10;
//     width: 26px;
//     height: 26px;
//     font-size: 14px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     top: ${({ top }) => top * 0.5 + 'px'};
//     float: left;
//     border-radius: 50%;
//     color: #aaa;
//     cursor: pointer;
//     font-weight: bold;
//     background: rgba(255, 255, 255, 0.5);
//     margin: 0 0.5em;
//     user-select: none;
//     margin-top: -30px;
//     &:hover {
//       background: rgba(255, 255, 255, 0.9);
//     }
//   }
// `;

// const Slider = ({ height, children, auto, speed }) => {
//   const ref = useRef();
//   const [length] = useState(children.length + 1);
//   const [percent] = useState(100 / length);
//   const [position, setPosition] = useState(1);
//   const SPEED = speed || 3000;

//   const moveToRight = _ => {
//     if (!ref.current) return null;
//     const currentPer = -position * percent;
//     const movePer = position < length - 1 ? currentPer - percent : currentPer;

//     if (position === length - 1) {
//       ref.current.style.transition = `none`;
//       ref.current.style.transform = `translateX(${0}%)`;
//       setTimeout(() => {
//         ref.current.style.transition = `transform 0.3s linear`;
//         ref.current.style.transform = `translateX(-${percent}%)`;
//       }, 50);
//     } else {
//       ref.current.style.transition = `transform 0.3s linear`;
//       ref.current.style.transform = `translateX(${movePer}%)`;
//     }

//     setPosition(position < length - 1 ? position + 1 : 1);
//   };

//   const moveToLeft = _ => {
//     if (!ref.current) return null;
//     const currentPer = -position * percent;
//     const movePer = position !== 0 ? currentPer + percent : currentPer;

//     if (position === 1) {
//       ref.current.style.transition = `transform 0.3s linear`;
//       ref.current.style.transform = `translateX(${movePer}%)`;
//       setTimeout(() => {
//         ref.current.style.transition = `none`;
//         ref.current.style.transform = `translateX(-${percent * (length - 1)}%)`;
//       }, 300);
//     } else {
//       ref.current.style.transition = `transform 0.3s linear`;
//       ref.current.style.transform = `translateX(${movePer}%)`;
//     }

//     setPosition(position !== 1 ? position - 1 : length - 1);
//   };

//   useInterval(() => {
//     auto && moveToRight();
//   }, SPEED);

//   const config = {
//     onSwipedLeft: () => moveToRight(),
//     onSwipedRight: () => moveToLeft(),
//     preventDefaultTouchmoveEvent: true,
//     trackMouse: true
//   };

//   return (
//     <Swipeable {...config}>
//       <Container
//         top={(ref && ref.current && ref.current.clientHeight + 26) || '50%'}
//         height={height}
//         length={length}
//         percent={percent}
//       >
//         <div className="btn" onClick={moveToLeft}>
//           <img src={arrow_l} alt="" style={{ width: '18px' }} />
//         </div>
//         <div className="btn" style={{ float: 'right' }} onClick={moveToRight}>
//           <img src={arrow_r} alt="" style={{ width: '18px' }} />
//         </div>
//         <div className="contents" ref={ref}>
//           {children[children.length - 1]}
//           {children}
//         </div>
//       </Container>
//     </Swipeable>
//   );
// };

// export default Slider;

// const useInterval = (callback, delay) => {
//   const savedCallback = useRef();

//   useEffect(() => {
//     savedCallback.current = callback;
//   }, [callback]);

//   useEffect(() => {
//     const tick = _ => {
//       savedCallback.current();
//     };
//     if (delay !== null) {
//       let id = setInterval(tick, delay);
//       return () => clearInterval(id);
//     }
//   }, [delay]);
// };
