import React, { useState, useEffect, useMemo, useRef, useContext } from 'react';
import styled from 'styled-components';
import { withRouter, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { formatPrice } from '../../common/format';
import { FirebaseContext } from '../../firebase';
import { Checkbox } from '../../components/Icons/Checkbox';
import Translator from '../../common/Translator/Translator';
import {
  location,
  down as downIcon,
  credit as creditIcon,
  creditActive,
  paypal,
  paypalActive,
  unionpay,
  unionpayActive,
  alipay,
  alipayActive,
  wechatpay,
  wechatpayActive,
} from '../../images';
import Icon from '../../components/Icons';
import CountryList from '../../common/countryList';
import OrderData from '../../common/Classes/OrderData';
import { requestPayment } from '../../redux/order';
import { format, addDays, isAfter } from 'date-fns';

// Import
const axios = require('axios');
const async = require('async');
const firebase_app = require('firebase/app');
const firebase_database = require('firebase/firestore');
const admin = require("firebase-admin");

// Firebase config
const config = {
  apiKey: "AIzaSyCVPP2EK041q3QgZxdQEu2mgyisHiWRhoI",
  authDomain: "deliquick-93b6b.firebaseapp.com",
  databaseURL: "https://deliquick-93b6b.firebaseio.com",
  projectId: "deliquick-93b6b",
  storageBucket: "deliquick-93b6b.appspot.com",
  messagingSenderId: "371258643019"
}

if (!firebase_app.apps.length) {
   firebase_app.initializeApp(config);
}else {
   firebase_app.app(); // if already initialized, use that one
}
var db = firebase_app.firestore();

// Shuttle url
const shuttle_url = 'https://test.shuttledelivery.co.kr/api' //'https://www.shuttledelivery.co.kr/api'

// API key
const api_key = 'a5dc229a9db8-2423de5b-4707-b730-870c' // '12ff10a2-ea37-4172-a46e-00db91ec8e98'

async function get_addons(shuttle_header, branch_id, menu_id_list){

	const addons_list = []
	var i = 0
	for (i in menu_id_list){
		const shuttle_get_addons_url = shuttle_url + '/branch/' + String(branch_id) + '/menu/' + String(menu_id_list[i]) + '/item_details?language=en'
		const addons = []
		
		const config = {
		  method: 'get',
		  url: shuttle_get_addons_url,
		  headers: shuttle_header
		};

		const response = await axios(config)
		var a = 0
		for (a in response.data['Result']['AddonsByCategories'][0]['Addons']){
			addons.push(response.data['Result']['AddonsByCategories'][0]['Addons'][a]['Id'])
		}
		addons_list.push(addons)
		
		if ((i + 1) === menu_id_list.length){
			return addons_list
		}
	}
}

async function post_user(shuttle_header){

	const shuttle_post_user_url = shuttle_url + '/user'
	const user_name = String(Math.random().toString(36).substr(2,11))
	const email = user_name + '@gmail.com'
	const pass_word = String(Math.random().toString(36).substr(2,11)) + '!!'
	
	const first_name = 'TEMP'
	const last_name = 'TEMP'
	
	const post_data = {
		"apikey": api_key,
		"Email": email,
		"Password": pass_word,
		"Language": "en",
		"FirstName": first_name,
		"LastName": last_name
	}

	const response = await axios.post(shuttle_post_user_url,
			{"headers":shuttle_header},
			{"data":post_data}
		)

	const result_data = {
		"email": email,
		"pass_word": pass_word,
		"user_id": response.data['Result']['Id']
	}
	
	return result_data
}

async function post_access_token(shuttle_header, email, pass_word){
	
	const shuttle_post_access_token_url = shuttle_url + '/user/access_token'
	
	const post_data = 
	{
		"apikey": api_key,
		"email": email,
		"password": pass_word,
		"Language": "en"
	}

	const response = await axios.post(shuttle_post_access_token_url,
		{"headers":shuttle_header},
		{"data":post_data}
	)
	
	return response.data['Result']['access_token']
}

async function user_add_address(shuttle_header, user_id, location_hash){
	
	const shuttle_user_add_address_url = shuttle_url + '/user/' + String(user_id) + '/address'

	const post_data = JSON.stringify(
	{
	  "LocationHash": location_hash,
	  "Addressline": "",
	  "Phone1": "",
	  "Notes": "",
	  "Addressnic": "",
	  "ApartmentNumber": "",
	  "BuildingNumber": "",
	  "CheckNickname": ""
	});
	
	const config = {
	  method: 'post',
	  url: shuttle_user_add_address_url,
	  headers: shuttle_header,
	  data : post_data
	};

	const response = await axios(config)
	
	return response.data['Result']['Id']
}


async function post_cart(shuttle_header, branch_id, location_hash, email){
	
	const shuttle_post_cart_url = shuttle_url + '/cart'
	
	const post_data = JSON.stringify(
	{
		"BranchId":branch_id,
		"LocationHash":location_hash,
		"apikey":api_key,
		"language":"en"
	});

	const config = {
	  method: 'post',
	  url: shuttle_post_cart_url,
	  headers: shuttle_header,
	  data : post_data
	};

	const response = await axios(config)
	
	return response.data['Result']['CartId']
}

async function cart_add_position(shuttle_header, cart_id, menu_id_list, variant_id_list, quantity_list){
	
	const shuttle_post_add_position_url = shuttle_url + '/cart/' + String(cart_id) + '/position'
	const message_list = []
	
	var i = 0
	for (i in menu_id_list){
		
		const post_data = JSON.stringify({
		"MenuItemId":menu_id_list[i],
		"VariantId":variant_id_list[i],
		"Addons":"",
		"Quantity":quantity_list[i]
		});

		const config = {
		  method: 'post',
		  url: shuttle_post_add_position_url,
		  headers: shuttle_header,
		  data : post_data
		};

		const response = await axios(config)
		message_list.push(response.data['Result']['PaymentMethodRestrictionMessage'])
	}
	
	return message_list
}

async function cart_check(shuttle_header, cart_id){
	
	const shuttle_cart_check_url = shuttle_url + '/cart/' + String(cart_id) + '/check'

	const config = {
	  method: 'get',
	  url: shuttle_cart_check_url,
	  headers: shuttle_header
	};

	const response = await axios(config)
	
	return response.data
}

async function cart_order(shuttle_header, cart_id, address_id){
	
	const shuttle_cart_order_url = shuttle_url + '/order'
	
	const post_data = JSON.stringify({
		"CartId":cart_id,
		"AddressId":String(address_id),
		"PaymentMethod":"deliquick",
		"PickupOption":false,
		"PlasticCutlery":true,
		"OrderNotes":"",
		"AddressComments":"",
		"DeliveryRequestId":1
		});
	
	const config = {
	  method: 'post',
	  url: shuttle_cart_order_url,
	  headers: shuttle_header,
	  data : post_data
	};
	
	const has_error = false
	const error_message = ''
	
	await axios(config)
	.then(function (response) {
		console.log(JSON.stringify(response.data));
	})
	.catch(function (error) {
		console.log('error')
		const error_message = error.response.data['ResultStatus']['ErrorMessage']
		const has_error = true
		//console.log(has_error)
		//console.log(error_message)
	});
	
	const result = [has_error, error_message]
	return result
}

async function delete_user(shuttle_header, user_id){
	
	const shuttle_delete_user_url = shuttle_url + '/user/' + String(user_id)
	
	const shuttle_delete_user_axios = await axios.delete(shuttle_delete_user_url,
		{"headers":shuttle_header}
	)
	
	return "Complete to delete user"
}

/*server.listen(port, hostname, () => {
	
	console.log(`Server running at http:${hostname}:${port}`);
	
	branch_id = 56
	menu_id_list = [259]
	location_hash = "wydm8mf5b"
	variant_id_list = [385]
	quantity_list = [2]
	
	shuttle_header = {
	  'Authorization': 'Basic c2h1dHRsZTpwcmV2aWV3NHU',
	  'apikey': api_key,
	  'Content-Type': 'application/json',
	  'language': 'en'
	}
	
	//get_addons_promise = get_addons(shuttle_header, branch_id, menu_id_list)
	//get_addons_promise.then(function(addons_list) {
		//console.log(addons_list)
	//})
	
	post_user_promise = post_user(shuttle_header)
	post_user_promise.then(function(post_user_result) {
		
		//console.log(post_user_result)
		email = post_user_result['email']
		pass_word = post_user_result['pass_word']
		user_id = post_user_result['user_id']
		
		post_access_token_promise = post_access_token(shuttle_header, email, pass_word)
		post_access_token_promise.then(function(access_token) {
			
			//console.log(access_token)
			shuttle_header['Shuttle-Authorization'] = access_token

			user_add_address_promise = user_add_address(shuttle_header, user_id, location_hash)
			user_add_address_promise.then(function(address_id) {
				
				//console.log(address_id)
				
				post_cart_promise = post_cart(shuttle_header, branch_id, location_hash)
				post_cart_promise.then(function(cart_id) {
					
					//console.log(cart_id)
					
					cart_add_position_promise = cart_add_position(shuttle_header, cart_id, menu_id_list, variant_id_list, quantity_list)
					cart_add_position_promise.then(function(message_list) {
						
						//console.log(message_list)
						
						cart_check_promise = cart_check(shuttle_header, cart_id)
						cart_check_promise.then(function(check_response_data) {
							
							//console.log(check_response_data['ResultStatus'])
							//console.log(check_response_data['Result'])
							
							cart_order_promise = cart_order(shuttle_header, cart_id, address_id)
							cart_order_promise.then(function(order_response_flag) {
								
								//console.log(order_response_flag)

								delete_user_promise = delete_user(shuttle_header, user_id)
								delete_user_promise.then(function(delete_string) {
									
									console.log(delete_string)
									
								})
							})
						})
					})
				})
			})
		})
	})
});*/

const paymentArray = [
  {
    name: 'credit',
    defaultIcon: creditIcon,
    activeIcon: creditActive,
    value: 'card',
  },
  {
    name: 'paypal',
    defaultIcon: paypal,
    activeIcon: paypalActive,
    value: 'paypal',
  },
  {
    name: 'alipay',
    defaultIcon: alipay,
    activeIcon: alipayActive,
    value: 'alipay',
  },
  {
    name: 'unionpay',
    defaultIcon: unionpay,
    activeIcon: unionpayActive,
    value: 'unionpay',
  },
  {
    name: 'molpay',
    defaultIcon: creditIcon,
    activeIcon: creditActive,
    value: 'molpay',
  },
  {
    name: 'yandex',
    defaultIcon: creditIcon,
    activeIcon: creditActive,
    value: 'paysbuy',
  },
  {
    name: 'wechatpay',
    defaultIcon: wechatpay,
    activeIcon: wechatpayActive,
    value: 'wechatpay',
  },
  {
    name: 'inicis',
    defaultIcon: creditIcon,
    activeIcon: creditActive,
    value: 'inicis',
  },
];

const Order = (props) => {
  const { cart } = useSelector((state) => state.cart, []);
  const { info } = useSelector((state) => state.info, []);
  const { lang } = useSelector((state) => state.lang, []);
  const [viewCart, setViewCart] = useState([]);

  const [openPay, setOpenPay] = useState(false);
  const [pay, setPay] = useState(0); // 결제수단 선택용

  const [check, setCheck] = useState(false);
  const [marketingCheck, setMarketingCheck] = useState(false);
  const [inputData, setInputData] = useState({
    roomNo: info.qrData.roomNo || '',
    reservationDate: isAfter(new Date().toDateString() + ' 18:00', new Date())
      ? format(addDays(new Date(), 1), 'YYYY-MM-DD')
      : format(new Date(), 'YYYY-MM-DD'),
    // reservationTime: format(new Date(), 'HH:mm'),
  });
  const [errorMsg, setErrorMsg] = useState(null);
  const dispatch = useDispatch();
  const { db } = useContext(FirebaseContext);
  const [coupon, setCoupon] = useState(null);

  // 서비스비 (배달비: 고객이 추가 결제할 비용)
  const DELIVERY_FEE = useMemo(() => {
    // if (process.env.NODE_ENV === 'development') return 0;
    const storeFee = (cart && cart.length > 0 && cart[0].store_delivery_fee) || 0;
    return parseInt(info.serviceFee || 0) + parseInt(storeFee);
  }, [cart, info]);

  const total = useMemo(() => {
    let totalPrice = 0;
    viewCart.map((item) => (totalPrice += item.total));
    if (coupon) {
      if (coupon.type === 'minus') {
        totalPrice -= coupon.amount;
      } else if (coupon.type === 'percent') {
        totalPrice = totalPrice * ((100 - parseInt(coupon.amount)) / 100);
      }
    }
    return totalPrice + DELIVERY_FEE;
  }, [viewCart, DELIVERY_FEE, coupon]);

  const payRef = useRef();
  const extendPayRef = useRef();

  const openInfo = (open, ref, setter) => {
    setter(!open);
    ref.current.style.marginTop = !open ? '1px' : '-5px';
    ref.current.style.transform = !open ? 'rotate(180deg)' : 'rotate(0deg)';
  };

  const animateOpenPay = (open, ref) => {
    const height = document.getElementById('section').offsetHeight;
    const length = paymentArray.length - 1;
    ref.current.style.height = !open ? height * length + 'px' : '0px';
  };

  const [storeData, setStoreData] = useState(false);

  useEffect(() => {
    (async () => {
      if (cart[0]?.store_id) {
        const data = await db
          .collection('stores')
          .doc(cart[0].store_id)
          .get()
          .then((doc) => ({ ...doc.data(), id: doc.id }));

        setStoreData(data);
      }
    })();
  }, []); // eslint-disable-line

  useEffect(() => {
    setViewCart(cart);
    if (cart.length === 0) {
      props.history.push('/');
    }
  }, [cart, props.history]);

  const makeOrder = async () => {
    if (!check) {
      return null;
    } else if (!info.code) {
      return alert('Error! (Invalid path)');
    } else if (info.code === 'GDS001' && process.env.NODE_ENV === 'production') {
      return alert('샘플페이지는 결제가 불가능합니다.');
    }
    if ((isRoomServiceAvailable && !inputData.reservationTime) || !inputData.reservationDate)
      return alert('Select reservation date and time');
    if (info.isPhoneNoAble && !inputData.phoneNo) return alert('Enter your "phone number"');
    if (info.isRoomNoAble && !inputData.roomNo) return alert('Enter your "room number"');
	
    var order = new OrderData(
      info,
      viewCart,
      storeData,
      inputData,
      { pay: paymentArray[pay].value, total },
      lang,
      coupon
    );
	const { hotel_id, store_id, cart } = order.data;
	
	// -- Shuttle  Order Part --
	// Shuttle에서 추가된 가게에서 구매할 경우
	
	const searchText = '_Shuttle_'
	if(store_id.includes(searchText)){
		// 셔틀 서버에 보낼 필요한 변수 세팅
		const location_hash = await db
		.collection('hotels')
		.doc(hotel_id)
		.get()
		.then((doc) => doc.data()?.position);
		const menu_id_list = cart.map(({ id }) => id)
		const variant_id_list = cart.map(({ counter }) => counter)
		const quantity_list = cart.map(({ variant }) => variant)
		
		// 셔틀 헤더
		const shuttle_header = {
		  'Authorization': 'Basic c2h1dHRsZTpwcmV2aWV3NHU',
		  'apikey': api_key,
		  'Content-Type': 'application/json',
		  'language': 'en'
		}
		
		// 셔틀 서버 오더 프로세스
		const post_user_promise = post_user(shuttle_header)
		post_user_promise.then(function(post_user_result) {
			alert('comp')
			//console.log(post_user_result)
			const email = post_user_result['email']
			const pass_word = post_user_result['pass_word']
			const user_id = post_user_result['user_id']
			
			const post_access_token_promise = post_access_token(shuttle_header, email, pass_word)
			post_access_token_promise.then(function(access_token) {
				
				//console.log(access_token)
				shuttle_header['Shuttle-Authorization'] = access_token

				const user_add_address_promise = user_add_address(shuttle_header, user_id, location_hash)
				user_add_address_promise.then(function(address_id) {
					
					//console.log(address_id)
					
					const post_cart_promise = post_cart(shuttle_header, store_id, location_hash)
					post_cart_promise.then(function(cart_id) {
						
						//console.log(cart_id)
						
						const cart_add_position_promise = cart_add_position(shuttle_header, cart_id, menu_id_list, variant_id_list, quantity_list)
						cart_add_position_promise.then(function(message_list) {
							
							//console.log(message_list)
							
							const cart_check_promise = cart_check(shuttle_header, cart_id)
							cart_check_promise.then(function(check_response_data) {
								
								//console.log(check_response_data['ResultStatus'])
								//console.log(check_response_data['Result'])
								
								if (check_response_data['ResultStatus']['HasError']){ // 주문 불가능할 때 (카트 검사)
									return alert('zzz')//(check_response_data['ResultStatus']['ErrorMessage'])
									const delete_user_promise = delete_user(shuttle_header, user_id)
									delete_user_promise.then(function(delete_string) {
										//console.log(delete_string)
									})
									return
								}
								
								const cart_order_promise = cart_order(shuttle_header, cart_id, address_id)
								cart_order_promise.then(function(order_result) { // 주문 불가능할 때 (오더)
									
									const order_response_flag = order_result[0]
									const error_message = order_result[1]
									
									const delete_user_promise = delete_user(shuttle_header, user_id)
									delete_user_promise.then(function(delete_string) {
										
										//console.log(delete_string)
										
									})
									
									if(!order_response_flag){ // 주문 성공적으로 되었을 시 Deliquick 결제 프로세스 진행
										return alert('succ')
										console.log('order complete')
									}
									else{
										console.log(error_message)
										return alert(error_message)
									}
								})
							})
						})
					})
				})
			})
		})
		
	}
	
    const isValid = await order.checkOrderNumberValid();
	
    // 주문번호 중복검사후 중복이면 다시 호출
    if (!isValid) {
      return makeOrder();
    }

    dispatch(requestPayment(order.data));
  };

  const onInputChange = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  const checkCoupon = async () => {
    if (!inputData.couponId) return;
    const id = inputData.couponId.toUpperCase();
    const docRef = db.collection('coupons').doc(id);
    const data = await docRef.get().then((doc) => doc.data());
    if (!data) return alert('Unavalilabe Code');
    if (data.isUsed) return alert('Already Used');
    const [type, amount] = data.type.split('_');
    setCoupon({
      type,
      amount,
      id,
    });
  };

  const isRoomServiceAvailable = useMemo(() => {
    if (!storeData) return;
    const roomServiceTag = '4wCiR7tgDZ54vixEF5Gr';
    return storeData.tags.includes(roomServiceTag);
  }, [storeData]);

  // total 옵션 삭제시 같이 삭제 되게
  return (
    <Container>
      <div className='header'>
        <Icon
          type='exit'
          alt=''
          style={{ marginLeft: '5%' }}
          onClick={() => {
            props.history.push('/order/cart');
          }}
        />

        <div style={{ fontSize: '16px', lineHeight: 1.5, color: 'black' }}>
          <Translator id='order' type='local' />
        </div>
        <div style={{ width: '10px', height: '10px', marginRight: '5%' }} />
      </div>
      <div style={{ marginTop: '48px' }}>
        {viewCart.length > 0 && viewCart.map((item, index) => <CartItem item={item} key={index} />)}
      </div>
      <div
        style={{
          padding: '12px 16px',
          display: 'flex',
          flexDirection: 'column',
          lineHeight: 1.71,
        }}
      >
        {coupon && (
          <div className='price'>
            <span>Discount</span>
            <span>
              -{coupon.type === 'minus' && '₩'}
              {coupon.amount}
              {coupon.type === 'percent' && '%'}
            </span>
          </div>
        )}
        <div className='price'>
          <span>
            <Translator id='subtotal' type='local' />
          </span>
          <span>₩{formatPrice(total - DELIVERY_FEE)}</span>
        </div>

        <div className='price'>
          <span>
            <Translator id='deliveryfee' type='local' />
          </span>
          <span>₩{formatPrice(DELIVERY_FEE)}</span>
        </div>

        <div
          className='price'
          style={{
            color: 'black',
            lineHeight: 1.5,
            fontSize: '16px',
            fontWeight: 'bold',
          }}
        >
          <span>
            <Translator id='total' type='local' />
          </span>
          <span>₩{formatPrice(total)}</span>
        </div>
      </div>
      <div
        className='section'
        id='section'
        style={{
          justifyContent: 'space-between',
          color: '#ff3250',
        }}
        onClick={() => {
          openInfo(openPay, payRef, setOpenPay);
          animateOpenPay(openPay, extendPayRef);
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={paymentArray[pay].activeIcon} alt='' style={{ marginRight: '8px' }} />
          <Translator id={paymentArray[pay].name} type='local' />
        </div>
        <img src={downIcon} alt='' style={{ marginTop: '-5px', transition: 'transform 0.15s linear' }} ref={payRef} />
      </div>

      <div
        ref={extendPayRef}
        style={{
          overflow: 'hidden',
          transition: 'all 0.15s ease-out',
          height: 0,
        }}
      >
        {paymentArray.map((item, index) => (
          <React.Fragment key={index}>
            {index !== pay && (
              <div
                className='section'
                onClick={() => {
                  setPay(index);
                  setOpenPay(!openPay);
                  animateOpenPay(openPay, extendPayRef);
                  openInfo(openPay, payRef, setOpenPay);
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img src={item.defaultIcon} alt='' style={{ marginRight: '8px' }} />
                  <Translator id={item.name} type='local' />
                </div>
              </div>
            )}
          </React.Fragment>
        ))}
      </div>

      <div className='section'>
        <div style={{ display: 'flex', alignItems: 'center', color: '#373751' }}>
          <img src={location} alt='' style={{ marginRight: '8px' }} />
          {info.name_en} {inputData.roomNo}
        </div>
      </div>
      {isRoomServiceAvailable && (
        <div
          className='section'
          style={{
            background: '#fff',
            flexDirection: 'column',
            fontSize: '12px',
          }}
        >
          <input
            id='date_picker'
            className='input'
            type='date'
            name='reservationDate'
            placeholder='Select date'
            onChange={onInputChange}
            value={inputData.reservationDate}
            min={
              isAfter(new Date().toDateString() + ' 18:00', new Date())
                ? format(addDays(new Date(), 1), 'YYYY-MM-DD')
                : format(new Date(), 'YYYY-MM-DD')
            }
            max={format(addDays(new Date(), 3), 'YYYY-MM-DD')}
          />
          <div style={{ display: 'flex', marginTop: '8px' }}>
            {['18:00', '18:30', '19:00', '19:30', '20:00'].map((time) => (
              <div
                key={time}
                style={{
                  margin: '0 4px',
                  padding: '6px 8px',
                  color: `${inputData.reservationTime === time ? `#ff3250` : `#aaa`}`,
                  border: `1px solid ${inputData.reservationTime === time ? `#ff3250` : `#aaa`}`,
                  borderRadius: '18px',
                }}
                onClick={() =>
                  onInputChange({
                    target: {
                      name: 'reservationTime',
                      value: time,
                    },
                  })
                }
              >
                {time}
              </div>
            ))}
          </div>
        </div>
      )}
      {info && info.isPhoneNoAble && (
        <div
          className='section'
          style={{
            background: '#fff',
            flexDirection: 'column',
            fontSize: '12px',
          }}
        >
          <div style={{ marginBottom: '8px' }}>
            <CustomTranslator lang={lang} />
          </div>

          <CountryList lang={lang} setData={setInputData} />

          <input
            type='text'
            name='phoneNo'
            className='input'
            placeholder='Phone No.'
            defaultValue={inputData.roomNo}
            onChange={(e) => {
              const num = e.target.value.replace(/^0/, '');
              setInputData({ ...inputData, phoneNo: num });
            }}
          />
        </div>
      )}
      {info && info.qrData && !info.qrData.roomNo && info.isRoomNoAble && (
        <div className='section' style={{ background: '#fff', fontSize: '12px' }}>
          <input
            type='text'
            name='roomNo'
            className='input'
            placeholder='Room No.'
            defaultValue={inputData.roomNo}
            onChange={onInputChange}
          />
        </div>
      )}

      {/* paypal 일 때 이메일 입력창 x */}
      {pay !== 1 && (
        <div
          className='section'
          style={{
            background: '#fff',
            flexDirection: 'column',
            fontSize: '12px',
          }}
        >
          <div style={{ marginBottom: '8px' }}>
            <Translator id='emailinput' type='local' />
          </div>
          <input
            type='email'
            name='email'
            placeholder='Email'
            className='input'
            defaultValue={inputData.email}
            style={{ boxShadow: errorMsg && '0 0 4px 0px #ff3250' }}
            onChange={(e) => {
              const emailRule = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
              if (e.target.value.length === 0 || emailRule.test(e.target.value)) {
                setErrorMsg(null);
              } else {
                setErrorMsg('Invalid email format');
              }
              onInputChange(e);
            }}
          />
          {errorMsg && (
            <div
              style={{
                color: '#ff3250',
                fontWeight: 400,
                marginTop: '0.25rem',
              }}
            >
              {errorMsg}
            </div>
          )}
        </div>
      )}

      <div
        className='section'
        style={{
          background: 'white',
          flexDirection: 'column',
          fontSize: '12px',
        }}
      >
        <div style={{ marginBottom: '8px' }}>
          Apply discount <b>Coupon</b> here
        </div>
        <input name='couponId' className='input' onChange={onInputChange} placeholder='Coupon Code' />
        <button
          onClick={checkCoupon}
          style={{
            borderRadius: '4px',
            marginTop: '12px',
            width: '90%',
            background: '#ff3250',
            color: 'white',
            border: 'none',
            outline: 'none',
            padding: '0.8rem 1rem',
            fontSize: '16px',
            cursor: 'pointer',
          }}
        >
          APPLY
        </button>
        {coupon && (
          <div style={{ marginTop: '12px', color: '#ff3250' }}>Success! Final Price: ₩{formatPrice(total)}</div>
        )}
      </div>

      {pay !== 1 && (
        <div
          className='section'
          style={{
            background: 'white',
          }}
          onClick={() => {
            setMarketingCheck(!marketingCheck);
            setInputData({ ...inputData, agree: !marketingCheck });
          }}
        >
          <div
            style={{
              display: 'flex',
              fontSize: '13px',
              color: '#55607d',
              lineHeight: 1.38,
              fontWeight: 'normal',
              justifyContent: 'flex-start',
            }}
          >
            <div
              style={{
                width: 'fit-content',
                marginRight: '8px',
              }}
            >
              <Checkbox selected={marketingCheck} />
            </div>
            <div style={{ marginTop: '4px' }}>
              <CustomTranslator2 lang={lang} />
            </div>
          </div>
        </div>
      )}
      <div
        className='section'
        style={{
          background: 'white',
          borderBottom: 'solid 1px #e6e6e6',
        }}
        onClick={() => {
          setCheck(!check);
        }}
      >
        <div
          style={{
            display: 'flex',
            fontSize: '13px',
            color: '#55607d',
            lineHeight: 1.38,
            fontWeight: 'normal',
          }}
        >
          <div
            style={{
              marginRight: '8px',
            }}
          >
            <Checkbox selected={check} />
          </div>
          <div style={{ marginTop: '4px' }}>
            <Translator id='pay1' type='local' />
          </div>
        </div>
      </div>
      <div
        className='section'
        style={{
          fontSize: '12px',
          fontWeight: 'normal',
          lineHeight: 1.64,
          borderTop: 'none',
          borderBottom: '1px solid #e6e6e6',
          color: '#ff3250',
        }}
      >
        * <Translator id='popup' type='local' />
        <br />* <Translator id='browser' type='local' />
      </div>

      <div
        className='section'
        style={{
          fontSize: '12px',
          fontWeight: 'normal',
          lineHeight: 1.64,
          marginBottom: '2rem',
          borderTop: 'none',
          borderBottom: '1px solid #e6e6e6',
        }}
      >
        <Translator id='exim' type='local' />
        <br />
        <Translator id='exim2' type='local' />
      </div>

      <div style={{ width: '100%', height: '2.5em' }}></div>

      <ButtonBackground>
        <OrderButton onClick={makeOrder} disabled={!check}>
          <div className='name'>
            <Translator id='makepayment' type='local' />
          </div>
        </OrderButton>
      </ButtonBackground>
    </Container>
  );
};

export default withRouter(Order);

const CartItem = ({ item }) => {
  const options = [...item.options];

  return (
    <ItemContainer>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div
          style={{
            color: 'black',
            fontSize: '16px',
            display: 'flex',
          }}
        >
          <div>
            <span style={{ marginRight: '4px' }}>
              <Translator id={item.name_id} collection='words' />
            </span>
            <span
              style={{
                fontSize: '14px',
                color: 'black',
                marginLeft: '8px',
                marginRight: '16px',
                whiteSpace: 'nowrap',
                marginTop: '1px',
              }}
            >
              {`X${item.counter}`}
            </span>
          </div>
        </div>
        <div style={{ color: 'black' }}>₩{formatPrice(item.total)}</div>
      </div>
      <div
        style={{
          fontSize: '12px',
          fontWeight: 'norwap',
          color: '#aaa',
          marginTop: '3px',
        }}
      >
        {item.realName}
      </div>
      <div style={{ width: '75%' }}>
        {options.length > 0 &&
          options.map((option, index) => (
            <div
              style={{
                lineHeight: 1.8,
                display: 'flex',
                alignItems: 'center',
                whiteSpace: 'normal',
              }}
              key={index}
            >
              <span>
                <Translator id={option.name_id} collection='words' name={option.realName} />
              </span>
            </div>
          ))}
      </div>
    </ItemContainer>
  );
};

const CustomTranslator = ({ lang }) => {
  const style = { color: '#ff3250' };
  switch (lang) {
    default:
      return (
        <>
          Please leave your <span style={style}>phone number</span> for food arrival notification.
        </>
      );
    case 'jp':
      return (
        <>
          配信食べ物到着通知のために<span style={style}>電話番号</span>
          を残してください。
        </>
      );
    case 'cn':
      return (
        <>
          请留下你的<span style={style}>电话号码</span>,以便通知食物到达。
        </>
      );
    case 'th':
      return (
        <>
          โปรดฝาก<span style={style}>หมายเลขโทรศัพท์</span>
          ของคุณเพื่อแจ้งเตือนการมาถึงของอาหาร
        </>
      );
  }
};

const CustomTranslator2 = ({ lang }) => {
  const style = { textDecoration: 'underline' };
  switch (lang) {
    default:
      return (
        <>
          By collecting personal information, you agree to the{' '}
          <Link style={style} to='/contract/use'>
            Terms of Use
          </Link>
          ,{' '}
          <Link style={style} to='/contract/privacy'>
            Privacy Policy and Cookie Policy.
          </Link>{' '}
          (optional)
        </>
      );
    case 'jp':
      return (
        <>
          個人情報を収集することで、{' '}
          <Link style={style} to='/contract/use'>
            利用規約
          </Link>
          および
          <Link style={style} to='/contract/privacy'>
            プライバシーポリシーおよびクッキーポリシー
          </Link>
          に同意したことになります。 (選択)
        </>
      );
    case 'cn':
      return (
        <>
          透过收集个人资料, 代表已同意本司的
          <Link style={style} to='/contract/use'>
            《使用条款》
          </Link>
          与{' '}
          <Link style={style} to='/contract/privacy'>
            《隐私权政策》
          </Link>
          并同意授权
          <Link style={style} to='/contract/privacy'>
            《个人资料》
          </Link>
          (选择)
        </>
      );
    case 'th':
      return (
        <>
          ตกลงที่จะมีการเก็บรวบรวมข้อมูลส่วนบุคคล
          นโยบายเกี่ยวกับข้อกําหนดและเงื่อนไขการคุ้มครองข้อมูลส่วนบุคคลและนโยบายคุกกี้ (ตัวเลือก)
        </>
      );
  }
};

const Container = styled.div`
  color: #aaaaaa;
  font-size: 14px;
  width: 100%;
  min-height: 100vh;
  .header {
    width: 100%;
    max-width: 580px;
    display: flex;
    padding: 12px 0;
    border-bottom: solid 1px #e6e6e6;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    position: fixed;
    top: 0;
  }
  .price {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 2px;
  }
  .section {
    padding: 12px 5%;
    display: flex;
    background: #f9f9f9;
    border-top: 1px solid #e6e6e6;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    align-items: center;
  }
  .input {
    font-size: 16px;
    font-weight: bold;
    background: #f8f8f8;
    color: #55607d;
    width: 80%;
    margin: 0 5%;
    border-radius: 24px;
    padding: 15px 5%;
    box-shadow: none;
    outline: none;
    border: none;
    &::placeholder {
      color: #ccc;
    }
  }
`;

const ItemContainer = styled.div`
  width: 90%;
  margin: 0 5%;
  border-bottom: solid 1px #e6e6e6;
  padding: 12px 0;
`;

const OrderButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  background-color: ${(props) => (props.disabled ? 'gray' : '#ff3250')};
  color: white;
  cursor: pointer;
  padding: 12px 0;
  font-size: 16px;
  line-height: 1;
  transition: 0.2s all ease-out;
  white-space: nowrap;
  &:hover {
    background-color: ${(props) => (props.disabled ? 'gray' : '#c3223a')};
  }
`;
const ButtonBackground = styled.div`
  z-index: 100;
  position: fixed;
  bottom: 0;
  width: 90%;
  max-width: 522px;
  margin: 0 5%;
  padding: 0.5rem 0 1rem 0;
  @media screen and (min-width: 580px) {
    margin: 0 29px;
  }
`;
