import React from 'react';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import styled from 'styled-components';
import Translator from '../../common/Translator/Translator';
import Tag from '../Tag/Tag';
import { Link } from 'react-router-dom';
import notOpenImg from '../../images/guide_thumb.svg';

const MenuContainer = styled.div`
  background: white;
  margin-top: 16px;
  min-height: 264px;
  max-width: 400px;
  max-height: 420px;
  border-radius: 8px;
  text-align: left;
  margin-bottom: 1rem;
  box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.1);
  margin-left: 8px;
  .img {
    background-color: #ccc;
    background-image: ${(props) => `url(${props.active ? props.img : notOpenImg})`};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    max-width: 400px;
    max-height: 300px;
    height: 156px;
    border-radius: 8px;
  }
  .subtitle {
    font-size: 13px;
    font-weight: bold;
    line-height: 1.23;
    color: #aaaaaa;
    margin-left: 7.2%;
    margin-right: 7.2%;
    margin-top: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .title {
    color: ${(props) => `url(${props.active ? '#000' : '#aaa'})`};
    margin-left: 7.2%;
    margin-right: 7.2%;
    font-family: Arial;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.09;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .content {
    font-size: 13px;
    width: 86%;
    margin-left: 7.2%;
    line-height: 1.23;
    color: #aaaaaa;
    overflow: hidden;
    padding: 8px 0;
    white-space: nowrap;
  }
`;

function NextSlider({ contents }) {
  const params = {
    slidesPerView: 'auto',
    spaceBetween: 16,
  };

  return (
    <Swiper {...params}>
      {contents &&
        contents.map((item, key) => (
          <Link to={`/store/${item.id}`} key={key} style={{ width: '62.5%' }}>
            <MenuContainer img={item.photo} active={item.active}>
              <div className="img" />
              <div className="subtitle">{item.realName}</div>
              <div className="title">
                <Translator id={item.name_id} collection="words" />
              </div>
              <div className="content">
                {item.tags.map((id, index) => (
                  <Tag name={<Translator id={id} collection="tags" />} key={index} />
                ))}
              </div>
            </MenuContainer>
          </Link>
        ))}
    </Swiper>
  );
}

export default NextSlider;
