// english
export const en = {
  total: 'Total',
  order: 'Order',
  nomenu: 'No Menu Selected',
  deliveryfee: 'Delivery charges',
  chicken: 'Chicken',
  korean: 'Korean',
  japanese: 'Japanese',
  dessert: 'Dessert',
  pizza: 'Pizza/Burger',
  burger: 'Burger',
  chinese: 'Chinese',
  street: 'Street',
  salads: 'Salads',
  asian: 'Asian',
  midnight: 'Midnight',
  deliverytime: 'Estimated time',
  minute: 'minutes',
  address: 'Address',
  businesshour: 'Business Hour',
  telephone: 'Tel',
  main: 'Main',
  side: 'Side',
  info: 'Info',
  payment: 'Payment',
  quantity: 'Qauntity',
  addtocart: 'Add to cart',
  unavailable: 'Now closed',
  alipay: 'Alipay',
  paypal: 'PayPal',
  unionpay: 'UnionPay',
  molpay: 'MOLPay',
  paysbuy: 'PAYSBUY',
  tenpay: 'TenPay',
  yandex: 'Yandex',
  makepayment: 'CHECKOUT',
  size: 'Size',
  option: 'Options',
  credit: 'Credit Card',
  myorder: 'My Order',
  mylocation: 'My Location',
  tracking: 'Tracking',
  orderplaced: 'Order received',
  preparing: 'Preparing',
  delivery: 'Order delivered',
  paymentsuccess: 'Payment successful',
  cancel: 'Cancel payment',
  cancel2: 'Payment cancelled',
  cart1: `Already added same item in your cart! Continue to order?`,
  cart2: 'To order food, same restaurant menu only! Do you want to replace it?',
  method: 'Select payment method',
  invalid: 'Invalid access',
  room1: 'Enter your "Room" number in this field',
  room2: 'You must type a "correct room" number',
  nearby: 'Nearby places',
  home: 'Home',
  pay1:
    'You cannot cancel an order after ordering completed Check this box if you have confirmed and agree for your cart and payment information. (required)',
  minprice: 'Minimum Order Amount',
  price: 'Price',
  exim: `1) This payment method is provided by Eximbay and is billed as www.eximbay.com.`,
  exim2: `2) Note: Please note that the billing descriptor will be listed as EXIMBAY.COM.`,
  original: 'Original',
  subtotal: 'Subtotal',
  empty: 'Your cart is currently empty',
  undermin: `Your cart is less than required
  minimum order amount`,
  countlimit: `Items in your cart has exceeded the maximum
  allowed quantity of 50`,
  nohistory: 'No order history',
  status1: 'Awaiting for placing your order',
  status2: 'Preparing',
  status3: 'On the way',
  status4: 'Delivered',
  deleteinfo: `Your order history will be deleted automatically within 72 hours after payment. Please check your email for more information.`,
  emailagreement: `I agree to use this email for marketing purpose. (optional)`,
  emailinput: `We will send the receipt if you tell us your email address.`,
  popup: `If you have in trouble for the payment, please disable the pop-up blocker in your browser.`,
  browser: `After payment, you must return to this page to complete your payment successfully.`,
  hoteltime: `We can't deliver at this time.`,
  openingsoon: `Opening Soon…`,
  nowclosed: `Sorry, Now Closed`,
  tourguide: `Tour Guide`,
  faq: `FAQ`,
  aboutus: `ABOUT US`,
  inicis: 'Korean Credit Card Only',
  african: 'Africa',
  mexican: 'Mexican',
  america: 'American',
  arabic: 'Arabic/Turkish',
  indian: 'Indian',
  european: 'European',
  meal_box: 'Meal Box',
  grocery: 'Grocery',
  all: 'All Cuisines',
  in_room_dining: 'In Room Dining',
};
