import React, { useState, useEffect, useContext, useMemo } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { FirebaseContext } from '../../firebase';
import { formatPrice } from '../../common/format';
import { convertProcess } from './SelectOrder';
import Translator from '../../common/Translator/Translator';
import location from '../../images/location.svg';
import payment from '../../images/payment_won.svg';
import preImg from '../../images/preparing.svg';
import deliverImg from '../../images/delivering.svg';
import compleImg from '../../images/completed.svg';
import cancelImg from '../../images/cancel.svg';
import Icon from '../../components/Icons';

const Container = styled.div`
  color: #aaaaaa;
  font-size: 14px;
  background: #f9f9f9;
  min-height: 100vh;
  .header {
    width: 100%;
    max-width: 580px;
    display: flex;
    padding: 12px 0;
    border-bottom: solid 1px #e6e6e6;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    position: fixed;
    top: 0;
  }
  .price {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 2px;
  }
  .section {
    padding: 12px 4%;
    display: flex;
    background: #f9f9f9;
    border-top: 1px solid #e6e6e6;
    font-size: 16px;
    line-height: 1.5;
    color: #000;
  }
`;

const ItemContainer = styled.div`
  margin: 0 5%;
  width: 90%;
  border-bottom: solid 1px #e6e6e6;
  padding: 12px 0;
`;

const MyOrder = (props) => {
  const { id } = props.match.params;
  const { db } = useContext(FirebaseContext);
  const [data, setData] = useState(null);

  const DELIVERY_FEE = useMemo(() => {
    return data ? (data.serviceFee || 0) + (data.deliveryFee || 0) : 0;
  }, [data]);

  useEffect(() => {
    window.scrollTo(0, 0);
    db.collection('orders')
      .doc(id)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          // 임시 데이터만 저장되어있는 경우 실제 결제 성공여부를 확인하여 데이터에 저장 혹은 삭제
          return props.history.push(`/order/complete/${id}`);
        }
        const storeId = doc.data().store_id;
        db.collection('stores')
          .doc(storeId)
          .get()
          .then((storeDoc) => {
            setData({ ...doc.data(), storeNameId: storeDoc.data().name_id });
          });
      });
  }, [db]); // eslint-disable-line

  const total = useMemo(() => {
    let totalPrice = 0;
    if (data) {
      data && data.cart.forEach((item) => (totalPrice += item.total));
      totalPrice = totalPrice + DELIVERY_FEE;
    }
    return totalPrice;
  }, [data, DELIVERY_FEE]);

  // 접수대기 => 접수완료 => 배달중 => 배달완료
  const changeImg = (status) => {
    if (status === 1 || status === 2) {
      return preImg;
    } else if (status === 3) {
      return deliverImg;
    } else if (status === -1) {
      return cancelImg;
    } else {
      return compleImg;
    }
  };

  return (
    <Container>
      <div className="header">
        <Icon
          type="exit"
          style={{ marginLeft: '5%' }}
          onClick={() => {
            props.history.push('/');
          }}
        />

        <div style={{ fontSize: '16px', lineHeight: 1.5, color: 'black' }}>My Order</div>
        <div style={{ width: '3%', height: '10px', marginRight: '5%' }} />
      </div>
      {data && (
        <div style={{ background: '#fff', marginTop: '48px' }}>
          <div
            style={{
              borderBottom: 'solid 1px #e6e6e6',
              textAlign: 'center',
              fontSize: '18px',
              lineHeight: 1.33,
              color: '#373751',
              padding: '24px 0',
            }}
          >
            <img src={changeImg(data.status)} alt="" style={{ width: '120px' }} />
            <div>{convertProcess(data.status)}</div>
          </div>
          <div
            style={{
              fontSize: '12px',
              color: '#aaa',
              lineHeight: 1.33,
              borderBottom: 'solid 1px #e6e6e6',
              margin: '0 5%',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '8px 0',
            }}
          >
            <div>#{data.orderNumber}</div> <div>{data.date.toDate().toLocaleString()}</div>
          </div>
          <div
            style={{
              borderBottom: 'solid 1px #e6e6e6',
              padding: '12px 0',
              margin: '0 5%',
              fontSize: '16px',
              color: 'black',
              fontWeight: 'bold',
            }}
          >
            {<Translator id={data.storeNameId} collection="words" />}
          </div>
          <div>
            {data.cart &&
              data.cart.map((item, index) => (
                <CartItem item={item} cartIndex={index} key={index} />
              ))}
          </div>
          <div
            style={{
              padding: '12px 5%',
              display: 'flex',
              flexDirection: 'column',
              lineHeight: 1.71,
            }}
          >
            <div className="price">
              <span>
                <Translator id="subtotal" type="local" />
              </span>
              <span>₩{formatPrice(total - DELIVERY_FEE)}</span>
            </div>
            <div className="price">
              <span>
                <Translator id="deliveryfee" type="local" />
              </span>
              <span>₩{formatPrice(DELIVERY_FEE)}</span>
            </div>

            <div
              className="price"
              style={{
                color: 'black',
                lineHeight: 1.5,
                fontSize: '16px',
                fontWeight: 'bold',
              }}
            >
              <span>
                <Translator id="total" type="local" />
              </span>
              <span>₩{formatPrice(total)}</span>
            </div>
          </div>
          <div className="section">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                color: '#373751',
              }}
            >
              <img src={payment} alt="" style={{ marginRight: '8px' }} />
              {data.pay &&
                data.pay.substr(0, 1).toUpperCase() + data.pay.substr(1, data.pay.length)}
            </div>
          </div>

          <div className="section">
            <div>
              <img src={location} alt="" style={{ marginRight: '8px' }} />
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                color: '#373751',
              }}
            >
              <div>
                <div>{data.address_en}</div>
                <div>{data.hotel}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};

export default withRouter(MyOrder);

const CartItem = ({ item }) => {
  const options = [...item.options];

  return (
    <ItemContainer>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            color: 'black',
            fontSize: '16px',
            display: 'flex',
          }}
        >
          <span style={{ marginRight: '4px' }}>
            <Translator id={item.name_id} collection="words" />
          </span>

          <span
            style={{
              fontSize: '14px',
              color: 'black',
              marginLeft: '8px',
              marginRight: '16px',
              marginTop: '1px',
              whiteSpace: 'nowrap',
            }}
          >
            {`X ${item.counter}`}
          </span>
        </div>
        <div style={{ color: 'black' }}>₩{formatPrice(item.total)}</div>
      </div>
      <div
        style={{
          fontSize: '12px',
          color: '#aaa',
          whiteSpace: 'nowrap',
        }}
      >
        {item.realName}
      </div>
      <div style={{ width: '75%' }}>
        {options.length > 0 &&
          options.map((option, index) => (
            <div
              style={{
                lineHeight: 1.8,
                display: 'flex',
                alignItems: 'center',
                whiteSpace: 'normal',
              }}
              key={index}
            >
              <span>{option.name}</span>
            </div>
          ))}
      </div>
    </ItemContainer>
  );
};
