import { differenceInMinutes, differenceInHours } from 'date-fns';
import { takeEvery, put, call, all } from 'redux-saga/effects';
import firebase from '../firebase';
import Payment from '../common/Classes/Payment';
import { logger } from '../common/utils';
import { clearCart } from './cart';

const { db } = firebase;

/* types */
const DELETE_EXPIRE_DATA = 'order/DELETE_EXPIRE_DATA';
// temp
const ADD_TEMP = 'order/ADD_TEMP';
const FETCH_TEMP_DATA = 'order/FETCH_TEMP_DATA';
const FETCH_DELETE_TEMP_DATA = 'order/FETCH_DELETE_TEMP_DATA';
const DELETE_TEMP = 'order/DELETE_TEMP';
// completed
const ADD_COMPLETED = 'order/ADD_COMPLETED';
const FETCH_COMPLETED_DATA = 'order/FETCH_COMPLETED_DATA';

const REQUEST_PAYMENT = 'order/REQUEST_PAYMENT';

/* actions */
export const deleteExpireTemp = () => ({ type: DELETE_EXPIRE_DATA });
// temp
export const fetchTempData = (data) => ({ type: FETCH_TEMP_DATA, data });

export const fetchDeleteTempData = (orderNumber) => ({ type: FETCH_DELETE_TEMP_DATA, orderNumber });
// completed
export const fetchCompletedData = (data) => ({ type: FETCH_COMPLETED_DATA, data });

export const requestPayment = (orderData) => ({ type: REQUEST_PAYMENT, orderData });

/* sagas */
// temp
const fetchTempDataSaga = function* ({ data }) {
  const doc = db.collection('orders-temp').doc(data.orderNumber);
  yield call([doc, doc.set], data);
  yield put({ type: ADD_TEMP, data });
};

const fetchDeleteTempDataSaga = function* ({ orderNumber }) {
  const doc = db.collection('orders-temp').doc(orderNumber);
  yield call([doc, doc.delete]);
  yield put({ type: DELETE_TEMP, orderNumber });
};

// completed
const fetchCompletedDataSaga = function* ({ data }) {
  const doc = db.collection('orders').doc(data.orderNumber);
  yield call([doc, doc.set], data);
  yield put({ type: ADD_COMPLETED, data });
};

const getExchangeRatio = function* () {
  const doc = db.collection('settings').doc('exchange');
  const data = yield call([doc, doc.get]);
  const transform = (doc) => doc.data().rounded / 1000;
  return transform(data);
};

const changeCouponStatus = function* (data) {
  const doc = db.collection('coupons').doc(data.id);
  yield call([doc, doc.update], { isUsed: true });
  return true;
};

const requestPaymentSaga = function* ({ orderData }) {
  yield call(fetchTempDataSaga, { data: orderData });

  const exchangeRatio = yield call(getExchangeRatio);
  const pgResult = yield call(Payment.doPayment, orderData, exchangeRatio);
  logger('pg response:', pgResult);
  if (pgResult.success) {
    yield all([
      call(fetchDeleteTempDataSaga, { orderNumber: orderData.orderNumber }),
      call(fetchCompletedDataSaga, { data: orderData }),
      call(changeCouponStatus, orderData.coupon),
    ]);
    yield put(clearCart());
    yield call(Payment.sendPushMsg, orderData);
    window.location.replace('/order/my');
  } else {
    alert('Error, User canceled');
    window.location.replace('/order/cart');
  }
};

export const orderSaga = function* () {
  yield all([
    takeEvery(FETCH_TEMP_DATA, fetchTempDataSaga),
    takeEvery(FETCH_DELETE_TEMP_DATA, fetchDeleteTempDataSaga),
    takeEvery(FETCH_COMPLETED_DATA, fetchCompletedDataSaga),
    takeEvery(REQUEST_PAYMENT, requestPaymentSaga),
  ]);
};

/* reducers */
const initialState = {
  temp: [],
  completed: [],
};

const order = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TEMP:
      return {
        ...state,
        temp: [...state.temp, action.data],
      };
    // todo: persist가 발동전에 지난 시간 체크 하도록 or 페이지 첫 로딩시 action실행
    case DELETE_EXPIRE_DATA:
      const temMinuteFilter = (data) =>
        data.filter((item) => differenceInMinutes(new Date(), item.date) < 10);
      const hourFilter = (data) =>
        data.filter((item) => differenceInHours(new Date(), item.date) < 72);
      return {
        ...state,
        completed: hourFilter(state.completed),
        temp: temMinuteFilter(state.temp),
      };
    case DELETE_TEMP:
      return {
        ...state,
        temp: state.temp.filter(
          (item) => item && item.orderNumber && item.orderNumber !== action.orderNumber
        ),
      };
    case ADD_COMPLETED:
      return {
        ...state,
        completed: [...(state.completed || []), action.data],
      };
    default:
      return state;
  }
};

export default order;
