import React, { useEffect, useContext, useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { FirebaseContext } from '../../firebase';
import Loader from '../../components/Icons/Loader';
import { useSelector, useDispatch } from 'react-redux';
import logo from '../../images/deliquick.svg';
import { loadStore, clearStore } from '../../redux/store';
import NextSlider from '../../components/SlideBanner/NextSlider';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;

const TopItem = styled.div`
  width: 100%;
  max-width: 580px;
  max-height: 590px;
  height: ${window.innerWidth * 1.25 + 'px'};
  background-image: ${(props) => `url(${props.img})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  /* -webkit-filter: blur(3px);
  filter: blur(3px); */
`;

const FloatDiv = styled.div`
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 580px;
  max-height: 590px;
  height: ${window.innerWidth * 1.25 + 'px'};
  position: absolute;
  top: 49px;
  z-index: 12;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: white;
  .title {
    text-shadow: 0 0 16px rgba(0, 0, 0, 0.5);
    font-size: 24px;
    font-weight: bold;
    line-height: 1.17;
    padding: 0 2rem;
  }
  .subtitle {
    color: rgba(255, 255, 255, 0.7);
    font-size: 16px;
    font-weight: bold;
    line-height: 1.2;
  }
  .comment {
    font-size: 14px;
    line-height: 1.23;
    color: rgba(255, 255, 255, 0.9);
    text-align: left;
    margin-bottom: 1.5rem;
    padding: 0 5%;
  }
`;

const Content = (props) => {
  const { id } = props.match.params;
  const { db } = useContext(FirebaseContext);
  const [data, setData] = useState(null);
  const { lang } = useSelector((state) => state.lang, []);
  const { stores } = useSelector((state) => state.stores, []);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearStore());
    dispatch(loadStore());
  }, [dispatch]);

  useEffect(() => {
    db.collection('contents')
      .doc(id)
      .get()
      .then((doc) => {
        const menuArr = doc.data().menus;
        setData({
          ...doc.data(),
          menus:
            menuArr && [menuArr[menuArr.length - 1]].concat(menuArr.slice(0, menuArr.length - 1)),
        });
      });
  }, [db, id]);

  const switchLanguage = useCallback(
    (target) => {
      return target[lang] ? target[lang] : target.en;
    },
    [lang]
  );

  const filteredStores = useMemo(() => {
    if (!stores) return;
    const ids = (data && data.tags) || [];

    return stores.filter((item) => {
      let includeResult = [];
      for (const tag of item.tags) {
        ids.includes(tag) && includeResult.push(tag);
      }
      return includeResult.length === ids.length;
    });
  }, [stores, data]);

  return (
    <Container>
      {data ? (
        <>
          <TopItem img={data.bg_img_blur || data.bg_img} />
          <FloatDiv>
            <div style={{ width: '10px', height: '10px' }} />
            <div style={{ textAlign: 'center' }}>
              <div className="subtitle">{switchLanguage(data.subtitle)}</div>
              <div className="title">{switchLanguage(data.title)}</div>
            </div>
            <div className="comment">{switchLanguage(data.comment)}</div>
          </FloatDiv>
          {data.contents.map((item, index) => (
            <ContentItem data={item} lang={lang} key={index} />
          ))}
          <div className="center">
            <div
              style={{
                width: '12.5%',
                height: '2px',
                borderTop: '1px solid #e6e6e6',
                margin: '40px 0',
              }}
            />

            {data && filteredStores && filteredStores.length > 0 && (
              <>
                <img src={logo} alt="" style={{ width: '10%' }} />
                <div
                  style={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                    lineHeight: 1.25,
                    color: '#55607d',
                  }}
                >
                  DELIQUICK PICKED
                </div>
              </>
            )}
          </div>
          <div style={{ width: '90%', padding: '2rem 5%' }}>
            {filteredStores && <NextSlider contents={filteredStores}></NextSlider>}
          </div>
        </>
      ) : (
        <Loader />
      )}
    </Container>
  );
};

export default withRouter(Content);

const Image = styled.div`
  width: 100%;
  height: ${({ height }) => height};
  max-height: 340px;
  background-image: ${(props) => `url(${props.img})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  margin-bottom: 1rem;
  border-radius: 4px;
`;

const ContentItem = ({ data, lang }) => {
  const [height, setHeight] = useState(window.innerWidth * 0.9 * 0.625 + 'px');

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerWidth * 0.9 * 0.625 + 'px');
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const switchLanguage = useCallback(
    (target) => {
      return target[lang] ? target[lang] : target.en;
    },
    [lang]
  );

  return (
    <>
      <div style={{ width: '90%', margin: '32px 5% 0 5%' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {data.smallbar && (
            <div
              style={{
                width: '12.5%',
                height: '2px',
                borderTop: '1px solid #e6e6e6',
                marginBottom: '40px',
                ...data.style,
              }}
            />
          )}
          {data.sectionTitle && (
            <div
              style={{
                width: '100%',
                textAlign: 'center',
                color: '#55607d',
                fontSize: '16px',
                lineHeight: 1.25,
                fontWeight: 'bold',
                ...data.style,
              }}
            >
              {switchLanguage(data.sectionTitle)}
            </div>
          )}
        </div>

        {data.photo && <Image img={data.photo} height={height} />}
        {data.title && (
          <div
            style={{
              marginBottom: '0.5rem',
              fontSize: '18px',
              fontWeight: 'bold',
              ...data.titleStyle,
            }}
          >
            {data.title && switchLanguage(data.title)}
          </div>
        )}

        <div style={data.style}>
          {data.text &&
            switchLanguage(data.text)
              .split('<br/>')
              .map((line, index) => (
                <span key={`line-${index}`}>
                  {line}
                  <br />
                </span>
              ))}
        </div>
      </div>
    </>
  );
};
