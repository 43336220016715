import { format } from 'date-fns';

const possibilities = {
  lowerCased: 'abcdefghijklmnopqrstuvwxyz',
  capitals: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
  numbers: '0123456789',
  special: "~!@#$%^&()_+-={}[];',"
};

export const randomId = (len, pattern) => {
  if (!len) len = 30;
  if (!pattern) pattern = 'aA0';

  let chars = '';

  pattern.split('').forEach(a => {
    if (!isNaN(parseInt(a))) {
      chars += possibilities.numbers;
    } else if (/[a-z]/.test(a)) {
      chars += possibilities.lowerCased;
    } else if (/[A-Z]/.test(a)) {
      chars += possibilities.capitals;
    } else {
      chars += possibilities.special;
    }
  });

  let result = '';

  for (let i = 0; i < len; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  return result;
};

export const formatPrice = number => {
  return Number(number).toLocaleString('en');
};

export const formatTenPercentCommission = number => {
  return Math.round((number * 1.1) / 100) * 100;
};

// 전화번호 - 삭제
export const formatDeleteDash = number => {
  return number.replace(/-/gi, '');
};

export const _capitalizeFirstLetter = str => {
  if (str) return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getUniqueOrderNo = code => {
  const now = format(new Date(), 'YYMMDD');
  const orderNo = `D${code.substr(0, 1)}${now}_${randomId(5, 'aA0')}`;
  return orderNo;
};
