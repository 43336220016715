import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import logo from '../../images/logo.svg';
import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from '../../redux/lang';
import PreviewSidebar from './PreviewSidebar';
import sidemenu from '../../images/sidemenu.svg';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: solid 1px #e6e6e6;
  height: 47px;
  position: sticky;
  top: 0;
  background: white;
  z-index: 300;
  .logo {
    width: 96px;
    height: 24px;
    transition: opacity 0.8s ease-out;
    -webkit-transition: opacity 0.8s ease-out;
  }
  .store {
    margin-left: 5%;
    font-size: 16px;
    line-height: 1.5;
    color: #000000;
    transition: opacity 0.8s ease-out;
    -webkit-transition: opacity 0.8s ease-out;
  }
`;

const Black = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 150;
  background: rgba(0, 0, 0, 0.5);
`;

const PreviewHeader = props => {
  const dispatch = useDispatch();
  const { lang } = useSelector(state => state.lang, []);
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  useEffect(() => {
    if (isFirstRender) {
      const systemLanguage = navigator.language.split('-')[1].toLowerCase();
      const availableLanguage = ['cn', 'jp', 'th'];
      if (availableLanguage.includes(systemLanguage)) {
        dispatch(setLanguage(systemLanguage));
      } else {
        dispatch(setLanguage('en'));
      }
    }
  }, [dispatch, lang, isFirstRender]);

  const [openSide, setOpenSide] = useState(false);
  const onChangeLang = e => {
    dispatch(setLanguage(e.target.value));
    setOpenSide(false);
  };

  return (
    <>
      <Container>
        {props.store ? (
          <div
            style={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis'
            }}
            className="store"
            id="header-store"
          >
            {props.store}
          </div>
        ) : (
          <img
            src={logo}
            className="logo"
            alt=""
            id="header-logo"
            style={{ marginLeft: '5%', marginTop: '4px' }}
          />
        )}

        <img
          src={sidemenu}
          style={{ marginLeft: 'auto', marginRight: '5%' }}
          alt=""
          onClick={() => {
            setOpenSide(!openSide);
          }}
        />
      </Container>
      <PreviewSidebar open={openSide} onChangeLang={onChangeLang} storeName={props.storeName} />
      {openSide && (
        <Black
          onClick={() => {
            setOpenSide(false);
          }}
        />
      )}
    </>
  );
};

export default withRouter(PreviewHeader);
