import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { withRouter, Link } from 'react-router-dom';
import { FirebaseContext } from '../../firebase';
import Translator from '../../common/Translator/Translator';
import Icon, { changeImg } from '../../components/Icons';
import Loader from '../../components/Icons/Loader';
import { useSelector, useDispatch } from 'react-redux';
import { deleteExpireTemp } from '../../redux/order';
import { sortBy } from 'lodash';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  min-height: 100vh;
  background: #f9f9f9;
  .header {
    width: 90%;
    display: flex;
    padding: 12px 5%;
    position: sticky;
    top: 0;
    border-bottom: solid 1px #e6e6e6;
    justify-content: space-between;
    align-items: center;
    background: #fff;
  }
`;

export const convertProcess = status => {
  switch (status) {
    case 1:
      return <Translator type="local" id="status2" />;
    case 2:
      return <Translator type="local" id="status2" />;
    case 3:
      return <Translator type="local" id="status3" />;
    case 4:
      return <Translator type="local" id="status4" />;
    case -1:
      return 'Canceled';
    default:
      return <Translator type="local" id="status2" />;
  }
};

const SelectOrder = props => {
  const { db } = useContext(FirebaseContext);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { temp, completed } = useSelector(state => state.order);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(deleteExpireTemp());
    let concatedData = [];
    temp.forEach(item => concatedData.push({ ...item, temp: true }));
    concatedData.push(...completed);

    const orderNumbers = sortBy(concatedData, ['date'])
      .map(item => item.orderNumber)
      .reverse();

    if (orderNumbers.length === 0) {
      return setLoading(false);
    }
    const getOrderData = async orderNumbers => {
      const promises = orderNumbers.map(
        id =>
          new Promise(resolve =>
            db
              .collection('orders')
              .doc(id)
              .get()
              .then(orderDoc => {
                if (!orderDoc.exists) return resolve({ temp: true, orderNumber: id });

                const store_id = orderDoc.data().store_id;
                db.collection('stores')
                  .doc(store_id)
                  .get()
                  .then(storeDoc => {
                    resolve({ ...orderDoc.data(), storeNameId: storeDoc.data().name_id });
                  });
              })
          )
      );

      return await Promise.all(promises);
    };

    getOrderData(orderNumbers).then(result => {
      setData(result.filter(item => item !== null));
      setLoading(false);
    });
  }, [db]); // eslint-disable-line

  return (
    <Container>
      <div className="header">
        <Icon
          type="exit"
          onClick={() => {
            props.history.goBack();
          }}
        />
        <div style={{ fontSize: '16px', lineHeight: 1.5, color: 'black' }}>My Order</div>
        <div style={{ width: '10px', height: '10px', marginRight: '5%' }} />
      </div>
      <div style={{ marginTop: '8px' }}>
        {!loading && data && data.map((item, index) => <OrderItem data={item} key={index} />)}
        <div
          style={{
            lineHeight: 1.64,
            fontSize: '11px',
            color: '#aaa',
            width: '90%',
            margin: '12px 5%'
          }}
        >
          <Translator type="local" id="deleteinfo" />
        </div>
        {!loading && !data && (
          <div
            style={{
              textAlign: 'center',
              marginTop: '2rem',
              lineHeight: 1.64,
              fontSize: '14px',
              color: '#aaa'
            }}
          >
            <Translator type="local" id="nohistory" />
          </div>
        )}
      </div>
      {loading && <Loader>Please wait...</Loader>}
    </Container>
  );
};

export default withRouter(SelectOrder);

const ItemContainer = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 8px;
  flex-direction: column;
  border-bottom: solid 1px #e6e6e6;
  background: white;
  color: black;
  cursor: pointer;
`;

const OrderItem = ({ data }) => {
  return (
    <Link to={`/order/my/${data.orderNumber}`}>
      {data.temp ? (
        <ItemContainer>
          <div
            style={{
              margin: '12px 1rem 12px 1rem',
              display: 'flex',
              justifyContent: 'space-between',
              lineHeight: 1.5,
              alignItems: 'center'
            }}
          >
            <span style={{ color: '#ff3250' }}>Incomplete order</span>
            <span style={{ marginLeft: 'auto', color: '#aaa', fontSize: '14px' }}>
              Continue Order
            </span>
            <Icon type="next" />
          </div>
          <div
            style={{
              fontSize: '12px',
              color: '#aaa',
              lineHeight: 1.33,
              borderTop: 'solid 1px #e6e6e6',
              margin: '0 1rem',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '8px 0'
            }}
          >
            <div>#{data.orderNumber}</div>
          </div>
        </ItemContainer>
      ) : (
        <ItemContainer>
          <div
            style={{
              fontSize: '14px',
              margin: '12px 1rem',
              display: 'flex',
              alignItems: 'center',
              color: '#373751'
            }}
          >
            <img
              src={changeImg(data.status)}
              style={{ width: '24px', marginRight: '0.5rem' }}
              alt=""
            />
            {convertProcess(data.status)}
          </div>
          <div
            style={{
              margin: '0 1rem 12px 1rem',
              display: 'flex',
              justifyContent: 'space-between',
              fontWeight: 'bold',
              lineHeight: 1.5
            }}
          >
            <Translator id={data.storeNameId} collection="words" />
            <Icon type="next" />
          </div>
          <div
            style={{
              fontSize: '12px',
              color: '#aaa',
              lineHeight: 1.33,
              borderTop: 'solid 1px #e6e6e6',
              margin: '0 1rem',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '8px 0'
            }}
          >
            <div>#{data.orderNumber}</div>{' '}
            {data.date && (
              <div>
                {data.date.toDate().toLocaleDateString()} {data.date.toDate().toLocaleTimeString()}
              </div>
            )}
          </div>
        </ItemContainer>
      )}
    </Link>
  );
};
