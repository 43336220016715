import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import downIcon from "../../images/down.svg";
import exitIcon from "../../images/exit.svg";

const Container = styled.div`
  width: 100%;
  .header {
    width: 90%;
    display: flex;
    padding: 12px 5%;
    border-bottom: solid 1px #e6e6e6;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    position: fixed;
    top: 0;
  }
`;

const Faq = props => {
  return (
    <Container>
      <div className="header">
        <img
          src={exitIcon}
          alt=""
          onClick={() => {
            props.history.goBack();
          }}
        />

        <div style={{ fontSize: "16px", lineHeight: 1.5, color: "black" }}>
          FAQ
        </div>
        <div style={{ width: "10px", height: "10px", marginRight: "5%" }} />
      </div>
      <div
        style={{ display: "flex", flexDirection: "column", marginTop: "48px" }}
      >
        {data.map(item => (
          <Item data={item} />
        ))}
      </div>
    </Container>
  );
};

export default withRouter(Faq);

const Item = ({ data }) => {
  const { lang } = useSelector(state => state.lang, []);
  const [open, setOpen] = useState(false);
  const imgRef = useRef();
  const openInfo = () => {
    setOpen(!open);
    imgRef.current.style.marginTop = !open ? "1px" : "-5px";
    imgRef.current.style.transform = !open ? "rotate(180deg)" : "rotate(0deg)";
  };
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        padding: "14px 0",
        borderBottom: "solid 1px #e6e6e6",
        alignItems: "flex-start",
        fontSize: "14px",
        background: open ? "#f9f9f9" : "white"
      }}
      onClick={() => {
        openInfo();
      }}
    >
      <div style={{ width: "80%", lineHeight: 1.43, marginLeft: "5%" }}>
        <div>{lang ? data.question[lang] : data.question.en}</div>
        {open && (
          <div
            style={{ marginTop: "14px" }}
            dangerouslySetInnerHTML={{ __html: data.answer[lang] }}
          />
        )}
      </div>

      <div style={{ marginLeft: "5%", marginTop: "4px" }}>
        <img
          src={downIcon}
          alt=""
          style={{ marginTop: "-5px", transition: "transform 0.15s linear" }}
          ref={imgRef}
        />
      </div>
    </div>
  );
};

const data = [
  {
    question: {
      en: `I want to change the menu or quantity for an order I have already made`,
      cn: `订购后想更改菜单或数量。`,
      jp: `注文後、メニューまたは数量を変更したいです。`
    },
    answer: {
      en: `You can not change your order you have already orderd`,
      cn: `订单完成后无法变更数量。`,
      jp: `注文完了後、数量変更は不可能です。`
    }
  },
  {
    question: {
      en: `Where can I confirm the order details?`,
      cn: `订购的明细在哪里可以确认?`,
      jp: `注文した内訳はどこで確認できますか？`
    },
    answer: {
      en: `Go to the "My order" in the upper right corner tap`,
      cn: `可在手机画面右上角的我的主页确认`,
      jp: `モバイル画面右上段のマイページで確認可能`
    }
  },
  {
    question: {
      en: `How do I know if my food has arrived?`,
      cn: `如何知道配送食物到达了呢?`,
      jp: `デリバリー料理の到着はどうやって知ることができますか?`
    },
    answer: {
      en: `Hotel staff inform you`,
      cn: `酒店职员通知您的客房`,
      jp: `ホテルマンがルームでお知らせ`
    }
  },
  {
    question: {
      en: `Do I need to tip my delivery partner?`,
      cn: `需要给小费吗?`,
      jp: `チップをあげなくてはいけませんか？`
    },
    answer: {
      en: `Tips aren't included and All DeliQuick sesrvice never asked you about tips`,
      cn: `DeliQuick的所有服务均不要求客户提供小费。`,
      jp: `DeliQuickのすべてのサービスはお客様にチップを要求いたしません。`
    }
  },
  {
    question: {
      en: `What is the delivery time?`,
      cn: `配送时间是怎么样的?`,
      jp: `配達時間はどれ位かかりますか?`
    },
    answer: {
      en: `The time required for delivery shown in the order details as follows <p>1) Confirm your order and preparing</p> <p>2) Delivery time to your location</p> <p>*Actual delivery time may vary due to restaurant conditions, traffic conditions and etc.</p>`,
      cn: `在订单明细中显示的送货时间如下。 <p>1)餐厅订单受理及准备时间</p> <p>2)从餐厅移动到我位置的预期时间</p> <p>*实际送货时间可能因餐厅情况、交通状况等其他因素而有变动。</p>`,
      jp: `注文内訳に表示される配達必要時間は以下の通りです。 <p>1)レストラン注文受付および準備時間</p> <p>2)レストランから自分の位置まで移動する予想時間</p> <p>*実際の配達時間はレストランの状況、交通状況などその他の要因によって変動することがあります。</p>`
    }
  },
  {
    question: {
      en: `When is DeliQuick able to order a food?`,
      cn: `DeliQuick可以订餐的时间是什么时候?`,
      jp: `DeliQuick注文可能時間はいつですか？`
    },
    answer: {
      en: `Business hours of each restaurant are different`,
      cn: `入驻DeliQuick的餐厅营业时间各不相同。`,
      jp: `DeliQuickに入店しているレストランの営業時間はそれぞれ異なります。`
    }
  },
  {
    question: {
      en: `Can I order a variety of foods in multiple restaurants at once?`,
      cn: `一次可以点多种食物和多种餐厅吗?`,
      jp: `一度にいろんな食べ物やいろんなレストランで注文できますか？`
    },
    answer: {
      en: `You can't order multiple restaurant food at once`,
      cn: `在同一家餐厅可以点各种食物，但一次不能点多种餐厅的食物。完成一次订购后，请在其他餐厅用同样的方法订购。请参考另外收取配送费的情况。`,
      jp: `同一のレストランでいろいろな食べ物を注文することは出来ますが、一度に色々なレストランでの注文は出来ません。一度注文完了後、他のレストランで同じ方法で注文してください。配達費が別途かかる点は参考してください。`
    }
  },
  {
    question: {
      en: `Do I need to register to use the service?`,
      cn: `需要注册会员吗?`,
      jp: `会員登録が必要ですか？`
    },
    answer: {
      en: `DeliQuick is enough from only room information you are staying in.
      You never order delivery in same way again"`,
      cn: `DeliQuick可以以客房信息，为顾客送餐，无需另行注册会员。`,
      jp: `DeliQuickは客室情報だけでお客様に配達させていただきますので、別途、会員登録の必要はありません。`
    }
  },
  {
    question: {
      en: `What is the delivery fee?`,
      cn: `送货手续费是什么?`,
      jp: `配達手数料は何ですか?`
    },
    answer: {
      en: `It is the minimum service charge for delivering your food safely so that you can enjoy the delicious food you ordered.`,
      cn: `是在DeliQuick中，为方便顾客享用所订购的美味食品，安全送货上门的最低服务费用。`,
      jp: `デリクックから顧客が注文したおいしい食べ物を楽にお召し上がりできるように安全に配信していただく最小限のサービス費用です。`
    }
  },
  {
    question: {
      en: `What if I found foreign object in my food that shouldn't have been there or how to ensure food quality?`,
      cn: `如果我在食物中发现了不该出现的异物怎么办
      如何保证食品质量?`,
      jp: `食べ物に問題があるか、異物がある場合はどうなりますか?`
    },
    answer: {
      en: `We make sure that it's possible for a driver to be at the restaurant as soon as your food is ready. Your food won't have to sit under a heat lamp. In the rare cases that if you found foreign object, we ask the kitchen to make to the order again. Otherwise, we will give you full refund.`,
      cn: `我们确保你的菜一做好，司机就有可能赶到餐厅。
      你的食物不必放在加热灯下。
      在极少数情况下，如果您发现异物，我们会要求厨房重新订做。否则，我们将全额退款。`,
      jp: `配達員は、食べ物ができるだけ暖かくおいしく配達するためにレストランの前にいます。調理後のすぐそばでおいしくお召し上がりできるように最善を尽くします。
      まれな場合ですが、もし食べ物に異物があれば注文した食べ物を再作成いただいたり、全額返金します。`
    }
  }
];
