import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import nameIcon from '../../images/name.svg';
import timeIcon from '../../images/time.svg';
import closeIcon from '../../images/closedday.svg';
import phoneIcon from '../../images/call.svg';
import addressIcon from '../../images/address.svg';
import { FirebaseContext } from '../../firebase';
import { withRouter } from 'react-router-dom';
import Translator from '../../common/Translator/Translator';
import EmptyHeader from './PreviewHeader';

const Container = styled.div`
  color: black;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #f9f9f9;
  height: 100vh;
  .info-item {
    width: 100%;
    border-bottom: solid 1px #e6e6e6;
    padding: 1rem 5%;
    display: flex;
    align-items: center;
    line-height: 1.14;
    font-size: 14px;
  }
  .small {
    font-size: 12px;
  }
  .col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const PreviewStoreInfo = ({ match }) => {
  const { db } = useContext(FirebaseContext);
  const [data, setData] = useState(null);
  const { id } = match.params;

  useEffect(() => {
    db.collection('stores')
      .doc(id)
      .get()
      .then(doc => setData(doc.data()));
  }, [db, id]);

  const numberToDay = number => {
    switch (number) {
      case 0:
        return 'Sun';
      case 1:
        return 'Mon';
      case 2:
        return 'Tue';
      case 3:
        return 'Wed';
      case 4:
        return 'Thu';
      case 5:
        return 'Fri';
      case 6:
        return 'Sun';
      default:
        return '';
    }
  };

  return (
    <>
      <EmptyHeader store={data && <Translator id={data.name_id} collection="words" />} id={id} />
      <Container>
        {data && (
          <>
            <div className="info-item" style={{ alignItems: 'flex-start' }}>
              <img src={nameIcon} alt="" style={{ marginRight: '0.5rem' }} />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: '0.25rem',
                  marginTop: '5px'
                }}
              >
                <div>
                  <Translator id={data.name_id} collection="words" />
                </div>
                <div
                  className="small"
                  style={{
                    marginTop: '4px',
                    color: '#aaa'
                  }}
                >
                  {data.name}
                </div>
              </div>
            </div>
            <div className="info-item">
              <img src={timeIcon} alt="" style={{ marginRight: '0.5rem' }} />
              <div>{data.timeData && !data.timeData.isAllDayOpen ? data.time : '24 hours'}</div>
            </div>
            <div className="info-item">
              <img src={closeIcon} alt="" style={{ marginRight: '0.5rem' }} />
              {data.holidays
                ? data.holidays
                    .sort()
                    .map((item, index) => <span key={index}>{numberToDay(item)}&nbsp;</span>)
                : 'None'}
            </div>
            <div className="info-item">
              <img src={phoneIcon} alt="" style={{ marginRight: '0.5rem' }} />
              {data.phone}
            </div>
            <div className="info-item">
              <img src={addressIcon} alt="" style={{ marginRight: '0.5rem' }} />
              {data.address.detail} {data.address.en}
            </div>
          </>
        )}
      </Container>
    </>
  );
};

export default withRouter(PreviewStoreInfo);
