import React from 'react';
import styled from 'styled-components';
import bg from '../../images/bg-profile.svg';
import { useSelector } from 'react-redux';
import Icon from '../../components/Icons';

const Container = styled.div`
  width: 80%;
  max-width: 430px;
  height: ${window.innerHeight - 46 + 'px'};
  transform: ${({ open }) => (open ? 'translateX(35%)' : 'translateX(200%)')};
  opacity: ${({ open }) => (open ? 1 : 0)};
  visibility: ${({ open }) => !open && 'hidden'};
  transition: 0.25s transform ease-out;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
  z-index: 200;
  background: white;
  font-size: 16px;
  position: fixed;
  top: 47px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #373751;
  overflow-y: scroll;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
  }
  .item {
    width: 100%;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
      margin-right: 0.25rem;
    }
  }
  .info-img {
    display: flex;
    background: ${props => `url(${props.img})`};
    width: 100%;
    height: 96px;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .select-box {
    -moz-appeance: none;
    -webkit-appearance: none;
    appearance: none;
    border: none;
    font-size: 16px;
    line-height: 1.5;
    background: white;
  }
`;

const PreviewSidebar = ({ open, onChangeLang, storeName }) => {
  const language = useSelector(state => state.lang.lang, []);
  const height = document.body.offsetHeight;

  return (
    <Container open={open} height={height} img={bg}>
      <div>
        <div className="info-img">
          <div
            style={{
              marginTop: '56px',
              marginLeft: '1rem',
              fontSize: '18px',
              fontWeight: 'bold'
            }}
          >
            {storeName}
          </div>
        </div>
        <div className="item">
          <Icon type="lang" />
          <select
            className="select-box"
            onChange={onChangeLang}
            defaultValue={language}
            style={{ width: 'fit-content', padding: 0 }}
          >
            <option value="en">English</option>
            <option value="cn">中国 - 简体中文</option>
            <option value="jp">日本語</option>
            <option value="th">ไทย</option>
          </select>
        </div>
      </div>
    </Container>
  );
};

export default PreviewSidebar;
