import React, { useEffect } from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 95%;
  margin: 0 2.5%;
  a {
    color: blue;
  }
  table {
    border-collapse: collapse;
    font-size: 14px;
  }
  th,
  td {
    border: 1px solid gray;
    text-align: center;
  }
`;

const Privacy_en = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <h2 id="article1purpose">Article 1) Purpose</h2>

      <p>
        ① &quot;Personal information&quot; is information about an individual
        who is alive, and information that can identify the individual by the
        name, resident registration number, etc. included in the information
        (the information alone cannot identify a specific individual, Including
        those that can be identified and combined. ② The company places great
        importance on the protection of your personal information and complies
        with the Personal Information Protection Act, the Personal Information
        Protection Regulations on the Information Communication Network
        Promotion and Information Protection Act, and the Personal Information
        Protection Guidelines established by the Ministry of Information and
        Communication. We are. The Company will disclose the purpose of the
        personal information processing policy to understand the purpose and use
        of the personal information you provide and what measures are being
        taken to protect your personal information.
      </p>

      <h2 id="article2itemsofpersonalinformationcollected">
        Article 2) Items of Personal Information Collected
      </h2>

      <ol>
        <li>1 Personal information collected when using</li>

        <li>Required: N/A 2. Optional: preferred language</li>

        <li>2 Personal information collected when ordering</li>

        <li>Required: Hotel Room Number</li>

        <li>
          3Personal information collected when contacting customer service
        </li>

        <li>
          Affiliation / advertisement: Person in charge, name, phone number,
          address
        </li>

        <li>
          Customer support / other inquiries: email, phone number, order number
        </li>

        <li>Restaurant registration request: Phone number</li>
      </ol>

      <h2 id="article3consenttocollectionofpersonalinformation">
        Article 3) Consent to Collection of Personal Information
      </h2>

      <p>
        You may refuse to consent to the collection of personal information
        under &quot;Article 2) Items of Personal Information Collected&quot;.
        However, if you refuse, you may not be able to join the service or you
        may not be able to use the service. In addition, the Company may collect
        and use the personal information of the user without the consent in
        accordance with the laws and regulations when it falls under any of the
        following subparagraphs:
      </p>

      <ol>
        <li>
          It is obviously difficult to obtain the usual consent for economic and
          technical reasons as personal information necessary to fulfill the
          contract for the provision of information and telecommunication
          services
        </li>

        <li>
          When it is necessary for the settlement of charges due to the
          provision of information and communication services
        </li>

        <li>If there are other special provisions in the law</li>
      </ol>

      <h2 id="article4collectionanduseofpersonalinformation">
        Article 4) Collection and Use of Personal Information
      </h2>

      <p>
        The Company collects personal information for the following purposes,
        and will ask for the user&#39;s consent in advance if the purpose is
        changed.
      </p>

      <ol>
        <li>E-mail address (login ID), password: Used for customer service</li>

        <li>
          E-mail address and phone number: Secure communication path such as
          notification, self- Information on new products or event information,
          Write a review about service use Invitation, Identify your identity
          for customer inquiries and answers, Provide other services and
          advertisement information provided by the company
        </li>

        <li>
          Address and phone number: Delivery according to user&#39;s food order
          And to ensure the exact destination for the delivery of goods and
          identification of the person for identity, contact and answer
        </li>
      </ol>

      <h2 id="article5collectionofpersonalinformationbycookies">
        Article 5) Collection of personal information by cookies
      </h2>

      <p>
        ① A cookie is a small amount of information that a website sends to your
        computer browser (such as Internet Explorer). The company uses
        &#39;cookies&#39; to store information about you and to find it from
        time to time. Cookies identify your computer but do not personally
        identify you. You also have a choice of cookies. You can have the option
        to accept all cookies from the Tools\&gt; Internet Options tab at the
        top of your web browser, send a notification when a cookie is installed,
        or reject all cookies. ② Use of cookies
      </p>

      <ol>
        <li>
          Analyze the frequency of access or stay time to identify the
          user&#39;s taste and interests and use it in target marketing
        </li>

        <li>
          Analyze the habits of the members and measure the service
          reorganization
        </li>

        <li>
          Registering a board post Cookie expires when you close your browser or
          log out
        </li>
      </ol>

      <p>
        <table>
          <thead>
            <tr>
              <th>Company name</th>
              <th>Purpose</th>
              <th>Personal information provided</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Delivery Restaurant, Delivery Agent (Delivery Agent)</td>
              <td>
                Providing affiliate services including delivery service, etc.
              </td>
              <td>Name, mobile phone number, shipping address, Room number</td>
            </tr>
          </tbody>
        </table>
      </p>

      <h2 id="article6provisionofpersonalinformationtoathirdparty">
        Article 6) Provision of Personal Information to a Third Party
      </h2>

      <p>
        ① The Company shall use your personal information within the limits
        specified in the &quot;Purpose and purpose of collecting personal
        information&quot; and shall not use it beyond the scope of this
        information or provide it to any other person or company or
        organization. In particular, we will use and provide personal
        information with the following caution.
      </p>

      <ol>
        <li>
          Affiliate: You may provide or share your personal information with
          affiliates for the purpose of fulfilling the delivery service use
          agreement. In the event that you provide or share your personal
          information with the delivery (agency) and your local partners, you
          will notify us in advance of the name of the affiliate, the personal
          information item to be provided or shared (name of the recipient of
          the goods or service, You will be notified by e-mail and written
          notice individually about the reason for providing and sharing
          information, the method and duration of the protection, and if you do
          not agree, you will not provide it to the affiliate or share it with
          the affiliate. If you have a change in your partnership or terminate
          your partnership, you will be informed or agreed to by the same
          process.
        </li>

        <li>
          Sale, Merger, etc.: If the rights and obligations of the service
          provider are transferred completely, we will give detailed notice of
          the reason and procedure in advance and give the option of withdrawing
          consent to the user&#39;s personal information.
        </li>
      </ol>

      <p>
        ② Notice and consent method will be announced at least 10 days prior to
        announcement on the homepage of the homepage of online homepage. ③ The
        following are exceptions.
      </p>

      <ol>
        <li>
          When there is a request from related organizations for investigation
          purposes in accordance with the related laws and regulations.
        </li>

        <li>
          When it is provided to advertisers&#39; partners or research
          organizations in a form that can not identify a specific individual
          for statistical writing, academic research or market research.
        </li>

        <li>
          If there is a request pursuant to the procedures set forth in other
          relevant laws and regulations
        </li>

        <li>
          In exceptional cases, however, if the information is provided by the
          related laws or by the request of the investigation agency, it is
          notified to the parties. It may not be necessary due to legal grounds.
          We will do our utmost to prevent information from being used
          indiscriminately against the original purpose of collection and use.
        </li>
      </ol>

      <h2 id="article7retentionanduseperiodofpersonalinformationcollected">
        Article 7) Retention and Use Period of Personal Information Collected
      </h2>

      <p>
        ① The Company uses the member&#39;s personal information only from the
        time of using the service to the period of providing the service. ②
        After withdrawing from membership or suspension of membership,
        re-enrollment or termination of the membership will be repeatedly used
        to take advantage of economic benefits such as discount coupons and
        event benefits provided by the company or unauthorized use of names in
        this process. The Company will keep the information of the member for 90
        days after the withdrawal of membership. ③ Notwithstanding paragraphs 1
        through 4 of this Article, in the case of personal information that
        needs to be held for a certain period of time due to the confirmation of
        the relationship of rights and obligations related to the transaction,
        the Company shall keep the personal information in accordance with the
        laws and regulations stated below I will. • Personal information related
        to the use of the service (log-in record) Conservation grounds:
        Preservation period of communication secret law 3 months • Records /
        advertising records Preservation grounds: Law on consumer protection in
        e-commerce etc. 6 months • Contract or withdrawal Records Preservation
        Basis: Act on Consumer Protection in Electronic Commerce etc. Retention
        period: 5 years • Record of payment and goods supply: Preservation
        basis: Act on consumer protection in electronic commerce, etc. Retention
        period: 5 years • Consumer complaint or dispute Records on processing
        Records Preservation basis: Act on consumer protection in e-commerce,
        etc. Retention period 3 years • Records on personal location information
        Preservation ground: Act on the protection and use of location
        information Retention period 1 year • Retention period for Electronic
        Financial Transactions: Preservation Period of Electronic Financial
        Transactions Act: 5 years • Records on data registration and sales: 180
        days ④ If you request to view the transaction information you have
        consented to, Take measures so that you can check the reading without
        delay.
      </p>

      <h2 id="article8consignmentprocessingofpersonalinformation">
        Article 8) Consignment processing of personal information
      </h2>

      <p>
        In order to improve the service, the Company may entrust and process the
        collection, processing, and management of your personal information
        externally.
      </p>

      <ol>
        <li>
          When entrusting the processing of personal information, we will notify
          you of the consigning agency and its facts through the homepage in
          advance. However, in the case of entrusting the business of promoting
          or soliciting goods or services or soliciting sales, the individual
          shall be notified individually to the user through e-mail address,
          etc., and the details of the work entrusted by the company in writing
          If we can not notify you and the trustee, we will post it on the
          homepage for more than 30 days.
        </li>

        <li>
          In the case of entrusting the processing of personal information, we
          will do our best to keep the confidentiality of the personal
          information of the service provider through the contract agreement
          etc.
        </li>

        <li>
          We prohibit the provision of third parties and the burden of liability
          at the time of the accident, the period of consignment, the return or
          destruction of personal information after the end of processing, etc.,
          and keep the written or electronic contents of the contract.
        </li>

        <li>
          The Company&#39;s personal information consignment processing agency
          and entrusted business contents are as follows.
        </li>
      </ol>

      <p>
        <table>
          <thead>
            <tr>
              <th>Company</th>
              <th>Service contents</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Alipay(China)</td>
              <td rowSpan={4}>Offer electronic payment method</td>
            </tr>
            <tr>
              <td>WeChat Online(China)</td>
            </tr>
            <tr>
              <td>UnionPay(China)</td>
            </tr>
            <tr>
              <td>Paypal (US)</td>
            </tr>
          </tbody>
        </table>
      </p>

      <h2 id="article9personalinformationprotectionofficer">
        Article 9) Personal Information Protection Officer
      </h2>

      <p>
        The company is doing its best to ensure that you have good access to
        good information. In case of any incidents that arise in relation to the
        protection of your personal information, you are responsible for the
        protection of personal information. However, despite technical remedies,
        we are not responsible for any damage caused by unexpected accidents
        caused by basic network dangers such as hacking, and any disputes caused
        by posts made by visitors. The person and person responsible for
        handling your personal information are as follows, and we will respond
        promptly and faithfully to any inquiries about your personal
        information. [Personal Information Protection Officer]
      </p>

      <p>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Department</th>
              <th>Email</th>
              <th>Phone Number</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Mark Jung</td>
              <td>C/S</td>
              <td>help@publics.co.kr</td>
              <td>02-577-0420</td>
            </tr>
          </tbody>
        </table>
      </p>

      <h2 id="article10obligationofnotification">
        Article 10) Obligation of Notification
      </h2>

      <p>
        The current personal information processing policy will be notified
        through &#39;notice&#39; on the homepage at least 7 days before the
        amendment if there is any addition, deletion or modification of contents
        due to the change of government policy or security technology.
      </p>
    </Container>
  );
};

export default Privacy_en;
