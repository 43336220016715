import React from 'react';
import {
  korean,
  japanese,
  chinese,
  pizza,
  chicken,
  dessert,
  burger,
  noodle,
  salad,
  // midnight,
  america,
  african,
  mexican,
  arabic,
  indian,
  european,
  meal_box,
  grocery,
  none,
  all,
} from '../../images';

import styled from 'styled-components';

const ImgContainer = styled.img`
  width: ${(props) => props.width};
`;

const CategoryIcon = ({ type, style }) => {
  const srcMatch = (type) => {
    switch (type) {
      case 'all':
        return all;
      case 'korean':
        return korean;
      case 'japanese':
        return japanese;
      case 'chinese':
        return chinese;
      case 'pizza':
        return pizza;
      case 'chicken':
        return chicken;
      case 'dessert':
        return dessert;
      case 'burger':
        return burger;
      case 'asian':
        return noodle;
      case 'salads':
        return salad;
      case 'america':
        return america;
      case 'african':
        return african;
      case 'mexican':
        return mexican;
      case 'european':
        return european;
      case 'meal_box':
        return meal_box;
      case 'indian':
        return indian;
      case 'arabic':
        return arabic;
      case 'grocery':
        return grocery;
      default:
        return none;
    }
  };
  return <ImgContainer src={srcMatch(type)} width={type === 'none' ? '65%' : '58%'} style={style} />;
};

export default CategoryIcon;
