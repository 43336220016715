import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Radio } from '../Icons/Checkbox';
import { formatPrice, formatTenPercentCommission } from '../../common/format';
import Translator from '../../common/Translator/Translator';

const Container = styled.div`
  width: 90%;
  margin: 0 5%;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: black;
  border-bottom: solid 1px #e6e6e6;
  .option-item {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    line-height: 1.5;
    transition: 0.2s all ease-out;
  }
`;

const RadioGroup = ({ data, onChange, commission }) => {
  const [selected, setSelelected] = useState(0);

  useEffect(() => {
    data.forEach((item, index) => {
      if (item.default) {
        setSelelected(index);
      }
    });
  }, [data]);

  const handleClick = index => {
    setSelelected(index);
    const selectedData = data[index];
    onChange(selectedData, 'radio', data[selected]);
  };

  return (
    <Container>
      {data.map((item, index) => {
        return (
          <div
            className="option-item"
            key={`radio-${index}`}
            onClick={() => {
              handleClick(index);
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                height: '16px',
                lineHeight: 1.5
              }}
            >
              <Radio selected={selected === index} />
              <div style={{ marginLeft: '0.5rem' }}>
                {item.name_id ? <Translator id={item.name_id} collection="words" /> : item.realName}
              </div>
            </div>{' '}
            <div style={{ fontSize: '14px', lineHeight: 1.14 }}>
              +₩{formatPrice(commission ? formatTenPercentCommission(item.price) : item.price)}
            </div>
          </div>
        );
      })}
    </Container>
  );
};

export default RadioGroup;
