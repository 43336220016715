// japanese
export const jp = {
  total: '合計金額',
  order: '注文',
  nomenu: 'カートに商品なし',
  deliveryfee: '送料',
  chicken: 'チキン',
  korean: '韓国',
  japanese: '日本',
  dessert: 'デザート',
  pizza: 'ピザ/ハンバーガー',
  burger: 'ハンバーガー',
  chinese: '中国',
  street: '通り',
  salads: 'サラダ',
  asian: 'アジア',
  midnight: '夜食',
  deliverytime: '予想時間',
  minute: '分',
  address: '住所',
  businesshour: '営業時間',
  telephone: '電話',
  main: 'メインメニュー',
  side: 'サイドメニュー',
  info: '詳細',
  payment: '支払い',
  quantity: '数量',
  addtocart: 'カートに入れる',
  unavailable: '営業終了',
  alipay: 'アリペイ',
  makepayment: '注文する',
  size: 'サイズ',
  option: 'オプション',
  credit: 'クレジットカード',
  myorder: '私の注文',
  mylocation: '現在地',
  orderplaced: '注文完了',
  preparing: '準備中',
  delivery: 'デリバリー',
  paymentsuccess: '支払い完了',
  cancel: '支払いキャンセル',
  cancel2: '支払いがキャンセルされました',
  cart1: 'すでに同じ項目がカートにあります。 追加しますか？',
  cart2: '"カートには同一店舗の注文のみ追加が可能です。カートを空けて追加しますか？',
  method: '支払い方法をお選び下さい。',
  invalid: 'ルートが間違っています。',
  room1: 'ルームナンバーを入力して下さい',
  room2: '部屋番号を正しく入力して下さい。',
  nearby: '周辺の情報',
  home: 'ホーム',
  pay1:
    '注文受付の完了後はキャンセルはできません。ご注文のお食事、支払い情報を確認し、これに同意致します。(必須)',

  minprice: '最小注文金額',
  price: '価格',
  exim: `1) ご利用の決済手段はEximbayから提供しておりまして、www.eximbay.comにて請求されます。`,
  exim2: `2) ご注意：明細書にはEXIMBAY.COM にて表示されますため、ご参考のほどお願い申し上げます。`,
  original: '基本',
  subtotal: '合計',
  empty: '買い物かごが空いています',
  undermin: `最小注文金額未達`,
  countlimit: '最大の注文数量は50個です。',
  nohistory: '注文内訳がありません。',
  status1: 'ご注文受付待ち',
  status2: 'お店で注文を確認しました。',
  status3: '配達中',
  status4: '配達完了',
  deleteinfo: `顧客の注文履歴は決済完了後72時間以内に自動削除され、
  詳細はあなたの電子メールで確認することができます。`,
  emailagreement: `入力したEメールアドレスをマーケティングに活用することに同意します。(選択)`,
  emailinput: `メールアドレスをご入力いただくと領収書をお送りします。`,
  popup: `決済画面が開かない場合、プラウザの設定画面でポップアップブロックを解除してください。`,
  browser: `『決済完了』と表示された後、現在のペ－ジに戻った時点で、お支払い手続きが正常完了します`,
  hoteltime: `この時間は配達が出来ません。`,
  openingsoon: `まもなく営業を開始します`,
  nowclosed: `申し訳ございません。ただいま営業時間外です。`,
  tourguide: `観光情報`,
  faq: `よくある質問`,
  aboutus: `企業情報`,
  african: 'アフリカ',
  all: `全てのレストラン`
};
