import React, { useState, useEffect, useMemo, useContext } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addCart } from '../../redux/cart';
import Header from '../../Layouts/Header';
import Tag from '../../components/Tag/Tag';
import { PlusIcon, MinusIcon } from '../../components/Icons/Counter';
import RadioGroup from '../../components/Options/RadioGroup';
import OptionGroup from '../../components/Options/OptionGroup';
import { formatPrice, formatTenPercentCommission } from '../../common/format';
import { FirebaseContext } from '../../firebase';
import Translator, { stringReturn } from '../../common/Translator/Translator';
import Loader from '../../components/Icons/Loader';
import { filterObjectByKeyArr } from '../../common/filter';
import { sortBy } from 'lodash';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #aaaaaa;
  font-size: 12px;
  min-height: 100vh;
  .head-box {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 5%;
    text-align: left;
    border-bottom: solid 1px #e6e6e6;
    padding-bottom: 8px;
    .title {
      font-size: 22px;
      line-height: 1.09;
      color: #000000;
    }
    .sub-title {
      margin: 4px 0 8px 0;
      font-size: 12px;
      line-height: 1.33;
      color: #aaaaaa;
    }
    .tags {
      display: flex;
      flex-flow: row wrap;
    }
  }
  .option {
    width: 90%;
    margin: 0 5%;
    height: 48px;
    border-bottom: solid 1px #e6e6e6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    color: black;
    line-height: 1.5;
  }
  .min {
    width: 90%;
    margin: 0 5%;
    margin-bottom: 4rem;
    text-align: center;
    padding-top: 8px;
  }
  .bold {
    font-weight: bold;
  }
`;

const Image = styled.div`
  background-image: ${props => `url(${props.img})`};
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: ${window.innerWidth * 0.9 * 0.625 + 'px'};
  max-height: 326px;
  margin: 5% 0 2.5% 0;
  border-radius: 4px;
`;

const OrderButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  background-color: #ff3250;
  color: white;
  cursor: pointer;
  padding: 12px 0;
  font-size: 16px;
  line-height: 1;
  transition: 0.2s all ease-out;
  white-space: nowrap;
  &:hover {
    background-color: #c3223a;
  }
  .name {
  }
  .total {
    position: fixed;
    bottom: 1.75rem;
    right: 10%;
    font-size: 14px;
    line-height: 1.14;
  }
`;
const ButtonBackground = styled.div`
  z-index: 100;
  position: fixed;
  bottom: 0;
  width: 90%;
  max-width: 522px;
  margin: 0 5%;
  padding: 0.5rem 0 1rem 0;
  @media screen and (min-width: 580px) {
    margin: 0 29px;
  }
`;

const MenuDetail = props => {
  const [data, setData] = useState(null);
  const { lang } = useSelector(state => state.lang, []);
  const [store, setStore] = useState(null);
  const [optionData, setOptionData] = useState(null);
  const [counter, setCounter] = useState(1);
  const [options, setOptions] = useState([]);
  const { id } = props.match.params;
  const { db } = useContext(FirebaseContext);

  // componentDidMount
  useEffect(() => {
    // loadData 추후에 연동후 구현
    db.collection('menues')
      .doc(id)
      .get()
      .then(doc => {
        setData({ ...doc.data(), id: doc.id });
        db.collection('stores')
          .doc(doc.data().store_id)
          .get()
          .then(storeDoc => setStore({ ...storeDoc.data(), id: storeDoc.id }));
      });

    db.collection('options')
      .where('menu_id', '==', id)
      .get()
      .then(snapshots => {
        let result = [];
        snapshots.forEach(doc => result.push(doc.data()));
        setOptionData(result);
        setDeafultOption(result);
      });

    const setDeafultOption = data => {
      const defaultOptions = [];
      data
        .filter(group => group.radio === true)
        .forEach(group =>
          group.list.forEach(option => {
            if (option.default) {
              defaultOptions.push(option);
            }
          })
        );
      if (defaultOptions.length > 0) {
        setOptions(options => options.concat(defaultOptions));
      }
    };

    // setDefaultOption
  }, [db, id]);

  const addCounter = () => {
    if (counter === 50) {
      alert(stringReturn(lang, 'countlimit'));
    }
    setCounter(counter === 50 ? 50 : counter + 1);
  };

  const minusCounter = () => {
    setCounter(counter === 1 ? 1 : counter - 1);
  };

  const addOption = (data, type, prevData) => {
    if (type === 'add') {
      setOptions(options.concat(data));
    } else if (type === 'delete') {
      deleteOption(data);
    } else if (type === 'radio') {
      setOptions(options.filter(option => option.name !== prevData.name).concat(data));
    }
  };

  const deleteOption = data => {
    setOptions(options.filter(option => option.name !== data.name));
  };

  // redux connect
  const dispatch = useDispatch();
  const handleAddCart = value => dispatch(addCart(value));

  const total = useMemo(() => {
    let optionPrice = 0;
    options.map(option => {
      if (option.price) optionPrice += formatTenPercentCommission(parseInt(option.price));
      return null;
    });
    return (
      data && (formatTenPercentCommission(parseInt(data.price)) + parseInt(optionPrice)) * counter
    );
  }, [counter, options, data]);

  return (
    <Container>
      <Header
        store={store && <Translator id={store.name_id} collection="words" />}
        id={data && data.store_id}
      />
      {store ? (
        <>
          <div className="head-box">
            {data.photo ? (
              <Image img={data.photo} />
            ) : (
              <div style={{ width: '100%', height: '1rem' }} />
            )}
            <div className="title bold">
              <Translator id={data.name_id} collection="menudb" />
            </div>

            <div className="sub-title">
              <div>{<Translator id={data.name_id} collection="menudb" type="roman" />}</div>
              {data.realName}
            </div>
            <div className="tags">
              {data.tags &&
                data.tags.map(item => (
                  <Tag name={<Translator id={item} collection="tags" />} key={item} />
                ))}
            </div>
          </div>
          <div className="option">
            <div className="bold">
              <Translator id="original" type="local" />
            </div>
            <div>₩{formatPrice(formatTenPercentCommission(data.price))}</div>
          </div>
          {optionData &&
            sortBy(optionData, 'index')
              .filter(group => group.radio === true)
              .map((group, index) => (
                <React.Fragment key={`group-1-${index}`}>
                  <div className="option">
                    <div className="bold">
                      {group.groupName_id ? (
                        <Translator id={group.groupName_id} collection="words" />
                      ) : (
                        group.groupName
                      )}
                    </div>
                  </div>
                  <RadioGroup data={group.list} onChange={addOption} commission={true} />
                </React.Fragment>
              ))}
          {optionData &&
            sortBy(optionData, 'index')
              .filter(group => !group.radio)
              .map((group, index) => (
                <React.Fragment key={`group-${index}`}>
                  <div className="option">
                    <div className="bold">
                      {group.groupName_id ? (
                        <Translator id={group.groupName_id} collection="words" />
                      ) : (
                        group.groupName
                      )}
                    </div>
                  </div>
                  <OptionGroup data={group.list} onClick={addOption} commission={true} />
                </React.Fragment>
              ))}
          <div className="option">
            <div className="bold">
              <Translator id="quantity" type="local" />
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                height: '24px'
              }}
            >
              <MinusIcon active={true} onClick={minusCounter} />
              <span style={{ width: '32px', textAlign: 'center' }}>{counter}</span>
              <PlusIcon active={true} onClick={addCounter} />
            </div>
          </div>
          <div className="min">
            <Translator id="minprice" type="local" /> ₩{store && formatPrice(store.minprice || 0)}
          </div>
          <ButtonBackground>
            <div className="blur">
              <OrderButton
                onClick={() => {
                  const toDeleteArr = ['photo', 'tags', 'editedAt', 'category'];

                  handleAddCart({
                    ...filterObjectByKeyArr(toDeleteArr, data),
                    counter,
                    total,
                    options,
                    store_name_id: store.name_id,
                    store_min_price: store.minprice || 0,
                    language: lang,
                    store_delivery_fee: store.deliveryFee || 0
                  });
                }}
              >
                <div className="name">
                  <Translator id="addtocart" type="local" />
                  &nbsp;({counter})
                </div>
                <div className="total"> ₩{formatPrice(total)}</div>
              </OrderButton>
            </div>
          </ButtonBackground>
        </>
      ) : (
        <Loader />
      )}
    </Container>
  );
};

export default withRouter(MenuDetail);
