import React, { useContext, useEffect } from 'react';
import Loader from '../../components/Icons/Loader';
import { FirebaseContext } from '../../firebase';
import { useDispatch } from 'react-redux';
import { clearCart } from '../../redux/cart';
import Payment from '../../common/Classes/Payment';
import { fetchDeleteTempData, fetchCompletedData } from '../../redux/order';
import { logger } from '../../common/utils';

// myorder-> 미완료주문 -> 클릭시 체크후 주문 완료 처리
const CheckAndComplete = props => {
  const { id } = props.match.params;
  const { db } = useContext(FirebaseContext);
  const dispatch = useDispatch();

  const loadTempData = orderNumber => {
    return db
      .collection('orders-temp')
      .doc(orderNumber)
      .get()
      .then(doc => {
        return { exists: doc.exists, orderData: doc.data() };
      })
      .catch(err => console.log(err));
  };

  const completeOrder = async () => {
    const { status } = await Payment.checkPayStatus(id);

    // 결제 성공: db에 저장, 그외: 임시데이터삭제 후 주문 페이지로
    if (status === 'paid') {
      const result = await loadTempData(id);
      if (!result.exists) {
        alert('Invalid order processing path');
        return props.history.push('/');
      }
      dispatch(fetchDeleteTempData(id));
      dispatch(fetchCompletedData(result.orderData));

      Payment.sendPushMsg(result.orderData);
      dispatch(clearCart());
      props.history.push('/order/my');
    } else {
      logger('임시주문삭제');
      dispatch(fetchDeleteTempData(id));
      props.history.push('/');
    }
    return;
  };

  useEffect(() => {
    if (id) {
      completeOrder();
    } else {
      alert('Invalid order processing path');
      return props.history.push('/');
    }
  }, [id]); // eslint-disable-line

  return (
    <>
      <Loader>
        Checking payment history <br />
        Please wait...
      </Loader>
    </>
  );
};

export default CheckAndComplete;
