import React from 'react';

function Test() {
  return (
    <div>
      <h5>redux-saga-firebase</h5>
    </div>
  );
}

export default Test;
