import React, { useEffect, useState, useContext } from 'react';
import { throttle, sortBy, debounce } from 'lodash';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import CategorySwipe from '../../components/Swipe/CategorySwipe';
import TimeIcon from '../../images/time2.svg';
import NextIcon from '../../images/next.svg';
import animateScrollTo from 'animated-scroll-to';
import { FirebaseContext } from '../../firebase';
import Translator from '../../common/Translator/Translator';
import Loader from '../../components/Icons/Loader';
import EmptyHeader from './PreviewHeader';
import MenuItem from '../../components/Menu/MenuItem';

const Container = styled.div`
  color: #aaaaaa;
  font-size: 12px;
  .head-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    border-bottom: solid 1px #e6e6e6;
    padding-bottom: 24px;
    color: #aaaaaa;
    .title {
      margin-top: 24px;
      font-size: 22px;
      line-height: 1.09;
      color: #000000;
      font-weight: 600;
    }
  }
`;

const Preview = props => {
  const [header, setHeader] = useState(null);
  const [category, setCategory] = useState(0);
  const { db } = useContext(FirebaseContext);
  const { id } = props.match.params;
  const [menues, setMenu] = useState();
  const [store, setStore] = useState(null);

  useEffect(() => {
    db.collection('menues')
      .where('store_id', '==', id)
      .get()
      .then(snapshots => {
        let menuData = [];
        snapshots.forEach(doc => menuData.push({ ...doc.data(), id: doc.id }));
        setMenu(sortBy(menuData, ['index']).reverse());
      });
    db.collection('stores')
      .doc(id)
      .get()
      .then(doc => setStore({ ...doc.data(), id: doc.id }));
  }, [id, db]);

  // 상단 헤더 로고에서 업체명으로 변경
  useEffect(() => {
    const changeHeader = throttle(() => {
      if (window.scrollY >= 112) {
        if (header === null) {
          setHeader(<Translator id={store.name_id} collection="words" />);
          const elStore = document.getElementById('header-store');
          if (elStore) {
            elStore.style.opacity = 0.2;
            setTimeout(() => {
              elStore.style.opacity = 1;
            }, 10);
          }
        }
      } else {
        if (header !== null) {
          setHeader(null);
          const elLogo = document.getElementById('header-logo');
          if (elLogo) {
            // 여기가 0
            elLogo.style.opacity = 0.2;
            setTimeout(() => {
              elLogo.style.opacity = 1;
            }, 10);
          }
        }
      }
    }, 300);

    window.addEventListener('scroll', changeHeader);

    return () => {
      window.removeEventListener('scroll', changeHeader);
    };
  }, [store, header]);

  // 스크롤 위치에 따라 메뉴 카테고리 변경
  useEffect(() => {
    if (!store) return;
    const setCategoryByScroll = debounce(e => {
      const cateArray = store.menuCategories.map(item => item.name) || [];
      const posArray = cateArray.map(
        name => document.getElementsByName(name)[0] && document.getElementsByName(name)[0].offsetTop
      );
      if (window.scrollY <= 112) {
        setCategory(0);
      }

      // 스크롤시 아래방향 탭 영역 활성화
      if (
        (window.scrollY >= posArray[category + 1] - 95 &&
          window.scrollY <= posArray[category + 2] - 250) ||
        (!posArray[category + 2] && window.scrollY >= posArray[category + 1] - 95)
      ) {
        setCategory(category + 1);
      }
      // 스크롤 위 방향
      if (category > 0) {
        if (
          window.scrollY >= posArray[category - 1] - 95 &&
          window.scrollY <= posArray[category] - 300
        ) {
          setCategory(category - 1);
        }
      }
      // 스크롤 끝
      if (window.scrollY >= document.body.offsetHeight - window.innerHeight - 5) {
        setCategory(cateArray.length - 1);
      }
    }, 200);

    window.addEventListener('scroll', setCategoryByScroll);
    return () => {
      window.removeEventListener('scroll', setCategoryByScroll);
    };
  }, [category, menues, store]);

  useEffect(() => {
    if (menues && store && store.menuCategories) {
      const cateArray = store.menuCategories.map(item => item.name) || [];
      const index = category;

      const el = document.getElementById(`cate-${cateArray[index === 0 ? 0 : index - 1]}`);
      const catePos = el && el.offsetLeft;
      const cateRef = document.getElementById('category-scroll');
      animateScrollTo(catePos, {
        horizontal: true,
        element: cateRef,
        speed: 50
      });
    }
  }, [category, menues, store]);

  // y축: 카테고리 이름을 name props로 지정하여 첫번째 element를 찾은다음에 그 위치로 이동
  // x축: 카테고리 이름 바로전 항목의 위치로 이동 24px은 padding 만큼 더한 것
  const onClickCategory = name => {
    const cateArray = store.menuCategories.map(item => item.name) || [];
    const index = cateArray.indexOf(name);
    setCategory(index);
    if (name === cateArray[0]) {
      animateScrollTo(0, { speed: 100 });
    } else {
      const pos = document.getElementsByName(name)[0].offsetTop;
      const height = document.getElementsByName(name)[0].getBoundingClientRect().height;
      animateScrollTo(pos - height - 40, { speed: 100 });
    }
  };

  return (
    <>
      <EmptyHeader
        store={header}
        id={id}
        storeName={store && <Translator id={store.name_id} collection="words" />}
      />
      <Container>
        <Link to={`/preview/info/${id}`}>
          {store && (
            <div className="head-box">
              <div className="title">
                <Translator id={store.name_id} collection="words" />
              </div>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%'
                }}
              >
                <div style={{ marginLeft: '12%' }} />
                <div
                  style={{
                    marginTop: '4px',
                    lineHeight: 1.33
                  }}
                >
                  {store.realName}
                </div>

                <img src={NextIcon} alt="" style={{ marginRight: '5%' }} />
              </div>

              <div
                style={{
                  marginTop: '6px',
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center'
                }}
              >
                <img src={TimeIcon} alt="" style={{ marginTop: '-2px' }} />
                <div />
                {store.averageTime}~{parseInt(store.averageTime) + 10}{' '}
                <Translator id="minute" type="local" />
              </div>
            </div>
          )}
        </Link>
        <CategorySwipe>
          {menues &&
            store &&
            store.menuCategories &&
            store.menuCategories.map((item, index) => (
              <div
                key={`${item}_${index}`}
                active={index === category ? 'true' : 'false'}
                style={{
                  padding: '0 1.2rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                  minWidth: '45px'
                }}
                onClick={() => {
                  // setCategory(index);
                  onClickCategory(item.name);
                }}
                id={`cate-${item.name}`}
              >
                <Translator id={item.name_id} collection="words" />
              </div>
            ))}
        </CategorySwipe>
        {menues && store ? (
          <div style={{ overflowX: 'hidden' }}>
            {store.menuCategories &&
              store.menuCategories.map((category, index) => (
                <React.Fragment key={index}>
                  <div
                    style={{
                      background: '#f9f9f9',
                      width: '100%',
                      padding: '12px 5%',
                      fontWeight: 'bold',
                      lineHeight: 1.5,
                      fontSize: '16px',
                      color: 'black'
                    }}
                    name={category.name}
                  >
                    <Translator id={category.name_id} collection="words" />
                  </div>
                  {sortBy(
                    menues.filter(menu => menu.category === category.name),
                    'index'
                  ).map((menu, index) => (
                    <React.Fragment key={index}>
                      <MenuItem
                        data={menu}
                        onClick={() => {
                          props.history.push(`/preview/menu/${menu.id}`);
                        }}
                      />

                      {/* 아래는 border 라인 중간에만 들어가게 하기 위함 */}
                      {index !== menues.filter(menu => menu.category === category).length - 1 && (
                        <div
                          style={{
                            width: '90%',
                            margin: '0 5%',
                            borderBottom: '1px solid #e6e6e6',
                            height: '1px'
                          }}
                        />
                      )}
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))}
          </div>
        ) : (
          <Loader />
        )}
      </Container>
    </>
  );
};

export default Preview;
