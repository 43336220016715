import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const Item = styled.div`
  width: 100%;
  height: ${({ height }) => height + 'px'};
  max-height: 280px;
  background-image: ${props => `url(${props.img})` || '#efefef'};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  width: 90%;
  margin: 0 5%;
  text-align: left;
  color: #fff;
  text-shadow: 0 0 16px rgba(0, 0, 0, 0.5);
  font-size: 20px;
  font-weight: bold;
  line-height: 1.09;
  font-family: Arial;
`;

const SubTitle = styled.div`
  width: 90%;
  margin: 0 5%;
  text-align: left;
  text-shadow: 0 0 16px rgba(0, 0, 0, 0.5);
  font-size: 13px;
  font-weight: bold;
  line-height: 1.23;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 16px;
`;

const Dark = styled.div`
  position: relative;
  float: left;
  width: 100%;
  z-index: 1;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const MainContentItem = ({ data, style }) => {
  const { lang } = useSelector(state => state.lang, []);
  const [height, setHeight] = useState(window.innerWidth * 0.45);

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerWidth * 0.45);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Link to={`/content/${data.id}`} style={style}>
      <Item img={data.bg_img} height={height}>
        <Dark>
          <div>
            <SubTitle>{data.subtitle[lang] ? data.subtitle[lang] : data.subtitle['en']}</SubTitle>
            <Title>{data.title[lang] ? data.title[lang] : data.title['en']}</Title>
          </div>
          <div
            style={{
              color: 'white',
              width: '90%',
              margin: '0 5%',
              marginBottom: '16px',
              fontSize: '13px',
              lineHeight: 1.23,
              textAlign: 'left'
            }}
          >
            {data.comment[lang] ? data.comment[lang] : data.comment['en']}
          </div>
        </Dark>
      </Item>
    </Link>
  );
};

export default MainContentItem;
