export const th = {
  total: 'จำนวนเงินรวม',
  order: 'สั่งซื้อ',
  nomenu: 'ไม่มีประวัติการสั่งซื้อ (ตะกร้าสินค้า)',
  deliveryfee: 'ค่าจัดส่ง',
  chicken: 'ไก่ทอด',
  korean: 'เกาหลีใต้',
  japanese: 'ญี่ปุ่น',
  dessert: 'ขนม',
  pizza: 'พิซซ่า/แฮมเบอร์เกอร์',
  burger: 'แฮมเบอร์เกอร์',
  chinese: 'ประเทศจีน',
  street: 'ถนน',
  salads: 'สลัด',
  asian: 'เอเชีย',
  midnight: 'มื้อดึก',
  deliverytime: 'เวลาโดยประมาณ',
  minute: 'นาที',
  address: 'ที่อยู่',
  businesshour: 'เวลาทำการ',
  telephone: 'เบอร์โทรศัพท์',
  main: 'เมนูหลัก',
  side: 'เมนูเครื่องเคียง',
  info: 'ข้อมูลรายละเอียด',
  payment: 'ชำระเงิน',
  quantity: 'ปริมาณ',
  addtocart: 'เพิ่มใส่ตะกร้า',
  unavailable: 'ปิดทำการ',
  alipay: 'Alipay',
  paypal: 'PayPal',
  unionpay: 'Union Pay',
  makepayment: 'ชำระเงิน',
  size: 'ขนาด',
  option: 'ตัวเลือก',
  credit: 'บัตรเครดิต',
  myorder: 'รายการสั่งของฉัน',
  mylocation: 'ตำแหน่งของฉัน',
  tracking: 'ตรวจสอบรายละเอียดการสั่งซื้อ',
  orderplaced: 'คำสั่งซื้อเสร็จสมบูรณ์',
  preparing: 'กำลังเตรียมการ',
  delivery: 'จัดส่งสินค้า',
  paymentsuccess: 'ชำระเงินสำเร็จ',
  cancel: 'ยกเลิกการชำระเงิน',
  cancel2: 'การชำระเงินถูกยกเลิก',
  cart1: `มีสินค้าชนิดเดียวกันในตะกร้าสินค้าของคุณแล้ว! คุณต้องการที่จะสั่งซื้อต่อไปหรือไม่?`,
  cart2:
    'สามารถเพิ่มได้เฉพาะรายการสั่งซื้อจากในร้านเดียวกัน คุณต้องการลบการสั่งซื้อเก่าและเพิ่มรายการนี้ลงไปหรือไม่?',
  method: 'โปรดเลือกวิธีการชำระเงิน',
  invalid: 'การเชื่อมต่อผิดพลาด',
  room1: 'กรุณาใส่หมายเลขห้อง',
  room2: 'กรุณาใส่หมายเลขห้องที่ถูกต้อง',
  nearby: 'บริเวณใกล้เคียง',
  home: 'บ้าน',
  pay1:
    'หลังการสั่งซื้อสำเร็จ จะไม่สามารถยกเลิกรายการได้  ยืนยันรายการสั่งซื้อและข้อมูลการชำระเงิน (จำเป็น)',
  minprice: 'จำนวนสั่งซื้อขั้นต่ำ',
  price: 'ราคา',
  exim: `1) การชำระเงินนี้จัดทำโดย "Eximbay" และเรียกเก็บเงินจาก "www.eximbay.com"`,
  exim2: `2) หมายเหตุ: ใบแจ้งสั่งซื้อของคุณจะปรากฏในชื่อ Eximbay.com`,
  original: 'ออริจินัล',
  subtotal: 'ทั้งหมด',
  empty: `ไม่มีรายการในตะกร้าสินค้าของคุณ`,
  undermin: `ยอดสั่งซื้อไม่ถึงจำนวนขั้นต่ำ`,
  countlimit: `ปริมาณการสั่งซื้อสูงสุดคือ 50 ชิ้น`,
  nohistory: 'ไม่มีประวัติการสั่งซื้อ',
  status1: 'รอดำเนินการสั่งซื้อ',
  status2: 'กำลังเตรียมการ',
  status3: 'กำลังจัดส่ง',
  status4: 'จัดส่งแล้ว',
  deleteinfo: `ประวัติการสั่งซื้อของคุณจะถูกลบโดยอัตโนมัติภายใน 72 ชั่วโมงหลังการชำระเงินเสร็จสมบูรณ์ โปรดตรวจสอบรายละเอียดที่อีเมลของคุณ`,
  emailagreement: `ฉันยินยอมให้นำข้อมูลอีเมลนี้ไปใช้เพื่อจุดประสงค์ทางการตลาด (ตัวเลือก)`,
  emailinput: `กรอกอีเมลของคุณหากต้องการรับใบเสร็จ`,
  popup: `หากหน้าต่างการชำระเงินไม่ขึ้น โปรดปิดการใช้งานตัวบล็อกป็อปอัพในเบราว์เซอร์ของคุณ`,
  browser: `หลังชำระเงินแล้ว ต้องกลับไปที่หน้าต่างปัจจุบันเพื่อให้การชำระเงินเสร็จสิ้น`,
  hoteltime: `ขณะนี้เป็นเวลาที่ไม่สามารถจัดส่งได้`,
  openingsoon: `จะเปิดเร็ว ๆ นี้ ...`,
  nowclosed: `ขออภัยตอนนี้เราปิดแล้ว`,
  tourguide: `ไกด์นำเที่ยว`,
  faq: `คำถามที่พบบ่อย`,
  aboutus: `เกี่ยวกับเรา`,
  african: '',
  all: `ร้านอาหารทั้งหมด`
};
