import React, { useContext, useEffect } from 'react';
import Loader from '../../components/Icons/Loader';
import querystring from 'query-string';
import { FirebaseContext } from '../../firebase';
import { useDispatch } from 'react-redux';
import { clearCart } from '../../redux/cart';
import Payment from '../../common/Classes/Payment';
import { fetchDeleteTempData, fetchCompletedData } from '../../redux/order';
import { logger } from '../../common/utils';

/* paypal 결제시 리다이렉트 후 완료 처리 용 */
const PayComplete = props => {
  const data = querystring.parse(props.location.search);
  const { db } = useContext(FirebaseContext);
  const dispatch = useDispatch();

  const loadTempData = orderNumber => {
    return db
      .collection('orders-temp')
      .doc(orderNumber)
      .get()
      .then(doc => {
        return { exists: doc.exists, orderData: doc.data() };
      })
      .catch(err => console.log(err));
  };

  const completeOrder = async () => {
    const result = await loadTempData(data.merchant_uid);
    logger(result);
    if (!result.exists) {
      alert('Invalid order processing path');
      return props.history.push('/');
    }
    dispatch(fetchDeleteTempData(data.merchant_uid));
    dispatch(fetchCompletedData(result.orderData));

    Payment.sendPushMsg(result.orderData);
    dispatch(clearCart());
    props.history.push('/order/my');
  };

  useEffect(() => {
    console.log('접속');
    if (data.imp_success === 'true') {
      completeOrder();
    } else {
      alert('Payment canceled.');
      return props.history.push('/');
    }
  }, []); // eslint-disable-line

  return (
    <>
      <Loader>Please wait...</Loader>
    </>
  );
};

export default PayComplete;
