import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { formatPrice, formatTenPercentCommission } from '../../common/format';
import emptyImg from '../../images/empty.svg';
import Translator from '../../common/Translator/Translator';
import { useWindowResize } from '../../common/hooks';

const Container = styled.div`
  display: flex;
  width: 100%;
  height: ${({ height }) => height * 0.375 + 'px'};
  max-height: 217px;
  ${({ imgLoad }) =>
    !imgLoad &&
    css`
      height: auto;
      max-height: auto;
    `}
  .right {
    display: flex;
    justify-content: space-between;
    margin-top: 5%;
    width: ${({ isPhoto }) => (isPhoto ? `57.5%` : `90%`)};
    margin-left: ${({ isPhoto }) => (isPhoto ? 0 : '5%')};
    margin-bottom: ${({ isPhoto }) => (isPhoto ? 0 : '5%')};
    font-size: 12px;
    max-height: 160px;
    height: ${({ height, isPhoto }) => (isPhoto ? height * 0.275 + 'px' : 'auto')};
    color: #aaaaaa;
  }
  .title {
    font-size: 16px;
    line-height: 1.38;
    color: #000000;
  }
  .price {
    align-self: ${({ isPhoto }) => isPhoto && `flex-end`};
    font-size: 14px;
    color: #000000;
    margin-left: auto;
    white-space: nowrap;
    margin-top: ${({ isPhoto }) => !isPhoto && `2px`};
  }
`;

const Image = styled.div`
  background-image: ${(props) => `url(${props.img})`};
  background-position: center center;
  background-size: cover;
  width: 27.5%;
  max-width: 160px;
  max-height: 160px;
  height: ${({ height }) => height * 0.275 + 'px'};
  margin: 5% 5%;
  border-radius: 4px;
`;

const MenuItem = ({ data, onClick, commission }) => {
  const [imgLoad, setImgLoad] = useState(false);
  const [height, setHeight] = useState(window.innerWidth);
  useWindowResize(() => setHeight(window.innerWidth));

  return (
    <Container onClick={onClick} height={height} imgLoad={imgLoad} isPhoto={data.photo && true}>
      {data.photo && <Image img={imgLoad ? data.photo : emptyImg} height={height} />}
      <img
        style={{ width: 0, height: 0, visibility: 'hidden' }}
        src={data.photo}
        onLoad={() => {
          setImgLoad(true);
        }}
        alt=""
      />
      <div className="right">
        <div>
          {/* 메뉴명 번역 */}
          <div className="title">
            {data.name_id && <Translator id={data.name_id} collection="menudb" />}
          </div>
          {/* 로마자 */}
          {data.name_id && (
            <div>{<Translator id={data.name_id} collection="menudb" type="roman" />}</div>
          )}
          {/* 한국어 */}
          <div>{data.realName}</div>
        </div>
        <div className="price">
          ₩ {formatPrice(commission ? formatTenPercentCommission(data.price) : data.price)}
        </div>
      </div>
    </Container>
  );
};

export default MenuItem;
