import { useEffect } from 'react';

export const useWindowResize = handler => {
  useEffect(() => {
    const handleResize = () => {
      handler();
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handler]);
};
