import { stringReturn } from '../common/Translator/Translator';
import { differenceInHours } from 'date-fns';

const ADD_CART = 'cart/ADD_CART';
const DELETE_CART = 'cart/DELETE_CART';
const CLEAR_CART = 'cart/CLEAR_CART';
const SET_CART = 'cart/SET_CART';

export const addCart = value => ({ type: ADD_CART, payload: value });
export const clearCart = () => ({ type: CLEAR_CART });
export const deleteCart = index => ({ type: DELETE_CART, payload: index });
export const setCart = value => ({ type: SET_CART, payload: value });

const initialState = {
  cart: []
};

const cart = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CART:
      const current = [...state.cart];
      const filtered = current.filter(item => item.id === action.payload.id); // 같은 품목인지
      const filtered2 = current.filter(item => item.store_id === action.payload.store_id); // 같은 가게 인지
      const newCart = { ...action.payload, addedAt: new Date() };

      if (filtered.length > 0) {
        let answer = window.confirm(stringReturn(action.payload.language, 'cart1'));

        if (answer) {
          return {
            ...state,
            cart: state.cart.concat(newCart)
          };
        } else {
          return { ...state };
        }

        // 0개 초과면 같은 가게
      } else if (current.length > 0 && filtered2.length === 0) {
        let answer = window.confirm(stringReturn(action.payload.language, 'cart2'));

        if (answer) {
          return { ...state, cart: [newCart] };
        } else {
          return { ...state };
        }
      }

      return {
        ...state,
        cart: state.cart.concat(newCart)
      };

    case SET_CART:
      return {
        ...state,
        cart:
          action.payload &&
          action.payload.filter(item => differenceInHours(new Date(), item.addedAt) < 24)
      };
    case DELETE_CART:
      return {
        ...state,
        cart: state.cart.filter((_, index) => index !== action.payload)
      };
    case CLEAR_CART:
      return {
        ...state,
        cart: []
      };
    default:
      return state;
  }
};

export default cart;
