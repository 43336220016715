import React from "react";
import styled from "styled-components";

const Container = styled.span`
  border-radius: 12px;
  border: 1px solid #aaaaaa;
  font-size: 12px;
  color: #aaaaaa;
  padding: 0px 8px;
  text-align: center;
  margin-right: 4px;
  max-height: 16px;
  margin-bottom: 4px;
`;

const Tag = ({ name }) => {
  return <Container>{name}</Container>;
};

export default Tag;
