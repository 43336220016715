import React, { useContext, useState, useEffect, useMemo, useCallback } from 'react';
import { en, cn, jp, th } from './locale';
import { useSelector } from 'react-redux';
import { FirebaseContext } from '../../firebase';

const Translator = ({ type, id, collection, name }) => {
  const { lang } = useSelector(state => state.lang, []);
  const { db } = useContext(FirebaseContext);
  const [data, setData] = useState(null);

  const LANGUAGE = useMemo(() => {
    return type === 'roman' ? 'roman' : lang;
  }, [lang, type]);

  const setWordByLocal = useCallback(() => {
    return {
      en: en[id],
      cn: cn[id],
      jp: jp[id],
      th: th[id]
    };
  }, [id]);

  const saveDataToLocalStorage = (data, id) => {
    const locale = JSON.parse(sessionStorage.getItem('locale'));
    const newData = { ...locale, [id]: data };
    sessionStorage.setItem('locale', JSON.stringify(newData));
  };

  const loadDataAtLocalStorage = useCallback(() => {
    const locale = JSON.parse(sessionStorage.getItem('locale'));
    if (locale) return locale[id];
    return null;
  }, [id]);

  const getWordByDb = useCallback(() => {
    if (id && collection && id !== '#N/A') {
      db.collection(collection)
        .doc(id)
        .get()
        .then(doc => {
          saveDataToLocalStorage(doc.data(), id);
          setData(doc.data());
        });
    } else {
      return null;
    }
  }, [db, id, collection]);

  useEffect(() => {
    const localData = loadDataAtLocalStorage();
    if (type === 'local') {
      setData(setWordByLocal());
    } else {
      if (localData) {
        setData(localData);
      } else {
        getWordByDb();
      }
    }
  }, [lang, type, getWordByDb, loadDataAtLocalStorage, setWordByLocal]);

  const result =
    data && data[LANGUAGE] ? data[LANGUAGE] : data && data['en'] ? data['en'] : name || '';

  return <>{type === 'roman' && result !== '' ? `${result}` : result}</>;
};

export default Translator;

export const stringReturn = (language, value) => {
  switch (language) {
    case 'en':
      return en[value];
    case 'cn':
      return cn[value];
    case 'jp':
      return jp[value];
    case 'th':
      return th[value];
    default:
      return en[value];
  }
};
