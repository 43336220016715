import { combineReducers, createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';

import cart from './cart';
import lang from './lang';
import info from './info';
import order from './order';
import rootSaga from './sagas';
import stores from './store';

const rootReducer = combineReducers({
  cart,
  lang,
  info,
  order,
  stores,
});

const persistConfig = {
  key: 'root',
  storage,
};

const enhancedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();

let initialStore;

if (process.env.NODE_ENV === 'development') {
  initialStore = createStore(enhancedReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));
} else {
  initialStore = createStore(enhancedReducer, applyMiddleware(sagaMiddleware));
}
sagaMiddleware.run(rootSaga);
export const persistor = persistStore(initialStore);
export const store = initialStore;
