import React, { useEffect } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import Home from './pages/Home/Home';
import Header from './Layouts/Header';
import Category from './pages/Category/Category';
import Store from './pages/Store/Store';
import StoreInfo from './pages/Store/StoreInfo';
import MenuDetail from './pages/Store/MenuDetail';
import Cart from './pages/Order/Cart';
import Order from './pages/Order/Order';
import SelectOrder from './pages/Order/SelectOrder';
import MyOrder from './pages/Order/MyOrder';
import Contract_en from './Layouts/Contract_en';
import Content from './pages/Home/Content';
import Privacy_en from './Layouts/Privacy_en';
import styled from 'styled-components';
import Faq from './pages/Home/Faq';
import PayComplete from './pages/Order/PayComplete';
import CheckAndComplete from './pages/Order/CheckAndComplete';
import Preview from './pages/Preview/Preview';
import PreviewStoreInfo from './pages/Preview/PreviewStoreInfo';
import PreviewMenuDetail from './pages/Preview/PreviewMenuDetail';
import Test from './Test';
import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from './redux/lang';
import { logger } from './common/utils';

// const impKey =
//   process.env.NODE_ENV === 'production'
//     ? process.env.REACT_APP_IMP_KEY
//     : process.env.REACT_APP_IMP_TEST_KEY;

const impKey = process.env.REACT_APP_IMP_KEY;

const AppContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  background: #f9f9f9;
`;
const AppContent = styled.div`
  width: 100%;
  background: #fff;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.2);
  @media screen and (min-width: 580px) {
    width: 580px;
  }
`;

const App = ({ location }) => {
  const { lang } = useSelector(state => state.lang, []);
  const dispatch = useDispatch();
  const path = location.pathname.split('/')[1];
  window.IMP.init(impKey);

  useEffect(() => {
    const now = Date.now();
    const lastVisited = localStorage.getItem('lastVisited');
    const hour = (now - lastVisited) / 3600000;
    if (24 < hour) {
      localStorage.setItem('persist:root', '{}');
    }
    localStorage.setItem('lastVisited', now);

    /* set default language */
    const systemLanguage = navigator.language.split('-')[1].toLowerCase();
    const availableLanguage = ['cn', 'jp', 'th'];
    logger('systemLanguage:', systemLanguage);
    if (!lang && availableLanguage.includes(systemLanguage)) {
      dispatch(setLanguage(systemLanguage));
    } else if (lang) {
      dispatch(setLanguage(lang));
    } else {
      dispatch(setLanguage('en'));
    }
  }, [dispatch, lang]);

  return (
    <>
      <AppContainer>
        <AppContent>
          {path === 'store' || path === 'order' || path === 'preview' ? null : <Header />}
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/content/:id" component={Content} />
            <Route exact path="/category/:id" component={Category} />
            <Route exact path="/store/:id" component={Store} />
            <Route exact path="/store/info/:id" component={StoreInfo} />
            <Route exact path="/store/menu/:id" component={MenuDetail} />

            {/* 주문 */}
            <Route exact path="/order/cart" component={Cart} />
            <Route exact path="/order/checkout" component={Order} />
            <Route exact path="/order/complete" component={PayComplete} />
            <Route exact path="/order/complete/:id" component={CheckAndComplete} />
            <Route exact path="/order/my" component={SelectOrder} />
            <Route exact path="/order/my/:id" component={MyOrder} />
            <Route exact path="/order/faq" component={Faq} />

            {/* 약관 */}
            <Route exact path="/contract/use" component={Contract_en} />
            <Route exact path="/contract/privacy" component={Privacy_en} />

            {/* 메뉴미리보기 */}
            <Route exact path="/preview/:id" component={Preview} />
            <Route exact path="/preview/info/:id" component={PreviewStoreInfo} />
            <Route exact path="/preview/menu/:id" component={PreviewMenuDetail} />
            <Route exact path="/test" component={Test} />

            <Route render={() => <div>404 Not Found</div>} />
          </Switch>
        </AppContent>
      </AppContainer>
    </>
  );
};

export default withRouter(App);
