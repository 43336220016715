import { takeEvery, put, call, all, select } from 'redux-saga/effects';
import firebase from '../firebase';
import * as geofirex from 'geofirex';

const { db, app } = firebase;
const geo = geofirex.init(app);

/* types */
const LOAD_STORE = 'store/LOAD_STORE';
const SET_STORE = 'store/SET_STORE';
const CLEAR_STORE = 'store/CLEAR_STORE';

/* actions */
export const setStore = (value) => ({ type: SET_STORE, payload: value });
export const loadStore = (code) => ({ type: LOAD_STORE, code });
export const clearStore = () => ({ type: CLEAR_STORE });

/* sagas */
const loadStoreSaga = function* () {
  const { position, blackList, whiteList } = yield select((state) => state.info.info);
  const { geopoint } = position;
  const center = geo.point(geopoint._lat, geopoint._long);
  const radius = 2; // 거리 반경 (km)
  const field = 'position';
  const stores = geo.collection('stores');
  const query = stores.within(center, radius, field);

  const getWhiteStore = (id) => {
    const doc = db.collection('stores').doc(id);
    return call([doc, doc.get]);
  };

  const [...whiteListData] = yield all([...whiteList.map((id) => getWhiteStore(id))]);
  const result = yield call(geofirex.get, query);

  const lastData = result
    .filter(
      (item) =>
        (item.status === '등록' || item.status === '번역완료') &&
        (!blackList || !blackList.includes(item.id))
    )
    .concat(whiteListData.map((doc) => ({ id: doc.id, ...doc.data() })));
  yield put(setStore(lastData));
};

export const storeSaga = function* () {
  yield all([takeEvery(LOAD_STORE, loadStoreSaga)]);
};

/* redux */
const initialState = {
  info: null,
};

const store = (state = initialState, action) => {
  switch (action.type) {
    case SET_STORE:
      return {
        ...state,
        stores: action.payload,
      };
    case CLEAR_STORE:
      return initialState;
    default:
      return state;
  }
};

export default store;
