import React, { useEffect, useContext, useState, useMemo } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FirebaseContext } from '../../firebase';
import animateScrollTo from 'animated-scroll-to';
import CategorySwipe from '../../components/Swipe/CategorySwipe';
import StoreItem from '../../components/Menu/StoreItem';
import Translator from '../../common/Translator/Translator';
import Loader from '../../components/Icons/Loader';
import { orderBy, sortBy } from 'lodash';
import { Swipeable } from 'react-swipeable';
import * as geofirex from 'geofirex';
import { logger } from '../../common/utils';
import { categoryList } from '../Home/Home';

const Category = (props) => {
  const { db, app } = useContext(FirebaseContext);
  const { id } = props.match.params;
  const [cateIndex, setCateIndex] = useState(parseInt(id));
  const [data, setData] = useState(null);
  const { info } = useSelector((state) => state.info, []);
  const { lang } = useSelector((state) => state.lang, []);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [isOn, setIsOn] = useState(true);

  // const categories = [ // 왜 8개로 제한?
  //   { name: 'All', key: 'all' }, // category 0
  //   { name: 'Korean', key: 'korean', id: ['6kMrkmZarK5TFTGY65qF'] }, // category 1 // shuttle: Korean
  //   { name: 'Chicken', key: 'chicken', id: ['8CW013YW1LNHTxO1sb6J'] }, // category 2 // shuttle: Chicken
  //   { name: 'Chinese', key: 'chinese', id: ['cOi7Krdq2Gju5zoayLyL'] }, // category 3 // shuttle: Chinese
  //   { name: 'Japanese', key: 'japanese', id: ['5794zbsABH9roo3mtxZ5'] }, // category 4 // shuttle: Japanese
  //   { name: 'Italian/Pizza/Burger', key: 'pizza', id: ['pInMwb3XIa1wBiZSoqts', 'OAE9uKqrKagNUkmEMYj5'] }, // category 5 // shuttle: Italian & Pizza
  //   { name: 'Asian', key: 'asian', id: ['opGv38CWW50jB83PUtIK'] }, // category 6 // shuttle: Asian
  //   { name: 'Salads', key: 'salads', id: ['pZGFaAQrYu06QMSySWIc'] }, // category 7 // shuttle: Veg & Health
  //   { name: 'Dessert', key: 'dessert', id: ['vf6RrvlClS2beDWtdadf'] }, // // shuttle: Desserts
  // { name: 'American/Grill', key: 'america', id: ['9krC3lmteDxKWhaJ9dqI'] }, // // shuttle: American & Grill
  //   { name: 'African', key: 'african', id: ['b0RqdIDU0Iuh7zlxDHvT'] }, // // shuttle: African
  // { name: 'Mexican', key: 'mexican', id: ['hH6f2kSIz8xnZlLjW0bj'] }, // shuttle: Mexican
  // { name: 'Arabic/Turkish', key: 'arabic', id: ['stBSajpBek2fYrZWZK8y'] }, // shuttle: Arabic & Turkish
  // { name: 'Indian', key: 'indian', id: ['wv8538609ueVvet37MTQ'] }, // shuttle: Indian
  // { name: 'European', key: 'european', id: ['Yasgr3NnpYXwqxIwbY2o'] }, // shuttle: European
  // { name: 'Meal Box', key: 'meal_box', id: ['P9fMoDFg06XwNLQ8UGs6'] }, // shuttle: Meal Box
  // { name: 'Grocery', key: 'grocery', id: ['O618DGa9w478fIJpw9Ww'] } // shuttle: Grocery
  // ];

  useEffect(() => {
    setIsFirstRender(false);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!info || !info.active) {
      return props.history.push('/');
    }
    const geo = geofirex.init(app);
    const { geopoint } = info.position;
    const { blackList, whiteList } = info;
    const center = geo.point(geopoint._lat, geopoint._long);
    const radius = 2; // 거리 반경 (km)
    const field = 'position';

    const loadWhiteListData = (id) => {
      if (id)
        return db
          .collection('stores')
          .doc(id)
          .get()
          .then((doc) => ({ id: doc.id, ...doc.data() }));
    };

    const loadData = async () => {
      const promises = whiteList && whiteList.filter((id) => id !== '').map((id) => loadWhiteListData(id));
      const whiteListData = promises ? await Promise.all(promises) : [];
      const stores = geo.collection('stores');
      const query = stores.within(center, radius, field);

      isOn &&
        query.subscribe((data) => {
          const newData = data
            .filter(
              (item) =>
                (item.status === '등록' || item.status === '번역완료') && (!blackList || !blackList.includes(item.id))
            )
            .concat([...whiteListData]);
          logger('loaded result:', newData);
          setData(newData);
        });
    };
    loadData();
    return () => {
      setIsOn(false);
    };
  }, [db, info, props.history, lang, app, isOn]);

  const filteredCategories = useMemo(() => {
    return orderBy(categoryList, 'orderIndex', 'asc').filter(
      (_, index) => info && info.category && info.category.includes(index)
    );
    //return orderBy(categoryList, 'orderIndex', 'asc').filter((_, index) => info && info.category && info.category.includes(index)).concat( { name: "African", key: "african", id: ["b0RqdIDU0Iuh7zlxDHvT"] });
  }, [info]);

  const exceptedCategories = useMemo(() => {
    return (
      orderBy(categoryList, 'orderIndex', 'asc')
        .filter((_, index) => info && info.category && !info.category.includes(index))
        //.concat( { name: "African", key: "african", id: ["b0RqdIDU0Iuh7zlxDHvT"] })
        .map((item) => item.id)
        .flat()
    );
  }, [info]);

  useEffect(() => {
    if (!isFirstRender) {
      const el = document.getElementById(`cate-${filteredCategories[cateIndex === 0 ? 0 : cateIndex - 1].key}`);
      // el = document.getElementById(
      //`cate-${filteredCategories[cateIndex === 0 ? 0 : 1]}`
      //);
      const catePos = el.offsetLeft;
      const cateRef = document.getElementById('category-scroll');
      animateScrollTo(catePos, {
        horizontal: true,
        element: cateRef,
        speed: 50,
      });
    }
  }, [cateIndex, filteredCategories, isFirstRender]);

  useEffect(() => {
    window.history.replaceState({ data: `/category/${cateIndex}` }, 'push', `/category/${cateIndex}`);
  }, [cateIndex]);

  const filteredData = useMemo(() => {
    // 메뉴
    if (!data) return;

    if (cateIndex === 1) {
      // 전체
      let excepted = [];
      // 이 루프 부분 수정
      data.forEach((item) => {
        if (!item.tags) return;
        const isIncludes = exceptedCategories?.map((id) => item.tags.includes(id));
        if (isIncludes.includes(false) || isIncludes.length === 0) excepted.push(item);
      });
      return sortBy(excepted, ['active']);
    } else {
      // 각 카테고리별
      let filtered = [];
      data.forEach((item) => {
        if (!item.tags) return null;
        const isIncludes =
          filteredCategories[cateIndex] && filteredCategories[cateIndex].id.map((id) => item.tags.includes(id));
        if (isIncludes.includes(true)) filtered.push(item);
      });

      return sortBy(filtered, ['active']);
    }
  }, [cateIndex, data, filteredCategories, exceptedCategories]);

  const moveToRight = () => {
    filteredCategories.length - 1 > cateIndex && setCateIndex(cateIndex + 1);
  };

  const moveToLeft = () => {
    cateIndex > 0 && setCateIndex(cateIndex - 1);
  };

  return (
    <>
      <CategorySwipe>
        {[...filteredCategories].map((category) => (
          <span
            style={{
              padding: '0 1.2rem',
              display: 'flex',
              alignItems: 'center',
              height: '100%',
            }}
            active={category.adminIndex === cateIndex ? 'true' : 'false'}
            onClick={() => {
              setCateIndex(category.adminIndex);
            }}
            id={`cate-${category.key}`}
            key={category.adminIndex}
          >
            <Translator id={category.key} type='local' />
          </span>
        ))}
      </CategorySwipe>
      <Swipeable
        onSwipedLeft={() => moveToRight()}
        onSwipedRight={() => moveToLeft()}
        preventDefaultTouchmoveEvent={true}
        trackMouse={true}
      >
        {data ? (
          filteredData.reverse().map((store, index) => (
            <Link to={`/store/${store.id}`} key={index}>
              <StoreItem img={store.photo} data={store} />
            </Link>
          ))
        ) : (
          <Loader />
        )}
        {data && filteredData.length < 3 && <div style={{ width: '100%', minHeight: '60vh' }}></div>}
      </Swipeable>
    </>
  );
};

export default withRouter(Category);
