import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import Tag from '../../components/Tag/Tag';
import RadioGroup from '../../components/Options/RadioGroup';
import OptionGroup from '../../components/Options/OptionGroup';
import { formatPrice } from '../../common/format';
import { FirebaseContext } from '../../firebase';
import Translator from '../../common/Translator/Translator';
import Loader from '../../components/Icons/Loader';
import { sortBy } from 'lodash';
import EmptyHeader from './PreviewHeader';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #aaaaaa;
  font-size: 12px;
  min-height: 100vh;
  .head-box {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 5%;
    text-align: left;
    border-bottom: solid 1px #e6e6e6;
    padding-bottom: 8px;
    .title {
      font-size: 22px;
      line-height: 1.09;
      color: #000000;
    }
    .sub-title {
      margin: 4px 0 8px 0;
      font-size: 12px;
      line-height: 1.33;
      color: #aaaaaa;
    }
    .tags {
      display: flex;
      flex-flow: row wrap;
    }
  }
  .option {
    width: 90%;
    margin: 0 5%;
    height: 48px;
    border-bottom: solid 1px #e6e6e6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    color: black;
    line-height: 1.5;
  }
  .min {
    width: 90%;
    margin: 0 5%;
    margin-bottom: 4rem;
    text-align: center;
    padding-top: 8px;
  }
  .bold {
    font-weight: bold;
  }
`;

const Image = styled.div`
  background-image: ${props => `url(${props.img})`};
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: ${window.innerWidth * 0.9 * 0.625 + 'px'};
  max-height: 326px;
  margin: 5% 0 2.5% 0;
  border-radius: 4px;
`;

const PreviewMenuDetail = props => {
  const [data, setData] = useState(null);
  const [store, setStore] = useState(null);
  const [optionData, setOptionData] = useState(null);
  const [options, setOptions] = useState([]);
  const { id } = props.match.params;
  const { db } = useContext(FirebaseContext);

  useEffect(() => {
    db.collection('menues')
      .doc(id)
      .get()
      .then(doc => {
        setData({ ...doc.data(), id: doc.id });
        db.collection('stores')
          .doc(doc.data().store_id)
          .get()
          .then(storeDoc => setStore({ ...storeDoc.data(), id: storeDoc.id }));
      });

    db.collection('options')
      .where('menu_id', '==', id)
      .get()
      .then(snapshots => {
        let result = [];
        snapshots.forEach(doc => result.push(doc.data()));
        setOptionData(result);
        setDeafultOption(result);
      });

    const setDeafultOption = data => {
      const defaultOptions = [];
      data
        .filter(group => group.radio === true)
        .forEach(group =>
          group.list.forEach(option => {
            if (option.default) {
              defaultOptions.push(option);
            }
          })
        );
      if (defaultOptions.length > 0) {
        setOptions(options => options.concat(defaultOptions));
      }
    };

    // setDefaultOption
  }, [db, id]);

  const addOption = (data, type, prevData) => {
    if (type === 'add') {
      setOptions(options.concat(data));
    } else if (type === 'delete') {
      deleteOption(data);
    } else if (type === 'radio') {
      setOptions(options.filter(option => option.name !== prevData.name).concat(data));
    }
  };

  const deleteOption = data => {
    setOptions(options.filter(option => option.name !== data.name));
  };

  return (
    <Container>
      <EmptyHeader
        store={store && <Translator id={store.name_id} collection="words" />}
        id={data && data.store_id}
      />
      {store ? (
        <>
          <div className="head-box">
            {data.photo ? (
              <Image img={data.photo} />
            ) : (
              <div style={{ width: '100%', height: '1rem' }} />
            )}
            <div className="title bold">
              <Translator id={data.name_id} collection="menudb" />
            </div>

            <div className="sub-title">
              <div>{<Translator id={data.name_id} collection="menudb" type="roman" />}</div>
              {data.realName}
            </div>
            <div className="tags">
              {data.tags &&
                data.tags.map(item => (
                  <Tag name={<Translator id={item} collection="tags" />} key={item} />
                ))}
            </div>
          </div>
          <div className="option">
            <div className="bold">
              <Translator id="original" type="local" />
            </div>
            <div>₩{formatPrice(data.price)}</div>
          </div>
          {optionData &&
            sortBy(optionData, 'index')
              .filter(group => group.radio === true)
              .map((group, index) => (
                <React.Fragment key={`group-1-${index}`}>
                  <div className="option">
                    <div className="bold">
                      {group.groupName_id ? (
                        <Translator id={group.groupName_id} collection="words" />
                      ) : (
                        group.groupName
                      )}
                    </div>
                  </div>
                  <RadioGroup data={group.list} onChange={addOption} />
                </React.Fragment>
              ))}
          {optionData &&
            sortBy(optionData, 'index')
              .filter(group => !group.radio)
              .map((group, index) => (
                <React.Fragment key={`group-${index}`}>
                  <div className="option">
                    <div className="bold">
                      {group.groupName_id ? (
                        <Translator id={group.groupName_id} collection="words" />
                      ) : (
                        group.groupName
                      )}
                    </div>
                  </div>
                  <OptionGroup data={group.list} onClick={addOption} />
                </React.Fragment>
              ))}
        </>
      ) : (
        <Loader />
      )}
    </Container>
  );
};

export default PreviewMenuDetail;
