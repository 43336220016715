import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import Tag from '../Tag/Tag';
import Translator from '../../common/Translator/Translator';
import notOpenImg from '../../images/guide_thumb.svg';
import { useWindowResize } from '../../common/hooks';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  margin-top: 16px;
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 8px;
  width: 90%;
  margin: 0 5%;
  margin-top: 1rem;
  color: ${props => (props.isOpen ? 'black' : '#aaa')};
  .flex {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 8px;
  }
  .title {
    font-size: 22px;
  }
  .sub {
    font-size: 13px;
    color: #aaaaaa;
  }
  .tags {
    display: flex;
    width: 100%;
    margin-top: 0.5rem;
  }
`;

const Image = styled.div`
  background-image: ${props => `url(${props.img})`};
  background-color: ${props => !props.img && '#e6e6e6'};
  background-position: center center;
  background-size: cover;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  width: 100%;
  height: ${props => props.width * 0.625 + 'px'};
  max-width: 560px;
  max-height: 350px;
  font-size: 18px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  ${({ imgLoad }) =>
    !imgLoad &&
    css`
      background: #e6e6e6;
    `}
`;

const StoreItem = ({ data }) => {
  const [imgLoad, setImgLoad] = useState(false);
  const [width, setWidth] = useState(window.innerWidth * 0.9);
  useWindowResize(() => setWidth(window.innerWidth * 0.9));

  return (
    <Container isOpen={data.active}>
      <img
        style={{ width: 0, height: 0, display: 'none' }}
        src={data && data.photo}
        onLoad={() => setImgLoad(true)}
        alt=""
      />
      {data.active ? (
        data.photo ? (
          <Image img={data.photo} width={width} imgLoad={imgLoad} />
        ) : (
          <Image width={width}>
            <Translator id={data.name_id} collection="words" />
          </Image>
        )
      ) : (
        <Image img={notOpenImg} width={width} imgLoad={true}>
          <Translator id="openingsoon" type="local" />
        </Image>
      )}

      <div className="flex">
        <div className="title">
          {data.name_id && <Translator id={data.name_id} collection="words" />}
        </div>
        <div className="sub"> {data.realName}</div>
      </div>
      <div className="tags">
        {data.tags.map((id, index) => (
          <Tag name={<Translator id={id} collection="tags" />} key={index} />
        ))}
      </div>
    </Container>
  );
};

export default StoreItem;
