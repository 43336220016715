import React, { useMemo, useEffect } from 'react';
import styled from 'styled-components';
import downIcon from '../images/down_navy.svg';

const StyledSelect = styled.select`
  box-shadow: none;
  outline: none;
  border: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: 16px;
  font-weight: bold;
  color: #55607d;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 5%;
  border-radius: 24px;
  padding: 15px 5%;
  background: url(${downIcon}) no-repeat 95% 45%;
  background-color: #f8f8f8;
  margin-bottom: 8px;
  &::-ms-expand {
    display: none;
  }
`;

export default function CountryList({ lang, setData }) {
  const initialCountry = useMemo(() => {
    switch (lang) {
      default:
        return 'China (+86)';
      case 'jp':
        return 'Japan (+81)';
      case 'en':
        return 'United States (+1)';
      case 'th':
        return 'Thailand (+66)';
    }
  }, [lang]);

  useEffect(() => {
    const code = initialCountry
      .split('+')[1]
      .replace(/\)/gi, '')
      .replace(/(\s*)/g, '');

    setData(data => ({ ...data, countryCode: code }));
  }, []); // eslint-disable-line

  return (
    <StyledSelect
      onChange={e => {
        const code = e.target.value
          .split('+')[1]
          .replace(/\)/gi, '')
          .replace(/(\s*)/g, '');
        setData(data => ({ ...data, countryCode: code }));
      }}
      defaultValue={initialCountry}
    >
      <option>Algeria (+213)</option>
      <option>Argentina (+54)</option>
      <option>Australia (+61)</option>
      <option>Austria (+43)</option>
      <option>Bahamas (+1 242)</option>
      <option>Bahrain (+973)</option>
      <option>Belarus (+375)</option>
      <option>Belgium (+32)</option>
      <option>Benin (+229)</option>
      <option>Bolivia (+591)</option>
      <option>Botswana (+267)</option>
      <option>Brazil (+55)</option>
      <option>Bulgaria (+359)</option>
      <option>Canada (+1)</option>
      <option>Cambodia (+855)</option>
      <option>Cayman Islands (+1 345)</option>
      <option>Chile (+56)</option>
      <option>China (+86)</option>
      <option>Colombia (+57)</option>
      <option>Costa Rica (+506)</option>
      <option>Croatia (+385)</option>
      <option>Cyprus (+357)</option>
      <option>Czech Republic (+420)</option>
      <option>Denmark (+45)</option>
      <option>Dominican Republic (+18)</option>
      <option>Ecuador (+593)</option>
      <option>Egypt (+20)</option>
      <option>El Salvador (+503)</option>
      <option>Estonia (+372)</option>
      <option>Finland (+358)</option>
      <option>France (+33)</option>
      <option>Georgia (+995)</option>
      <option>Germany (+49)</option>
      <option>Ghana (+233)</option>
      <option>Greece (+30)</option>
      <option>Grenada (+1 473)</option>
      <option>Guatemala (+502)</option>
      <option>Hong Kong (+852)</option>
      <option>Honduras (+504)</option>
      <option>Hungary (+36)</option>
      <option>Indonesia (+62)</option>
      <option>Iceland (+354)</option>
      <option>India (+91)</option>
      <option>Ireland (+353)</option>
      <option>Israel (+972)</option>
      <option>Italy (+39)</option>
      <option>Jamaica (+1 876)</option>
      <option>Japan (+81)</option>
      <option>Kazakhstan (+7)</option>
      <option>Kenya (+254)</option>
      <option>Latvia (+371)</option>
      <option>Liechtenstein (+423)</option>
      <option>Lithuania (+370)</option>
      <option>Luxembourg (+352)</option>
      <option>Macau (+853)</option>
      <option>Malaysia (+60)</option>
      <option>Malta (+356)</option>
      <option>Mauritius (+230)</option>
      <option>Mexico (+52)</option>
      <option>Moldova (+373)</option>
      <option>Netherlands (+31)</option>
      <option>New Zealand (+64)</option>
      <option>Nigeria (+234)</option>
      <option>Norway (+47)</option>
      <option>Pakistan (+92)</option>
      <option>Panama (+507)</option>
      <option>Peru (+51)</option>
      <option>Philippines (+63)</option>
      <option>Puerto Rico (+1)</option>
      <option>Poland (+48)</option>
      <option>Portugal (+351)</option>
      <option>Republic of Korea (+82)</option>
      <option>Romania (+40)</option>
      <option>Russia (+7)</option>
      <option>Rwanda (+250)</option>
      <option>Saudi Arabia (+966)</option>
      <option>Serbia (+381)</option>
      <option>Singapore (+65)</option>
      <option>Slovakia (+421)</option>
      <option>Slovenia (+386)</option>
      <option>South Africa (+27)</option>
      <option>Spain (+34)</option>
      <option>Sri Lanka (+94)</option>
      <option>Sweden (+46)</option>
      <option>Switzerland (+41)</option>
      <option>Tajikistan (+992)</option>
      <option>Taiwan (+886)</option>
      <option>Thailand (+66)</option>
      <option>Trinidad and Tobago (+1 868)</option>
      <option>Turkey (+90)</option>
      <option>Ukraine (+380)</option>
      <option>United Arab Emirates (+971)</option>
      <option>United Kingdom (+44)</option>
      <option>United States (+1)</option>
      <option>Uruguay (+598)</option>
      <option>Uzbekistan (+998)</option>
      <option>Venezuela (+58)</option>
      <option>Vietnam (+84)</option>
      <option>Zambia (+260)</option>
    </StyledSelect>
  );
}
