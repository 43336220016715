import React, { useState } from 'react';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import { shuffle } from 'lodash';
import MainContentItem from '../../pages/Home/MainContentItem';

const ManualSlider = ({ contents }) => {
  const [initialContents] = useState(shuffle(contents));
  const params = {
    scrollbar: {
      el: '.swiper-scrollbar',
      hide: true
    },
    loop: true
  };
  return (
    <Swiper {...params}>
      {contents &&
        initialContents.map((item, key) => (
          <div key={key}>
            <MainContentItem data={item} />
          </div>
        ))}
    </Swiper>
  );
};

export default ManualSlider;
