import React, { useEffect } from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 95%;
  margin: 0 2.5%;
  a {
    color: blue;
  }
`;

const Contract = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <h4 id="termsofuse">
        <strong>Terms of Use</strong>
      </h4>

      <p>Effective: October 15, 2019</p>

      <h4 id="1contractualrelationship">
        <strong>1. Contractual Relationship</strong>
      </h4>

      <p>
        These Terms of Use (&quot;Terms&quot;) govern your access or use, from
        within the Republic of Korea and its territories and possessions, of the
        applications, websites, content, products, and services (the
        &quot;Services,&quot; as more fully defined below in Section 3) made
        available in the Republic of Korea and its territories and possessions
        by PUBLICS CORP. and its parents, subsidiaries, representatives,
        affiliates, officers and directors (collectively,
        &quot;DeliQuick&quot;). PLEASE READ THESE TERMS CAREFULLY, AS THEY
        CONSTITUTE A LEGAL AGREEMENT BETWEEN YOU AND DELIQUICK. In these Terms,
        the words &quot;including&quot; and &quot;include&quot; mean
        &quot;including, but not limited to.&quot;
      </p>

      <p>
        By accessing or using the Services, you confirm your agreement to be
        bound by these Terms. If you do not agree to these Terms, you may not
        access or use the Services. These Terms expressly supersede prior
        agreements or arrangements with you. DeliQuick may immediately terminate
        these Terms or any Services with respect to you, or generally cease
        offering or deny access to the Services or any portion thereof, at any
        time for any reason.
      </p>

      <p>
        <strong>
          IMPORTANT: PLEASE REVIEW THE ARBITRATION AGREEMENT SET FORTH BELOW
          CAREFULLY, AS IT WILL REQUIRE YOU TO RESOLVE DISPUTES WITH DELIQUICK
          ON AN INDIVIDUAL BASIS THROUGH FINAL AND BINDING ARBITRATION. BY
          ENTERING THIS AGREEMENT, YOU EXPRESSLY ACKNOWLEDGE THAT YOU HAVE READ
          AND UNDERSTAND ALL OF THE TERMS OF THIS AGREEMENT AND HAVE TAKEN TIME
          TO CONSIDER THE CONSEQUENCES OF THIS IMPORTANT DECISION.
        </strong>
      </p>

      <p>
        Supplemental terms may apply to certain Services, such as policies for a
        particular event, program, activity or promotion, and such supplemental
        terms will be disclosed to you in separate region-specific disclosures
        (e.g., a particular webpage on app.deliquick.co.kr) or in connection
        with the applicable Service(s). Supplemental terms are in addition to,
        and shall be deemed a part of, the Terms for the purposes of the
        applicable Service(s). Supplemental terms shall prevail over these Terms
        in the event of a conflict with respect to the applicable Services.
      </p>

      <p>
        DeliQuick may amend the Terms from time to time. Amendments will be
        effective upon DeliQuick&#39;s posting of such updated Terms at this
        location or in the amended policies or supplemental terms on the
        applicable Service(s). Your continued access or use of the Services
        after such posting confirms your consent to be bound by the Terms, as
        amended. If DeliQuick changes these Terms after the date you first
        agreed to the Terms (or to any subsequent changes to these Terms), you
        may reject any such change by providing DeliQuick written notice of such
        rejection within 30 days of the date such change became effective, as
        indicated in the &quot;Effective&quot; date above. This written notice
        must be provided either (a) by mail or hand delivery to our registered
        agent for service of process, c/o PUBLICS CORP. (the name and current
        contact information for the registered agent in each state are available
        online <a href="https://ct.wolterskluwer.com/sop-locations">here</a>),
        or (b) by email from the email address associated with your Account to:
        help@publics.co.kr. In order to be effective, the notice must include
        your full name and clearly indicate your intent to reject changes to
        these Terms. By rejecting changes, you are agreeing that you will
        continue to be bound by the provisions of these Terms as of the date you
        first agreed to the Terms (or to any subsequent changes to these Terms).
      </p>

      <p>
        DeliQuick&#39;s collection and use of personal information in connection
        with the Services is described in DeliQuick&#39;s Privacy Statements
        located at{" "}
        <a href="https://app.deliquick.co.kr/contract/privacy">here</a>.
      </p>

      <h4 id="2arbitrationagreement">
        <strong>2. Arbitration Agreement</strong>
      </h4>

      <p>
        <strong>
          By agreeing to the Terms, you agree that you are required to resolve
          any claim that you may have against DeliQuick on an individual basis
          in arbitration, as set forth in this Arbitration Agreement. This will
          preclude you from bringing any class, collective, or representative
          action against DeliQuick, and also preclude you from participating in
          or recovering relief under any current or future class, collective,
          consolidated, or representative action brought against DeliQuick by
          someone else.
        </strong>
      </p>

      <h5 id="agreementtobindingarbitrationbetweenyouanddeliquick">
        <strong>Agreement to Binding Arbitration Between You and</strong>{" "}
        DeliQuick <strong>.</strong>
      </h5>

      <p>
        You and DeliQuick agree that any dispute, claim or controversy arising
        out of or relating to (a) these Terms or the existence, breach,
        termination, enforcement, interpretation or validity thereof, or (b)
        your access to or use of the Services at any time, whether before or
        after the date you agreed to the Terms, will be settled by binding
        arbitration between you and DeliQuick, and not in a court of law.
      </p>

      <p>
        You acknowledge and agree that you and DeliQuick are each waiving the
        right to a trial by jury or to participate as a plaintiff or class
        member in any purported class action or representative proceeding.
        Unless both you and DeliQuick otherwise agree in writing, any
        arbitration will be conducted only on an individual basis and not in a
        class, collective, consolidated, or representative proceeding. However,
        you and DeliQuick each retain the right to bring an individual action in
        small claims court and the right to seek injunctive or other equitable
        relief in a court of competent jurisdiction to prevent the actual or
        threatened infringement, misappropriation or violation of a party&#39;s
        copyrights, trademarks, trade secrets, patents or other intellectual
        property rights.
      </p>

      <h4 id="3theservices">
        <strong>3. The Services</strong>
      </h4>

      <p>
        The Services comprise mobile web(applications) and related services
        (each, an &quot;Application&quot;), which enable users to arrange and
        schedule transportation, logistics and/or delivery services and/or to
        purchase certain goods, including with third party providers of such
        services and goods under agreement with DeliQuick or certain of
        DeliQuick&#39;s affiliates (&quot;Third Party Providers&quot;). In
        certain instances the Services may also include an option to receive
        transportation, logistics and/or delivery services for an upfront price,
        subject to acceptance by the respective Third Party Providers. Unless
        otherwise agreed by DeliQuick in a separate written agreement with you,
        the Services are made available solely for your personal, noncommercial
        use. YOU ACKNOWLEDGE THAT YOUR ABILITY TO OBTAIN TRANSPORTATION,
        LOGISTICS AND/OR DELIVERY SERVICES THROUGH THE USE OF THE SERVICES DOES
        NOT ESTABLISH DELIQUICK AS A PROVIDER OF TRANSPORTATION, LOGISTICS OR
        DELIVERY SERVICES OR AS A TRANSPORTATION CARRIER.
      </p>

      <h5 id="license">
        <strong>License.</strong>
      </h5>

      <p>
        Subject to your compliance with these Terms, DeliQuick grants you a
        limited, non-exclusive, non-sublicensable, revocable, non-transferable
        license to: (i) access and use the Applications on your personal device
        solely in connection with your use of the Services; and (ii) access and
        use any content, information and related materials that may be made
        available through the Services, in each case solely for your personal,
        noncommercial use. Any rights not expressly granted herein are reserved
        by DeliQuick and DeliQuick&#39;s licensors.
      </p>

      <h5 id="restrictions">
        <strong>Restrictions.</strong>
      </h5>

      <p>
        You may not: (i) remove any copyright, trademark or other proprietary
        notices from any portion of the Services; (ii) reproduce, modify,
        prepare derivative works based upon, distribute, license, lease, sell,
        resell, transfer, publicly display, publicly perform, transmit, stream,
        broadcast or otherwise exploit the Services except as expressly
        permitted by DeliQuick; (iii) decompile, reverse engineer or disassemble
        the Services except as may be permitted by applicable law; (iv) link to,
        mirror or frame any portion of the Services; (v) cause or launch any
        programs or scripts for the purpose of scraping, indexing, surveying, or
        otherwise data mining any portion of the Services or unduly burdening or
        hindering the operation and/or functionality of any aspect of the
        Services; or (vi) attempt to gain unauthorized access to or impair any
        aspect of the Services or its related systems or networks.
      </p>

      <h5 id="provisionoftheservices">
        <strong>Provision of the Services.</strong>
      </h5>

      <p>
        You acknowledge that portions of the Services may be made available
        under DeliQuick&#39;s various brands or request options associated with
        transportation or logistics, including the transportation request brands
        currently referred to as &quot;DeliQuick&quot; and the logistics request
        products currently referred to as &quot;,&quot; K-bedal&quot;,
        CokCok114.&quot; You also acknowledge that the Services may be made
        available under such brands or request options by or in connection with:
        (i) certain of DeliQuick&#39;s subsidiaries and affiliates; or (ii)
        independent Third Party Providers, including Transportation Network
        Company drivers, Transportation Charter Permit holders or holders of
        similar transportation permits, authorizations or licenses.
      </p>

      <h5 id="thirdpartyservicesandcontent">
        <strong>Third Party Services and Content.</strong>
      </h5>

      <p>
        The Services may be made available or accessed in connection with third
        party services and content (including advertising) that DeliQuick does
        not control. You acknowledge that different terms of use and privacy
        policies may apply to your use of such third party services and content.
        DeliQuick does not endorse such third party services and content and in
        no event shall DeliQuick be responsible or liable for any products or
        services of such third party providers. Additionally, Apple Inc.,
        Google, Inc., Microsoft Corporation or BlackBerry Limited will be a
        third-party beneficiary to this contract if you access the Services
        using Applications developed for Apple (iOS), Android, Microsoft
        Windows, Google chrome or Blackberry-powered mobile devices,
        respectively. These third party beneficiaries are not parties to this
        contract and are not responsible for the provision or support of the
        Services in any manner. Your access to the Services using these devices
        is subject to terms set forth in the applicable third party
        beneficiary&#39;s terms of service.
      </p>

      <h5 id="ownership">
        <strong>Ownership.</strong>
      </h5>

      <p>
        The Services and all rights therein are and shall remain DeliQuick&#39;s
        property or the property of DeliQuick&#39;s licensors. Neither these
        Terms nor your use of the Services convey or grant to you any rights:
        (i) in or related to the Services except for the limited license granted
        above; or (ii) to use or reference in any manner DeliQuick&#39;s company
        names, logos, product and service names, trademarks or services marks or
        those of DeliQuick&#39;s licensors.
      </p>

      <h4 id="4accessanduseoftheservices">
        <strong>4. Access and Use of the Services</strong>
      </h4>

      <h5 id="useraccounts">
        <strong>User Accounts.</strong>
      </h5>

      <p>
        In order to use most aspects of the Services, you must register for and
        maintain an active personal user Services account (&quot;Account&quot;).
        You must be at least 18 years of age, or the age of legal majority in
        your jurisdiction (if different than 18), to obtain an Account, unless a
        specific Service permits otherwise. Account registration requires you to
        submit to DeliQuick certain personal information, such as your name,
        address, mobile phone number and age, as well as at least one valid
        payment method supported by DeliQuick. You agree to maintain accurate,
        complete, and up-to-date information in your Account. Your failure to
        maintain accurate, complete, and up-to-date Account information,
        including having an invalid or expired payment method on file, may
        result in your inability to access or use the Services. You are
        responsible for all activity that occurs under your Account, and you
        agree to maintain the security and secrecy of your Account username and
        password at all times. Unless otherwise permitted by DeliQuick in
        writing, you may only possess one Account.
      </p>

      <h5 id="userrequirementsandconduct">
        <strong>User Requirements and Conduct.</strong>
      </h5>

      <p>
        The Service is not available for use by persons under the age of 18. You
        may not authorize third parties to use your Account, and you may not
        allow persons under the age of 18 to receive transportation or logistics
        services from Third Party Providers unless they are accompanied by you.
        You may not assign or otherwise transfer your Account to any other
        person or entity. You agree to comply with all applicable laws when
        accessing or using the Services, and you may only access or use the
        Services for lawful purposes (e.g., no transport of unlawful or
        hazardous materials). You may not in your access or use of the Services
        cause nuisance, annoyance, inconvenience, or property damage, whether to
        the Third Party Provider or any other party. In certain instances you
        may be asked to provide proof of identity or other method of identity
        verification to access or use the Services, and you agree that you may
        be denied access to or use of the Services if you refuse to provide
        proof of identity or other method of identity verification.
      </p>

      <h5 id="textmessagingandtelephonecalls">
        <strong>Text Messaging and Telephone Calls.</strong>
      </h5>

      <p>
        You agree that DeliQuick may contact you by telephone or text messages
        (including by an automatic telephone dialing system) at any of the phone
        numbers provided by you or on your behalf in connection with DeliQuick
        account, including for marketing purposes. You understand that you are
        not required to provide this consent as a condition of purchasing any
        property, goods or services. You also understand that you may opt out of
        receiving text messages from DeliQuick at any time, either by calling to
        +82-(0)2-577-0420 using the mobile device that is receiving the
        messages, or by contacting help@publics.co.kr. If you do not choose to
        opt out, DeliQuick may contact you as outlined in its DeliQuick Privacy
        Statement, located at app.deliquick.co.kr/contract/privacy
      </p>

      <h5 id="userprovidedcontent">
        <strong>User Provided Content.</strong>
      </h5>

      <p>
        DeliQuick may, in DeliQuick&#39;s sole discretion, permit you from time
        to time to submit, upload, publish or otherwise make available to
        DeliQuick through the Services textual, audio, and/or visual content and
        information, including commentary and feedback related to the Services,
        initiation of support requests, and submission of entries for
        competitions and promotions (&quot;User Content&quot;). Any User Content
        provided by you remains your property. However, by providing User
        Content to DeliQuick, you grant DeliQuick a worldwide, perpetual,
        irrevocable, transferable, royalty-free license, with the right to
        sublicense, to use, copy, modify, create derivative works of,
        distribute, publicly display, publicly perform, and otherwise exploit in
        any manner such User Content in all formats and distribution channels
        now known or hereafter devised (including in connection with the
        Services and DeliQuick &#39;s business and on third-party sites and
        services), without further notice to or consent from you, and without
        the requirement of payment to you or any other person or entity.
      </p>

      <p>
        You represent and warrant that: (i) you either are the sole and
        exclusive owner of all User Content or you have all rights, licenses,
        consents and releases necessary to grant DeliQuick the license to the
        User Content as set forth above; and (ii) neither the User Content, nor
        your submission, uploading, publishing or otherwise making available of
        such User Content, nor DeliQuick&#39;s use of the User Content as
        permitted herein will infringe, misappropriate or violate a third
        party&#39;s intellectual property or proprietary rights, or rights of
        publicity or privacy, or result in the violation of any applicable law
        or regulation.
      </p>

      <p>
        You agree to not provide User Content that is defamatory, libelous,
        hateful, violent, obscene, pornographic, unlawful, or otherwise
        offensive, as determined by DeliQuick in its sole discretion, whether or
        not such material may be protected by law. DeliQuick may, but shall not
        be obligated to, review, monitor, or remove User Content, at
        DeliQuick&#39;s sole discretion and at any time and for any reason,
        without notice to you.
      </p>

      <h5 id="networkaccessanddevices">
        <strong>Network Access and Devices.</strong>
      </h5>

      <p>
        You are responsible for obtaining the data network access necessary to
        use the Services. Your mobile network&#39;s data and messaging rates and
        fees may apply if you access or use the Services from your device. You
        are responsible for acquiring and updating compatible hardware or
        devices necessary to access and use the Services and Applications and
        any updates thereto. DeliQuick does not guarantee that the Services, or
        any portion thereof, will function on any particular hardware or
        devices. In addition, the Services may be subject to malfunctions and
        delays inherent in the use of the Internet and electronic
        communications.
      </p>

      <h4 id="5payment">
        <strong>{`5. Payment & Refund`}</strong>
      </h4>

      <p>
        You understand that use of the Services may result in charges to you for
        the services or goods you receive (&quot;Charges&quot;). DeliQuick will
        receive and/or enable your payment of the applicable Charges for
        services or goods obtained through your use of the Services. Charges
        will be inclusive of applicable taxes where required by law.
      </p>

      <p>
        All Charges and payments will be enabled by DeliQuick using the
        preferred payment method designated in your Account, after which you
        will receive a receipt by delivered package which enclosed. If your
        primary Account payment method is determined to be expired, invalid or
        otherwise not able to be charged, you agree that DeliQuick may use a
        secondary payment method in your Account, if available. Charges paid by
        you are final and non-refundable, unless otherwise determined by
        DeliQuick.
      </p>

      <p>
        As between you and DeliQuick, DeliQuick reserves the right to establish,
        remove and/or revise Charges for any or all services or goods obtained
        through the use of the Services at any time in DeliQuick&#39;s sole
        discretion. Further, you acknowledge and agree that Charges applicable
        in certain geographical areas may increase substantially during times of
        high demand. DeliQuick will use reasonable efforts to inform you of
        Charges that may apply, provided that you will be responsible for
        Charges incurred under your Account regardless of your awareness of such
        Charges or the amounts thereof. DeliQuick may from time to time provide
        certain users with promotional offers and discounts that may result in
        different amounts charged for the same or similar services or goods
        obtained through the use of the Services, and you agree that such
        promotional offers and discounts, unless also made available to you,
        shall have no bearing on your use of the Services or the Charges applied
        to you. You may elect to cancel your request for Services at any time
        prior to the commencement of such Services, in which case you may be
        charged a cancellation fee on a Third Party Provider&#39;s behalf. After
        you have received services or goods obtained through the Service, you
        will have the opportunity to rate your experience and leave additional
        feedback. DeliQuick may use the proceeds of any Charges for any purpose,
        subject to any payment obligations it has agreed to with any Third Party
        Providers or other third parties.
      </p>

      <p>
        In certain cases, with respect to Third Party Providers, Charges you
        incur will be owed directly to Third Party Providers, and DeliQuick will
        collect payment of those charges from you, on the Third Party
        Provider&#39;s behalf as their limited payment collection agent, and
        payment of the Charges shall be considered the same as payment made
        directly by you to the Third Party Provider. In such cases, you retain
        the right to request lower Charges from a Third Party Provider for
        services or goods received by you from such Third Party Provider at the
        time you receive such services or goods, and Charges you incur will be
        owed to the Third Party Provider. DeliQuick will respond accordingly to
        any request from a Third Party Provider to modify the Charges for a
        particular service or good. This payment structure is intended to fully
        compensate a Third Party Provider, if applicable, for the services or
        goods obtained in connection with your use of the Services. In all other
        cases, Charges you incur will be owed and paid directly to DeliQuick or
        its affiliates, where DeliQuick is solely liable for any obligations to
        Third Party Providers. In such cases, you retain the right to request
        lower Charges from DeliQuick for services or goods received by you from
        a Third Party Provider at the time you receive such services or goods,
        and DeliQuick will respond accordingly to any request from you to modify
        the Charges for a particular service or good. Except with respect to
        taxicab transportation services requested through the Application,
        DeliQuick does not designate any portion of your payment as a tip or
        gratuity to a Third Party Provider. Any representation by DeliQuick(on
        DeliQuick&#39;s website, in the Application, or in DeliQuick&#39;s
        marketing materials) to the effect that tipping is
        &quot;voluntary,&quot; &quot;not required,&quot; and/or
        &quot;included&quot; in the payments you make for services or goods
        provided is not intended to suggest that DeliQuick provides any
        additional amounts, beyond those described above, to a Third Party
        Provider you may use. You understand and agree that, while you are free
        to provide additional payment as a gratuity to any Third Party Provider
        who provides you with services or goods obtained through the Service,
        you are under no obligation to do so. Gratuities are voluntary.
      </p>

      <h5 id="repaircleaningorlostandfoundfees">
        <strong>Repair, Cleaning or Lost and Found Fees.</strong>
      </h5>

      <p>
        You shall be responsible for the cost of repair for damage to, or
        necessary cleaning of, vehicles and property resulting from use of the
        Services under your Account in excess of normal &quot;wear and
        tear&quot; damages and necessary cleaning (&quot;Repair or
        Cleaning&quot;). In the event that a Repair or Cleaning request is
        verified by DeliQuick in DeliQuick&#39;s reasonable discretion,
        DeliQuick reserves the right to facilitate payment for the reasonable
        cost of such Repair or Cleaning using your payment method designated in
        your Account. Such amounts, as well as those pertaining to lost and
        found goods, will be transferred by DeliQuick to a Third Party Provider,
        if applicable, and are non-refundable.
      </p>

      <h4 id="6disclaimerslimitationofliabilityindemnity">
        <strong>6. Disclaimers; Limitation of Liability; Indemnity.</strong>
      </h4>

      <h5 id="disclaimer">
        <strong>DISCLAIMER.</strong>
      </h5>

      <p>
        THE SERVICES ARE PROVIDED &quot;AS IS&quot; AND &quot;AS
        AVAILABLE.&quot; DELIQUICK DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES,
        EXPRESS, IMPLIED, OR STATUTORY, NOT EXPRESSLY SET OUT IN THESE TERMS,
        INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
        PARTICULAR PURPOSE AND NON-INFRINGEMENT. IN ADDITION, DELIQUICK MAKES NO
        REPRESENTATION, WARRANTY, OR GUARANTEE REGARDING THE RELIABILITY,
        TIMELINESS, QUALITY, SUITABILITY, OR AVAILABILITY OF THE SERVICES OR ANY
        SERVICES OR GOODS REQUESTED THROUGH THE USE OF THE SERVICES, OR THAT THE
        SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. DELIQUICK DOES NOT
        GUARANTEE THE QUALITY, SUITABILITY, SAFETY OR ABILITY OF THIRD PARTY
        PROVIDERS. YOU AGREE THAT THE ENTIRE RISK ARISING OUT OF YOUR USE OF THE
        SERVICES, AND ANY SERVICE OR GOOD REQUESTED IN CONNECTION THEREWITH,
        REMAINS SOLELY WITH YOU, TO THE MAXIMUM EXTENT PERMITTED UNDER
        APPLICABLE LAW.
      </p>

      <h5 id="limitationofliability">
        <strong>LIMITATION OF LIABILITY.</strong>
      </h5>

      <p>
        DELIQUICK SHALL NOT BE LIABLE FOR INDIRECT, INCIDENTAL, SPECIAL,
        EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS,
        LOST DATA, PERSONAL INJURY, OR PROPERTY DAMAGE RELATED TO, IN CONNECTION
        WITH, OR OTHERWISE RESULTING FROM ANY USE OF THE SERVICES, REGARDLESS OF
        THE NEGLIGENCE (EITHER ACTIVE, AFFIRMATIVE, SOLE, OR CONCURRENT) OF
        DELIQUICK, EVEN IF DELIQUICK HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
        DAMAGES.
      </p>

      <p>
        DELIQUICK SHALL NOT BE LIABLE FOR ANY DAMAGES, LIABILITY OR LOSSES
        ARISING OUT OF: (i) YOUR USE OF OR RELIANCE ON THE SERVICES OR YOUR
        INABILITY TO ACCESS OR USE THE SERVICES; OR (ii) ANY TRANSACTION OR
        RELATIONSHIP BETWEEN YOU AND ANY THIRD PARTY PROVIDER, EVEN IF DELIQUICK
        HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. DELIQUICK SHALL NOT
        BE LIABLE FOR DELAY OR FAILURE IN PERFORMANCE RESULTING FROM CAUSES
        BEYOND DELIQUICK&#39;S REASONABLE CONTROL. YOU ACKNOWLEDGE THAT THIRD
        PARTY PROVIDERS PROVIDING TRANSPORTATION SERVICES REQUESTED THROUGH SOME
        REQUEST PRODUCTS MAY OFFER RIDESHARING OR PEER-TO-PEER TRANSPORTATION
        SERVICES AND MAY NOT BE PROFESSIONALLY LICENSED OR PERMITTED.
      </p>

      <p>
        THE SERVICES MAY BE USED BY YOU TO REQUEST AND SCHEDULE TRANSPORTATION,
        GOODS, OR LOGISTICS SERVICES WITH THIRD PARTY PROVIDERS, BUT YOU AGREE
        THAT DELIQUICK HAS NO RESPONSIBILITY OR LIABILITY TO YOU RELATED TO ANY
        TRANSPORTATION, GOODS OR LOGISTICS SERVICES PROVIDED TO YOU BY THIRD
        PARTY PROVIDERS OTHER THAN AS EXPRESSLY SET FORTH IN THESE TERMS.
      </p>

      <p>
        THE LIMITATIONS AND DISCLAIMER IN THIS SECTION DO NOT PURPORT TO LIMIT
        LIABILITY OR ALTER YOUR RIGHTS AS A CONSUMER THAT CANNOT BE EXCLUDED
        UNDER APPLICABLE LAW. BECAUSE SOME STATES OR JURISDICTIONS DO NOT ALLOW
        THE EXCLUSION OF OR THE LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR
        INCIDENTAL DAMAGES, IN SUCH STATES OR JURISDICTIONS, DELIQUICK&#39;S
        LIABILITY SHALL BE LIMITED TO THE EXTENT PERMITTED BY LAW. THIS
        PROVISION SHALL HAVE NO EFFECT ON DELIQUICK&#39;S CHOICE OF LAW
        PROVISION SET FORTH BELOW.
      </p>

      <h5 id="indemnity">
        <strong>Indemnity.</strong>
      </h5>

      <p>
        You agree to indemnify and hold DeliQuick and its affiliates and their
        officers, directors, employees, and agents harmless from any and all
        claims, demands, losses, liabilities, and expenses (including
        attorneys&#39; fees), arising out of or in connection with: (i) your use
        of the Services or services or goods obtained through your use of the
        Services; (ii) your breach or violation of any of these Terms; (iii)
        DeliQuick&#39;s use of your User Content; or (iv) your violation of the
        rights of any third party, including Third Party Providers.
      </p>

      <h4 id="7otherprovisions">
        <strong>7. Other Provisions</strong>
      </h4>

      <h5 id="choiceoflaw">
        <strong>Choice of Law.</strong>
      </h5>

      <p>
        These Terms are governed by and construed in accordance with the laws of
        the Republic of Korea., without giving effect to any conflict of law
        principles, except as may be otherwise provided in the Arbitration
        Agreement above or in supplemental terms applicable to your region.
      </p>

      <h5 id="claimsofcopyrightinfringement">
        <strong>Claims of Copyright Infringement.</strong>
      </h5>

      <p>
        Claims of copyright infringement should be sent to DeliQuick&#39;s
        designated agent. Please visit DeliQuick&#39;s web page at
        <a href="https://app.deliquick.co.kr"> DeliQuick</a> for the designated
        address and additional information.
      </p>

      <h5 id="notice">
        <strong>Notice.</strong>
      </h5>

      <p>
        DeliQuick may give notice by means of a general notice on the Services,
        electronic mail to your email address in your Account, telephone or text
        message to any phone number provided in connection with your account, or
        by written communication sent by first class mail or pre-paid post to
        any address connected with your Account. Such notice shall be deemed to
        have been given upon the expiration of 48 hours after mailing or posting
        (if sent by first class mail or pre-paid post) or 12 hours after sending
        (if sent by email or telephone). You may give notice to DeliQuick, with
        such notice deemed given when received by DeliQuick, at any time by
        first class mail or pre-paid post to our registered agent for service of
        process, PUBLICS. CORP. The name and current contact information as
        below
      </p>
    </Container>
  );
};

export default Contract;
