import React, { useState } from 'react';
import styled from 'styled-components';
import { Checkbox } from '../Icons/Checkbox';
import { formatPrice, formatTenPercentCommission } from '../../common/format';
import Translator from '../../common/Translator/Translator';

const Container = styled.div`
  width: 90%;
  margin: 0 5%;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: black;
  border-bottom: solid 1px #e6e6e6;
`;

const Item = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  line-height: 1.5;
  transition: 0.2s all ease-out;
`;

const OptionGroup = ({ data, onClick }) => {
  return (
    <Container>
      {data.map((item, index) => (
        <OptionItem data={item} onClick={onClick} key={`opt-${index}`} />
      ))}
    </Container>
  );
};

const OptionItem = ({ data, onClick, commission }) => {
  const [selected, setSelected] = useState(false);
  return (
    <Item
      onClick={() => {
        setSelected(!selected);
        if (selected) {
          onClick(data, 'delete');
        } else {
          onClick(data, 'add');
        }
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '16px',
          lineHeight: 1.5
        }}
      >
        <Checkbox selected={selected} />
        <div style={{ marginLeft: '0.5rem' }}>
          {data.name_id ? <Translator id={data.name_id} collection="words" /> : data.realName}
        </div>
      </div>{' '}
      <div style={{ fontSize: '14px', lineHeight: 1.14 }}>
        +₩{formatPrice(commission ? formatTenPercentCommission(data.price) : data.price)}
      </div>
    </Item>
  );
};

export default OptionGroup;
