import React from "react";
import styled from "styled-components";

export const Checkbox = ({ selected }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <rect
          width="16"
          height="16"
          x="4"
          y="4"
          fill={selected ? "#FF3250" : "#E6E6E6"}
          rx="8"
          style={{ transition: "0.2s all ease-out" }}
        />
        <path
          stroke="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M7.5 12l3 3 6-5"
        />
      </g>
    </svg>
  );
};

const Circle = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 8px;
  border: ${props =>
    props.selected ? "solid 1px #ff3250" : "solid 1px #cccccc"};
  transition: 0.2s all ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
  .point {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background-color: #ff3250;
  }
`;

export const Radio = ({ selected }) => {
  return (
    <Circle selected={selected}>{selected && <div className="point" />}</Circle>
  );
};
